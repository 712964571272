import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeUIStyleObject, Label, Box, Checkbox } from 'theme-ui';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import i18n from '../../../i18n/i18n';
export interface SessionConsentProps {
  headlineStyles: ThemeUIStyleObject;
  sublineStyles: ThemeUIStyleObject;
  setExerciseConsent: (param: {
    mlConsent: boolean;
    ownRiskConsent: boolean;
  }) => void;
}

const SessionConsent: React.FC<SessionConsentProps> = ({
  headlineStyles,
  sublineStyles,
  setExerciseConsent,
}: SessionConsentProps) => {
  const { t } = useTranslation();
  const [ownRisk, setOwnRisk] = useState<boolean>(false);
  const [mlConsent, setMlConsent] = useState<boolean>(false);

  return (
    <>
      <Text variant="h3" sx={{ m: 0, ...headlineStyles }}>
        {t('TRAIN_WITH_US')}
      </Text>

      <Text
        variant="h3"
        sx={{ m: 0, maxWidth: '300px', fontWeight: 'normal', ...sublineStyles }}
      >
        {t('SET_CONSENT')}
      </Text>

      <Box sx={{ textAlign: 'left', mt: '40px' }}>
        <Label
          sx={{
            cursor: 'pointer',
            position: 'relative',
            pl: '40px',
          }}
        >
          <Checkbox
            name="own-risk-consent"
            checked={ownRisk}
            onChange={(): void => {
              setOwnRisk(!ownRisk);
            }}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
            }}
          />
          <Text
            variant="h5"
            sx={{
              '@media (max-width: 320px)': {
                fontSize: 0,
              },
            }}
          >
            {t('OWN_RISK')} &nbsp;
            <a
              href={
                i18n.language === 'pl'
                  ? '/pdfs/own-risk-consent.pdf'
                  : '/pdfs/own-risk-consent_eng.pdf'
              }
              target="_blank"
            >
              {t('MEHR_ERFAHREN')}{' '}
            </a>
          </Text>
        </Label>
      </Box>
      <Box sx={{ textAlign: 'left', mt: '10px' }}>
        <Label
          sx={{
            cursor: 'pointer',
            position: 'relative',
            pl: '40px',
          }}
        >
          <Checkbox
            checked={mlConsent}
            onChange={(): void => {
              setMlConsent(!mlConsent);
            }}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
            }}
          />
          <Text
            variant="h5"
            sx={{
              '@media (max-width: 320px)': {
                fontSize: 0,
              },
            }}
          >
            {t('ALLOW_ML')} &nbsp;
            <a
              href={
                i18n.language === 'pl'
                  ? '/pdfs/ml-consent.pdf'
                  : '/pdfs/ml-consent_eng.pdf'
              }
              target="_blank"
            >
              {t('MEHR_ERFAHREN')}{' '}
            </a>
          </Text>
        </Label>
      </Box>
      <Box
        sx={{
          margin: 'auto',
          width: 'min-content',
          mt: '40px',
          mb: '80px',
        }}
      >
        <Button
          onClick={() =>
            setExerciseConsent({
              mlConsent: mlConsent,
              ownRiskConsent: ownRisk,
            })
          }
          variant="primary"
          disabled={!ownRisk}
        >
          {t('NEXT')}
        </Button>
      </Box>
    </>
  );
};

export default SessionConsent;

import * as React from 'react';
import { navigate } from 'gatsby';
import { Box } from 'theme-ui';

import type { LayoutProps } from '../../global/Layout';
import Layout from '../../global/Layout';
import Seo from '../../global/Seo';
import useAuth from '../../hooks/useAuth';
import { useUser } from '@youga/youga-client-api';

interface Props {
  authRequired: boolean;
  title: string;
  theme?: LayoutProps['theme'];
}

function Page({
  authRequired,
  title,
  theme,
  children,
}: React.PropsWithChildren<Props>) {
  const auth = useAuth();
  const { data: userData } = useUser();

  React.useEffect(() => {
    if (
      auth.status !== 'init' &&
      auth.status !== 'authenticated' &&
      authRequired
    ) {
      navigate(`/login/${window?.location.search}`);
    }
  }, [auth, authRequired, userData]);

  React.useEffect(() => {

    if (
      authRequired &&
      userData?.attributes['cognito:user_status'] !== 'EXTERNAL_PROVIDER' &&
      auth.status === 'authenticated' &&
      userData?.attributes &&
      (!userData?.attributes.email_verified ||
        userData?.attributes.email_verified === 'false')
    ) {
      console.log('navigate', { auth });
      navigate(`/confirm-signup`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <Layout theme={theme}>
      <Box id="relative-wrapper" sx={{ position: 'relative' }}>
        <Seo title={title} titleTemplate="" />
        {children}
      </Box>
    </Layout>
  );
}

export default Page;

/** @jsxImportSource theme-ui */
import React from 'react';

import { Image, Box, Flex } from 'theme-ui';
import { Link } from 'gatsby';

import { useCourses } from '@youga/youga-client-api';

import Text from '../Text/Text';
import i18n from '../../../i18n/i18n';

interface PoseComparisonSessionItemProps {
  poseImage: string;
  userImage: string;
  sessionName: string;
  sessionId: string;
  date?: string;
  target: string;
}

const PoseComparisonSessionItem: React.FC<PoseComparisonSessionItemProps> = ({
  poseImage,
  userImage,
  sessionId,
  sessionName,
  date,
  target,
}: PoseComparisonSessionItemProps) => {
  const sessionDate = date ? new Date(date) : null;
  const { data: coursesData } = useCourses(i18n.language);

  const matchingCourse = Object.values(coursesData?.courses || {}).find(
    (course) => course.sessions.includes(sessionId),
  );

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0px 10px 16px 0px rgba(0, 0, 0, 0.1);',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          paddingTop: `${(110 / 232) * 100}%`,
          backgroundSize: 'cover',
          borderRadius: '8px 8px 0 0',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: '50%',
            bottom: 0,
            backgroundImage: `url(${poseImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          }}
        >
          <Image
            className="fs-exclude"
            data-hj-suppress=""
            src={userImage}
            loading="lazy"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ p: 3, py: 1, height: '3.75rem' }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          {matchingCourse && (
            <Text
              clampLines={1}
              sx={{
                mt: '2px',
                fontFamily: 'heading',
                fontSize: 0,
                letterSpacing: '-0.02px',
                lineHeight: '1rem',
              }}
            >
              {matchingCourse.title}
            </Text>
          )}

          <Text
            clampLines={1}
            sx={{
              mt: '2px',
              fontFamily: 'heading',
              fontSize: 0,
              letterSpacing: '-0.02px',
              fontWeight: 'bold',
              lineHeight: '1rem',
            }}
          >
            {sessionName}
          </Text>

          {sessionDate && (
            <Text
              clampLines={1}
              sx={{
                mt: '2px',
                lineHeight: '14px',
                fontSize: '12px',
                fontFamily: 'heading',
                color: 'grey2',
              }}
            >
              {sessionDate.toLocaleDateString('de-DE', {
                day: 'numeric',
                month: 'long',
              })}
            </Text>
          )}
        </Flex>
      </Box>
      <Link
        to={target}
        sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      />
    </Box>
  );
};

export default PoseComparisonSessionItem;

import React, { useCallback, useRef, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box, Flex } from 'theme-ui';
import Text from '../Text/Text';
import IconChevronDown from '../../../assets/icons/iconChevronDown.inline.svg';

export interface AccordionElement {
  title: string;
  content: React.ReactElement;
}

export interface AccordionProps {
  small?: boolean;
  elements: AccordionElement[];
}

/**
 * When using this accordion, it is highly important to remove all margins inside the elements content
 * because we need to get the outer height of the content to animate the max-height value
 */
const Accordion: React.FC<AccordionProps> = ({
  small,
  elements,
}: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const boxRef = useRef(null);
  const [heightValues, setHeightValues] = useState<{ [title: string]: number }>(
    {},
  );

  const updateHeightValues = useCallback((): void => {
    if (!boxRef.current) {
      return;
    }

    const accordionElements = (
      boxRef.current as unknown as HTMLDivElement
    ).querySelectorAll('.accordion-content');

    const values: { [key: string]: number } = {};

    accordionElements.forEach((el) => {
      values[el.getAttribute('data-element-title') as string] =
        el.getBoundingClientRect().height;
    });

    setHeightValues(values);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onResize = debounce((): void => {
      updateHeightValues();
    }, 100);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      onResize();
    }

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box ref={boxRef}>
      {elements.map((el, index) => (
        <Box
          key={el.title}
          sx={{
            position: 'relative',
            py: small ? 3 : 4,
            borderTop: '1px solid black',
            borderBottom:
              index + 1 === elements.length ? '1px solid black' : '',
          }}
        >
          <Flex
            style={{
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onClick={(): void => {
              setActiveIndex(activeIndex === index ? null : index);
            }}
          >
            <IconChevronDown
              style={{
                cursor: 'pointer',
                marginTop: 8,
                minHeight: '28px',
                maxHeight: '28px',
                maxWidth: '28px',
                minWidth: '28px',
                marginBottom: -16,
                marginLeft: 4,
                transformOrigin: 'center center',
                transition: 'transform ease-in 0.3s',
                transform:
                  activeIndex === index
                    ? 'translateY(-50%) rotate(180deg)'
                    : 'translateY(-50%)',
              }}
            />
            <Text
              as="p"
              variant="section.subtitle"
              style={{
                textAlign: 'start',
                marginLeft: 24,
                m: 0,
                pr: 7,
                cursor: 'pointer',
                fontSize: '20px',
                fontWeight: 'normal',
              }}
            >
              {el.title}
            </Text>
          </Flex>
          <Box
            style={{
              marginLeft: '58px',
              marginRight: '58px',
              overflow: 'hidden',
              transition: 'max-height ease-in 0.15s',
              maxHeight: activeIndex === index ? heightValues[el.title] : 0,
            }}
          >
            <Box className="accordion-content" data-element-title={el.title}>
              {el.content}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Accordion;

import React from 'react';

export interface ControlSpreaderProps {
  order: number;
}

const ControlSpreader: React.FC<ControlSpreaderProps> = () => (
  <div className="video-react-control video-react-control-spreader" />
);

export default ControlSpreader;

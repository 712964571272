/** @jsxImportSource theme-ui */

import type { FooterProps } from '../../02_molecules/AuthFooter/AuthFooter';

import Footer from '../../02_molecules/Footer/Footer';

function FooterSection(props: FooterProps) {
  return <Footer {...props} />;
}

export default FooterSection;

import React from 'react';
import { Box } from 'theme-ui';

type Props = {};

const Tick = (props: Props) => {
  return (
    <Box>
      <Box
        sx={{
          width: '26px',
          height: '26px',
          backgroundColor: '#F48222',
          borderRadius: 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            border: '3px solid white',
            borderTopRightRadius: 2,
            borderColor: 'white',
            width: 12,
            height: 11,
            borderLeft: 'none',
            borderBottom: 'none',
            transform: 'rotate(130deg)',
            marginTop: '-5px',
          }}
        />
      </Box>
    </Box>
  );
};

export default Tick;

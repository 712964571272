import { useTranslation } from 'react-i18next';

import React from 'react';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import TextInput from '../../01_atoms/TextInput/TextInput';
import { Auth } from 'aws-amplify';
import useAuth from '../../../hooks/useAuth';

type ConfirmAuthCodeProps = {
  confirmCode?: string;
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  setCode: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  setPassword: (v: string) => void;
  setIsLoading: (val: boolean) => void;
};

export default function ConfirmAuthCode({
  confirmCode,
  errorInformation,
  setCode,
  setIsLoading,
}: ConfirmAuthCodeProps) {
  const { t } = useTranslation();
  const { handleSignOut } = useAuth();

  const handleSendCode = async () => {
    setIsLoading(true);
    await Auth.verifyCurrentUserAttribute('email');
    setIsLoading(false);
  };
  return (
    <>
      <React.Fragment key="confirm-submit-fragment">
        <TextInput
          id="code"
          type="text"
          name="code"
          onChange={(e): void => {
            setCode(e.target.value);
          }}
          required
          placeholder={t('CODE_FROM_EMAIL')}
          defaultValue={confirmCode}
          error={
            errorInformation?.position === 'code' && errorInformation.element
          }
          style={{ marginTop: '3.5rem' }}
        />

        <Button
          type="submit"
          style={{
            cursor: 'pointer',
            display: 'block',
            marginTop: '3rem',
          }}
        >
          {t('BEST_TIGEN')}
        </Button>

        <Button
          onClick={handleSendCode}
          type="button"
          style={{
            cursor: 'pointer',
            display: 'block',
            marginTop: '1rem',
          }}
        >
          {t('SEND_CODE_AGAIN')}
        </Button>
        <Button
          type="button"
          onClick={() => handleSignOut()}
          variant="text"
          style={{
            cursor: 'pointer',
            display: 'block',
            marginTop: '1rem',
          }}
        >
          {t('AUSLOGGEN')}
        </Button>
      </React.Fragment>
    </>
  );
}

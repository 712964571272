import { useTranslation } from 'react-i18next';

import React from 'react';
import { Image, Box, ThemeUIStyleObject } from 'theme-ui';

import Text from '../../01_atoms/Text/Text';
import OvalNumber from '../../01_atoms/OvalNumber/OvalNumber';
import IconPrivate from '../../../assets/icons/iconPrivate.svg';

export interface IntroProps {
  headlineStyles: ThemeUIStyleObject;
  sublineStyles: ThemeUIStyleObject;
  codeDataUrl?: string;
}

const Intro: React.FC<IntroProps> = ({
  headlineStyles,
  sublineStyles,
  codeDataUrl,
}: IntroProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Text variant="h3" sx={{ m: 0, ...headlineStyles }}>
        {t('SMARTMIRROR1')}
      </Text>

      <Text
        variant="h3"
        sx={{ m: 0, maxWidth: '300px', fontWeight: 'normal', ...sublineStyles }}
      >
        {t('SEHE_DIREKT_WO_DU_DICH_VERBES')}
      </Text>

      <Box
        sx={{
          mt: 3,
          ml: '22px',
          width: '100px',
          height: '100px',
          backgroundSize: 'contain',
          backgroundImage: `url(${codeDataUrl})`,
          '@media (min-height: 768px)': {
            mt: 4,
            width: '206px',
            height: '206px',
          },
        }}
      />

      <Box sx={{ mt: 4 }}>
        <Text
          variant="smallMontserrat"
          sx={{
            textAlign: 'left',
            mt: 3,
            pl: '40px',
            position: 'relative',
            maxWidth: '380px',
            '@media (min-height: 768px)': {
              mt: 4,
            },
          }}
        >
          <OvalNumber
            number={1}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '22px',
              height: '22px',
            }}
          />
          {t('SCANNE_DEN_CODE_MIT_DER')}
          <strong>{t('KAMERA_APP')}</strong> {t('AUF_DEINEM_IOS_GER_T')}
        </Text>
        <Text
          variant="smallMontserrat"
          sx={{
            textAlign: 'left',
            mt: 3,
            pl: '40px',
            position: 'relative',
            maxWidth: '380px',
            '@media (min-height: 768px)': {
              mt: 4,
            },
          }}
        >
          <OvalNumber
            number={2}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '22px',
              height: '22px',
            }}
          />

          {t('STELLE_DEN_SMART_MIRROR_AUF_UN')}
        </Text>
        <Text
          variant="smallMontserrat"
          sx={{
            textAlign: 'left',
            mt: 3,
            pl: '40px',
            position: 'relative',
            maxWidth: '380px',
            '@media (min-height: 768px)': {
              mt: 4,
            },
          }}
        >
          <OvalNumber
            number={3}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '22px',
              height: '22px',
            }}
          />

          {t('STARTE_DEINE_SESSION')}
        </Text>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mt: 9,
        }}
      >
        <Box sx={{ width: '40px', height: '22px' }}>
          <Image src={IconPrivate} sx={{ height: '22px' }} />
        </Box>

        <Text sx={{ fontSize: 0 }}>{t('KEINE_SORGE_DEINE_BILDER_SIN')}</Text>
      </Box>
    </>
  );
};

export default Intro;

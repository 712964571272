import Loader from 'react-loader-spinner';
import { Box } from 'theme-ui';

export interface SpinnerProps {
  theme?: 'light' | 'dark';
  color?: string;
  fullscreen?: boolean;
}

function Spinner({
  theme = 'light',
  color = 'black',
  fullscreen = false,
}: SpinnerProps) {
  const icon = (
    <Loader
      type="Oval"
      color={color}
      height={60}
      width={60}
      timeout={2000000}
    />
  );

  if (!fullscreen) {
    return icon;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        backgroundColor: theme === 'light' ? 'white' : 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icon}
    </Box>
  );
}

export default Spinner;

import * as React from 'react';

import { Connect, StreamingSession } from '@youga/youga-interfaces';
import { useYougaClientApi } from '@youga/youga-client-api';

import useAuth from '../hooks/useAuth';

export type ActionResult =
  | { type: 'auth-success' }
  | {
      type: 'connect-video';
      payload: {
        connectId: string;
        videoId: string;
        trainingSessionId: string;
        streamingSession: StreamingSession;
      };
    };

export interface ConnectHandlerResult {
  result?: ActionResult;
  error?: unknown;
}

export function useConnectHandler(connect?: Connect): ConnectHandlerResult {
  const { fetcher, api, userId } = useYougaClientApi();
  const { signInWithToken } = useAuth();

  const [result, setActionResult] = React.useState<ActionResult>();
  const [error, setError] = React.useState<unknown>();

  React.useEffect(() => {
    async function handleConnect() {
      if (connect == null) {
        return;
      }

      const { actions } = connect;
      if (actions == null) {
        return;
      }

      try {
        // in case if we have 0 actions, we just redirect to the app, because this means that not even auth is necessary
        let actionResult: ActionResult | null = userId
          ? { type: 'auth-success' }
          : null;

        for (let i = 0; i < actions.length; i += 1) {
          const action = actions[i];

          switch (action.type) {
            case 'auth':
              await signInWithToken(action);
              actionResult = { type: 'auth-success' };
              break;

            case 'connect-video': {
              if (action.streamingCode == null) {
                throw new Error(`Missing streamingCode`);
              }
              const streamingSession = await api.getStreamingSession(
                action.streamingCode,
              );
              actionResult = {
                type: 'connect-video',
                payload: {
                  connectId: connect.id,
                  videoId: action.videoId,
                  trainingSessionId: action.trainingSessionId,
                  streamingSession,
                },
              };
              break;
            }

            default:
              break;
          }
        }

        // Mark the auth-connect object as resolved and remove the credentials
        await fetcher(`/connect/${connect.id}`, {
          method: 'PUT',
          body: JSON.stringify({ actions: [] }),
        });

        if (actionResult == null) {
          throw new Error(`No action result.`);
        }

        setActionResult(actionResult);
      } catch (error) {
        console.error(`Error while handling "connect.actions":`, error);
        setError(error);
      }
    }

    handleConnect();
  }, [connect, api, fetcher, userId, signInWithToken]);

  return {
    result,
    error,
  };
}

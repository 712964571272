import { Details } from '../types/interfaces';

type Data = Record<string, Record<string, Details>>;

export const DATA: Data = {
  /* course name */
  basics: {
    /* session name */
    'basics-sun-salutations': {
      title: 'Der Sonnengruß',
      subtitle: 'Lerne die Posen im Detail',
      intro:
        'Der Sonnengruß ist eine Abfolge von verschiedenen Posen und ist der ideale Start in Deine Yoga-Session oder auch in einen schönen Tag. Die gesundheitlichen Vorteile sind weitreichend von Stressreduktion, Gewichtsabnahme bis zur Verdauungs- und Stoffwechselförderung.\n\nWusstest Du schon, dass die positive Wirkung sogar wissenschaftlich belegt ist? Lass uns einige der Posen im Detail anschauen',
      headerImage: require('../assets/images/onboardingJourney/basics_sun_header.png'),
      sections: [
        {
          id: '1',
          title: 'Halbe Vorbeuge',
          text: 'Die halbe Vorbeuge ist für Anfänger manchmal eine kleine Herausforderung, also gehe nur so weit, wie Du Dich gut fühlst.\nHebe Deinen Blick minimal an, halte Deinen Rücken lang und die Beine leicht gebeugt. Wichtig ist, dass Du Deine Halswirbelsäule nicht überstreckst.\n\nUnser Tipp für Anfänger: Nutze gerne zwei Yoga-Blöcke als Unterstützung unter Deinen Händen.',
          image: require('../assets/images/onboardingJourney/basics_baum.jpg'),
        },

        {
          id: '2',
          title: 'Gestreckte Berghaltung',
          text: 'Atme ein – Zeit für die Urdhva Hastasana!\nStrecke Deine Arme nach oben und lasse Deine Schultern dabei entspannt nach hinten fallen. Achte darauf die Schultern beim heben der Arme nicht nach oben zu ziehen, sondern nach unten/hinten.\n\nSchütze Deinen unteren Rücken indem Du in einer geraden Position bleibst und Dich in der Pose nicht nach hinten beugst.',
          image: require('../assets/images/onboardingJourney/basics_halbmond.jpg'),
        },
        {
          id: '3',
          title: 'Herabschauender Hund',
          text: 'Atme aus wenn Du in die Pose gehst. Achte auf den richtigen Abstand zwischen Händen und Füßen.\n\nBeuge Deine Beine leicht an und lasse den Rücken schön lang - strecke Deine Arme aus den Schultern kräftig. Und: Lass wortwörtlich Deinen Kopf nicht hängen.',
          image: require('../assets/images/onboardingJourney/basics_taenzer.jpg'),
        },
      ],
    },
    'basics-warrior': {
      title: 'Der Krieger',
      subtitle: 'Lerne die Posen im Detail',
      intro:
        'Wusstest Du, dass Blockaden in der Hüfte zu Beschwerden im Rücken und Knien führen kann?\n\nOffene Hüften sind beweglicher richten sich so aus, dass Du Deinen Rücken und sämtliche Gelenke entlastest. Stress und negative Emotionen werden in der Hüfte gespeichert.\n\nDas liegt daran, dass sich der Hüftbeugemuskel bei emotionalen Anspannungen zusammenzieht.\n\nOffene Hüften helfen also Körper und Geist. Probiere es doch gleich einmal aus:',
      headerImage: require('../assets/images/onboardingJourney/warrior_header.png'),
      sections: [
        {
          id: '1',
          title: 'Krieger 1',
          text: 'Der Krieger 1 mobilisiert Deine Brustwirbelsäule, kräftigt Arme, Schultern und Core. Deine Hüfte wird intensiv gedehnt.\n\nAchte bei der Ausführung des Krieger 1 darauf, dass der hintere Fuß im 45°-Winkel nach außen zeigt und das Bein komplett gestreckt ist. Das vordere Knie wird 90° gebeugt, sodass es mit deinem Knöchel eine Linie bildet. Vermeide ein starkes Hohlkreuz.',
          image: require('../assets/images/onboardingJourney/warrior_1.jpg'),
        },
        {
          id: '2',
          title: 'Krieger 2',
          text: 'Wie wäre es mit einer extra Portion Core-Muskulatur? Der Krieger 2 stärkt Deine Körpermitte effektiv und fördert die Durchblutung in Deinen Oberschenkeln.\n\nAnders als beim Krieger 1 zeigt Dein hinterer Fuß hier im 90°-Winkel nach außen und die Hüfte zeigt gerade zur Seite. Dein Oberkörper ist über der Hüfte und schaut zur Seite, während Dein Bauchnabel zur Wirbelsäule zieht. Lass den unteren Rücken hier lang und den Beckenboden aktiv.',
          image: require('../assets/images/onboardingJourney/warrior_2.jpg'),
        },
        {
          id: '3',
          title: 'Krieger 3',
          text: 'Wenn Du schon ein wenig erfahrener bist ist der Krieger 3 eine ideale Pose für Dich: Er erfordert neben Kraft auch eine gute Balance und aktiviert  somit Deinen ganzen Körper und bringt Deine Energie zum fließen.\n\nDas Standbein ist zwar gerade, allerdings nicht durchgestreckt. Flexe den Fuß deines hinteren Beins, spanne Deinen Bauch an und halte den unteren Rücken gerade um die Kraft zu entfesseln.',
          image: require('../assets/images/onboardingJourney/warrior_3.jpg'),
        },
      ],
    },
    'basics-hip-openers': {
      title: 'Der Hüftöffner',
      subtitle: 'Lerne die Posen im Detail',
      intro:
        'Wusstest Du, dass Blockaden in der Hüfte zu Beschwerden im Rücken und Knien führen kann?\n\nOffene Hüften sind beweglicher richten sich so aus, dass Du Deinen Rücken und sämtliche Gelenke entlastest. Stress und negative Emotionen werden in der Hüfte gespeichert.\n\nDas liegt daran, dass sich der Hüftbeugemuskel bei emotionalen Anspannungen zusammenzieht.\n\nOffene Hüften helfen also Körper und Geist. Probiere es doch gleich einmal aus:',
      headerImage: require('../assets/images/onboardingJourney/hipopener_header.png'),
      sections: [
        {
          id: '1',
          title: 'Der Low Lunge',
          text: 'Starte in den Low  Lunge - dem tiefen Ausfallschritt und bringe Deinen Fuß auf die Höhe Deiner Hände. Das Knie und Fuß des hinteren Beins kannst Du nun vorsichtig ablegen.\nHalte Deine Wirbelsäule gerade. Wenn Du möchtest kannst Du Deine Hände nun auf dem Knie ablegen.\n\nTipp: Falls der Boden etwas hart sein sollte trotz Yoga-Matte kannst Du die Matte auch doppelt legen.',
          image: require('../assets/images/onboardingJourney/hipopener_1.jpg'),
        },
        {
          id: '2',
          title: 'Der Squat',
          text: 'Die tiefe Hocke ist die Königin der hüftöffnenden Posen beim Yoga.\n\nDamit Du von ihrer Wirkung profitieren kannst ein paar Tipps: Stelle Deine Füße parallel und in hüftbreite auf. Achte darauf, dass Füße und Unterschenkel gleich ausgerichtet sind. Schiebe den Sitzbeinhöcker nach hinten und auseinander.\n\nDeine Wirbelsäule sollte gerade sein und Dein Core stabil.',
          image: require('../assets/images/onboardingJourney/hipopener_2.jpg'),
        },
        {
          id: '3',
          title: 'Die Taube',
          text: 'Die Taube ist eine sehr klassische Yoga Pose um die Hüften zu öffnen. Bringe ein Bein vor und setze Dein Knie hinter der Hand und den Fuß hinter der anderen Hand ab. Das Schienbein sollte nun parallel zum Rand Deiner Matte ausgerichtet sein.\n\nWichtig: Die Außenkante des vorderen Fußes schiebt sich fest in den Boden, das Großzehengelenk schiebst Du nach vorne und ziehst die Zehen nach hinten. Das schützt Deine Knie.',
          image: require('../assets/images/onboardingJourney/hipopener_3.jpg'),
        },
      ],
    },
    'basics-heart-openers': {
      title: 'Herzöffner',
      subtitle: 'Tipps für Herzöffner Posen',
      intro:
        'Herzöffner beschreiben beim Yoga die Posen, die Deine Brust dehnen, indem die Schultern nach hinten und unten gezogen werden und die Brust dadurch nach vorne geschoben wird.\n\nDurch diese Dehnung der Brustmuskeln wird Deine Lunge und Dein Herz geöffnet und Du bekommst mehr Platz zum Atmen. Das bringt Dir neue körperliche und mentale Kraft.\n\nGood to know: Herzöffner sind auch bekannt als Rückenbeugen. Doch anders als Du vielleicht zuerst vermutest, entsteht eine Rückenbeuge nicht durch Flexibilität und Kraft im unteren Rücken , sondern viel mehr aus der Öffnung der Brust.',
      headerImage: require('../assets/images/onboardingJourney/heartopener_header.png'),
      sections: [
        {
          id: '1',
          title: 'Die Brücke',
          text: 'Diese Pose verhilft deinem Körper zur Regeneration.\n\nLasse Deine Zehen nach vorne zeigen, während Deine Knie hüftbreit voneinander entfernt sind.\n\nUm diese Asana zu intensivieren, kannst Du mit Deinen Händen eine Faust unter Deinem Rücken machen und die Schulterblätter etwas zusammenziehen.',
          image: require('../assets/images/onboardingJourney/heartopener_1.jpg'),
        },
        {
          id: '2',
          title: 'Die Kobra',
          text: 'Achte darauf, dass Du Dich bei dieser Pose nicht aktiv mit den Händen nach oben drückst. Die Kraft muss aus dem Rücken kommen. Dazu kannst Du Deine Hände auch kurz anheben.\n\nEs ist außerdem wichtig, dass Du Deine Schultern nicht zu Deinen Ohren schiebst.\n\nDein  Scheitelpunkt und das Brustbein streben nach vorne oben. Deine Schulterblätter ziehen zusammen nach unten, um Deine Brust zu öffnen.',
          image: require('../assets/images/onboardingJourney/heartopener_2.jpg'),
        },
        {
          id: '3',
          title: 'Die Kamel Pose',
          text: 'Bitte beachte, langsam und vorsichtig in diese Rückbeuge zu gehen.\n\nAchte zusätzlich darauf, wie weit Du mit den Händen gehen kannst und lege sie dann entweder auf Deinen Fußsohlen oder Waden ab.\n\nProbiere mal Deine Zehen aufzustellen, dadurch kannst du Dein Gleichgewicht besser halten. Es ist sehr wichtig, dass Dein Kopf nicht zu weit nach hinten gelehnt ist.',
          image: require('../assets/images/onboardingJourney/heartopener_3.jpg'),
        },
      ],
    },
    'basics-balance': {
      title: 'Balance',
      subtitle: 'Tipps für Balance Posen',
      intro:
        'Ein wichtiger Bestandteil der Yogapraxis ist die Balance. Diese wirkt wie ein Spiegel Deiner inneren Harmonie und ist ein Inbegriff Deiner ausgeglichenen Haltung sowohl geistig als auch körperlich.\n\nDurch das Trainieren Deiner Balance verbesserst Du Deinen Gleichgewichtssinn und Deine Standfestigkeit.\n\nDarüber hinaus förderst Du auch noch Deine Konzentration und findest wieder in Dein Zentrum.\n\nGood to know: Das Organ, das dafür zuständig ist, Dich in Deiner Balance zu halten, nennt man Gleichgewichtsorgan (Vestibularorgan). Es sitzt gut geschützt dort, wo Du es vielleicht nicht erwartest: Im Innenohr.',
      headerImage: require('../assets/images/onboardingJourney/balance_header.png'),
      sections: [
        {
          id: '1',
          title: 'Die Baum Pose',
          text: 'Bei der Baum Pose ist es wichtig, dass Du die Grundspannung von Deinen Füßen bis zu Deinen Händen hältst.\n\nWenn es Dir besonders am Anfang noch schwerfällt, diese Pose zu halten, dann versuche es doch erst mal mit dem Rücken zu einer Wand oder indem Du Deinen Fuß etwas niedriger ansetzt wie an der Wade oder Deinem Knöchel.\n\nUm eine schiefe Haltung zu vermeiden, kannst Du Dir vorstellen, dass ein Faden am höchsten Punkt Deines Kopfes befestigt ist, der Dich gerade nach oben zieht.',
          image: require('../assets/images/onboardingJourney/balance_1.jpg'),
        },
        {
          id: '2',
          title: 'Der Halbmond',
          text: 'Dein Standbein trägt in der Pose Dein Körpergewicht. Dein Spielbein allerdings ist wichtig für Deine Balance. Wenn Du das hintere Bein anhebst, flexe Deinen Fuß und spreize die Zehen, um für mehr Stabilität in Deinem Körper zu sorgen.\n\nWenn Du Probleme mit dieser Asana hast, ist es von Vorteil, Hilfsmittel zu benutzen. Falls Deine Arme zu kurz sind, kannst Du ganz einfach einen Block vor Dir auf den Boden platzieren und Deinen Arm gerade in einer Linie darauf setzten.',
          image: require('../assets/images/onboardingJourney/balance_2.jpg'),
        },
        {
          id: '3',
          title: 'Die Tänzer Pose',
          text: 'Um Deinen unteren Rücken bei dieser Pose zu schonen, ist es wichtig, darauf zu achten, dass Dein Core aktiv ist.\n\nAm Anfang ist es außerdem äußerst empfehlenswert, Dein Bein nur so weit anzuheben wie es für Dich angenehm und machbar ist.\nEin wenig angenehmer und leichter wird die Pose, wenn Du Deinen Oberkörper nach unten beugst, sodass er sich parallel zum Boden befindet. Deine Arme kannst Du dann nach vorne ausstrecken.',
          image: require('../assets/images/onboardingJourney/balance_3.jpg'),
        },
      ],
    },
  },
  headstand: {
    'headstand-bodycenter': {
      title: 'Stärke Deine Körpermitte',
      subtitle: 'Dein Weg im Detail',
      intro:
        'Der Kopfstand – der König der Asanas. Mit der richtigen Technik wirst Du ihn meistern.\nMit kleinen Schritten kommst Du Deinem Ziel näher.\n\nWusstest Du, dass der Kopfstand ein echter Allrounder ist? Mit dem Kopfstand trainierst Du Deine Flexibilität, Mobilität und Stärke.\nWichtig für den Kopfstand ist eine starke Mitte. Lerne die Übungen kennen, die Dich Deinem Ziel vom Kopfstand näher bringen.',
      headerImage: require('../assets/images/onboardingJourney/headstand-bodycenter-header.png'),
      sections: [
        {
          id: '1',
          title: 'Diagonale Katze',
          text: 'Stelle Deine Handgelenke und Knie jeweils unter Schulter bzw. Knie, lege den Fußrücken ab oder stelle Deine Zeh auf. Halte den Rücken gerade und den Kopf in einer Linie mit der Wirbelsäule. Ziehe den Bauchnabel nach innen.\n\nMit dem Einatmen ziehst Du diagonal Arm und Bein nach oben und hältst sie für 15-30 Sekunden dort.',
          image: require('../assets/images/onboardingJourney/headstand-bodycenter-1.jpg'),
        },
        {
          id: '2',
          title: 'Delphin',
          text: 'Beginne im Vierfüßlerstand. Stelle nun die Zehen auf und löse mit einer Ausatmung die Knie vom Boden. Lass die Beine zunächst noch leicht gebeugt, drücke Dich aktiv mit den Unterarmen ab und schiebe den Rumpf bis in die Sitzknochen nach hinten und in die Länge und das Brustbein weg vom Boden. Strecke Deine Beine nur so weit aus, dass Du Deinen Rücken weiter gerade halten kannst.',
          image: require('../assets/images/onboardingJourney/headstand-bodycenter-2.jpg'),
        },
        {
          id: '3',
          title: 'Kopfstand mit Füßen',
          text: 'Die Welt steht erstmals Kopf! Wichtig ist, dass Du Deine Halswirbelsäule schützt. Schieb Unterarme, Ellbogen und Hände geradeaus nach unten in den Boden. Wandere nun mit beiden Füßen langsam Schritt für Schritt so weit wie möglich in Richtung Kopf und sende das Becken dabei über den Kopf nach vorne und oben: Deine Oberkörper richtet sich auf.',
          image: require('../assets/images/onboardingJourney/headstand-bodycenter-3.jpg'),
        },
      ],
    },
    'headstand-shoulderstrength': {
      title: 'Kräftige Deine Schultern',
      subtitle: 'Dein Weg im Detail',
      intro:
        'Neben der starken Mitte sind Deine Schultern entscheidend, für den Kopfstand. Mit der Kombination aus Core und Schultern baust Du die Kraft auf, die Du für diese Ausführung benötigst.\n\nSchöne, starke Schultern wollen wir doch alle, oder? Sie geben einem nicht nur die nötige Statik beim Kopfstand, sondern auch eine gute Körperhaltung und Anmut. Wir zeigen Dir gerne ein paar Übungen, mit welchen Du Deine Schultern stärkst.',
      headerImage: require('../assets/images/onboardingJourney/headstand-shoulderstrength-header.png'),
      sections: [
        {
          id: '1',
          title: 'Downward Dog',
          text: 'Atme aus wenn Du in die Pose gehst. Achte auf den richtigen Abstand zwischen Händen und Füßen.\nBeuge Deine Beine leicht an und lasse den Rücken schön lang - strecke Deine Arme aus den Schultern kräftig.\n\nUnd: Lass wortwörtlich Deinen Kopf nicht hängen.',
          image: require('../assets/images/onboardingJourney/headstand-shoulderstrength-1.jpg'),
        },
        {
          id: '2',
          title: 'Plank Pose',
          text: 'Lege Dich zunächst auf den Bauch mit dem Gesicht nach unten. Bringe die Hände über die Schultern mit den Handflächen nach unten. Hebe den Körper an, sodass er eine gerade Linie bildet. Achte darauf, dass die Arme senkrecht zum Boden stehen und die Hände direkt unter den Schultern platziert sind. Halte den Plank möglichst lange! Wie wäre es mit einer Challenge? Steigerungen in der Zeit werden schnell spürbar sein.',
          image: require('../assets/images/onboardingJourney/headstand-shoulderstrength-2.jpg'),
        },
        {
          id: '3',
          title: 'Einhändiger Tiger',
          text: 'Der einhändige Tiger sieht nicht nur super aus, sondern ist auch sehr effektiv. Starte auf allen Vieren. Hebe nun ein Bein angewinkelt an und greife nach dem Fuß mit dem diagonalen Arm. Öffne die Schultern und drücke aktiv mit dem Fuß in Deine Hand.\n\nGeschafft! Der Tiger ist bezwungen.',
          image: require('../assets/images/onboardingJourney/headstand-shoulderstrength-3.jpg'),
        },
      ],
    },
    'headstand-onelegup': {
      title: 'Das erste Mal schweben',
      subtitle: 'Dein Weg im Detail',
      intro:
        'Schritt für Schritt kommst Du dem Kopfstand näher. Du wirst in Kürze das erste Mal schweben und die Welt im wahrsten Sinne des Wortes aus einem anderen Winkel betrachten.\n\nUm Dich auf das erste Mal Schweben vorzubereiten haben wir ein paar Übungen für Dich:',
      headerImage: require('../assets/images/onboardingJourney/headstand-onelegup-header.png'),
      sections: [
        {
          id: '1',
          title: 'Katze',
          text: 'Diese Pose bringt Dehnung und Flexibilität in den Rücken, gibt Kraft in Wirbelsäule und Nacken und bereitet Dich aufs Schweben vor. Beginne im Vierfüßlerstand. Atme ein und ziehe den Bauchnabel ein und kippe das Becken, so dass du deine Wirbelsäule Richtung Decke drückst. Dein Kopf beugt sich dabei nach unten. Halte inne und spüre nach.',
          image: require('../assets/images/onboardingJourney/headstand-onelegup-1.jpg'),
        },
        {
          id: '2',
          title: 'Seitlicher Stütz',
          text: 'Drehe Deinen Körper zur Seite, verlagere das Gewicht auf die untere Hand und untere Fußaußenseite, bringe den Arm senkrecht nach oben. Beide Arme bilden eine Linie. Die Finger der oberen Hand sind gespreizt und bilden mit dem Ellenbogen, den Schultern bis zu den Fingerspitzen der oberen Hand eine Linie. Der rechte Fuß ruht auf dem linken Fuß, die Zehenspitzen zeigen nach vorne.',
          image: require('../assets/images/onboardingJourney/headstand-onelegup-2.jpg'),
        },
        {
          id: '3',
          title: 'Kopfstand – Zeh am Boden',
          text: 'Bereit fürs Schweben? Wichtig ist, dass Du Deine Halswirbelsäule schützt. Schieb Unterarme, Ellbogen und Hände geradeaus nach unten in den Boden. Wandere nun mit beiden Füßen langsam Schritt für Schritt so weit wie möglich in Richtung Kopf. Hebe ein Bein an und berühre mit dem zweiten den Boden nur mit dem Zeh. Tolles Gefühl, oder?',
          image: require('../assets/images/onboardingJourney/headstand-onelegup-3.jpg'),
        },
      ],
    },
    'headstand-fullheadstand': {
      title: 'Dein erster Kopfstand',
      subtitle: 'Dein Weg im Detail',
      intro:
        'Wusstest Du, dass der Kopfstand Dein Muladhara Chakra aktiviert, welche die Quelle für Stabilität, Mut und das Sicherheitsgefühl im Leben ist?\nDer Kopfstand verhilft zur geistigen Ausgeglichenheit und Klarheit. Durch den Fokus auf Deinen tiefen, gleichmäßigen Atem und Deine Balance reduzierst du Ärger, Angstgefühle und Stress.\n\nDie Vorteile sind zum greifen nah:',
      headerImage: require('../assets/images/onboardingJourney/headstand-fullheadstand-header.png'),
      sections: [
        {
          id: '1',
          title: 'Kopfstand – Zeh am Boden',
          text: 'Beginne mit dem Kopfstand mit Zeh am Boden. Wie immer gilt: Wichtig ist, dass Du Deine Halswirbelsäule schützt. Schieb Unterarme, Ellbogen und Hände geradeaus nach unten in den Boden. Wandere nun mit beiden Füßen langsam Schritt für Schritt so weit wie möglich in Richtung Kopf. Hebe ein Bein an und berühre mit dem zweiten den Boden nur mit dem Zeh. Tolles Gefühl, oder?',
          image: require('../assets/images/onboardingJourney/headstand-fullheadstand-1.jpg'),
        },
        {
          id: '2',
          title: 'Kopfstand – angewinkelt',
          text: 'Wenn Du Dich in der Pose mit dem Zeh am Boden sicher fühlst ist es Zeit für den nächsten Schritt: Hebe langsam das zweite Bein nach oben und winkle beide Beine gemeinsam an. Halte diese Position so lange wie Du kannst. Es ist vollkommen okay, wenn Du die Pose noch nicht lange halten kannst. Achte darauf Deinen Rücken gerade zu halten während Deine Hüften zum Himmel streben.',
          image: require('../assets/images/onboardingJourney/headstand-fullheadstand-2.jpg'),
        },
        {
          id: '3',
          title: 'Der Kopfstand',
          text: 'Die König der Asanas wirkt besonders kraftvoll und entfaltet seine vollständige Wirkung, wenn die Beine ausgestreckt sind. Die Füße sind hierbei geflext und ziehen nach oben. Füße, Hüfte und Kopf bilden eine Linie.\n\nDas ist doch ein Foto wert, oder? Angeben ist erlaubt.',
          image: require('../assets/images/onboardingJourney/headstand-fullheadstand-3.jpg'),
        },
      ],
    },
    'headstand-variations': {
      title: 'Kopfstand Variationen',
      subtitle: 'Dein Weg im Detail',
      intro:
        'Im Yoga gibt es nicht "die eine Wahrheit". So findet sich eine Vielzahl an Variationen des Kopfstandes. Wie wärs wenn Du Dich im Kopfstand frei bewegen könntest? Deiner Kreativität sind hier kaum Grenzen gesetzt. Bitte bedenke, dass die Variationen erst anzuführen sind, wenn Du stabil in dem Kopfstand mit gestreckten Beinen stehen kannst. Fühle Dich nicht unter Druck gesetzt. Lasse Dir Zeit auf Deinem Weg zum Kopfstand\n\nWir stellen Dir hier einige Variationen vor:',
      headerImage: require('../assets/images/onboardingJourney/headstand-variations-header.png'),
      sections: [
        {
          id: '1',
          title: 'Adler im Kopfstand',
          text: 'Die erste Variation des Kopfstandes ist, der Adler im Kopfstand. Hierzu gehst Du in den Kopfstand wie Du es bereits gelernt hast. Winde nun Deine Beine elegant umeinander und bleibe ruhig in dieser Asana stehen.\n\nFühre diese Bewegung langsam und konzentriert durch um das Gleichgewicht zu halten.',
          image: require('../assets/images/onboardingJourney/headstand-variations-1.jpg'),
        },
        {
          id: '2',
          title: 'Lotus im Kopfstand',
          text: 'Gehe in den Kopfstand. Wenn Du sicher stehst, forme die Beine zum Lotus. Legen dafür den rechten Fuß in die linke Leiste und umgekehrt. Die Fußrücken drücken leicht gegen die Oberschenkel, die Knie zeigen nach oben. Halte die Position, solange Du sicher stehen kannst. Um diese Asana zu lösen, öffne den Lotus und bringe die Beine langsam wieder nach oben ehe Du nach unten sinkst.',
          image: require('../assets/images/onboardingJourney/headstand-variations-2.jpg'),
        },
        {
          id: '3',
          title: 'Grätsche im Kopfstand',
          text: 'Gehe zunächst wieder in den Kopfstand und hebe die Beine gerade nach oben. Sobald Du ruhig stehen kannst, öffnest Du die Beine nach vorne außen. Die Zehen zeigen zum Boden und die Füße zeigen 90° zum Schienbein. Spreize die Beine nur so weit, wie es für Dich angenehm ist. Profitiere hier von der hüftöffnenden Wirkung und atme in die Dehnung.',
          image: require('../assets/images/onboardingJourney/headstand-variations-3.jpg'),
        },
      ],
    },
  },
};

// 31 items for each day of the 30 day challenge
export const CHALLENGES: string[] = [
  /* 01. */ 'balance-baum',
  /* 02. */ 'back-lower',
  /* 03. */ 'basics-warrior',
  /* 04. */ 'morning-3',
  /* 05. */ 'deep-stretch-fullbody',
  /* 06. */ 'back-full',
  /* 07. */ 'basics-hip-openers',
  /* 08. */ 'master-yin-yoga-4',
  /* 09. */ 'morning-4',
  /* 10. */ 'level-up-basics-2',
  /* 11. */ 'kurzurlaub-balance',
  /* 12. */ 'basics-heart-openers',
  /* 13. */ 'signature-crow',
  /* 14. */ 'level-up-basics-3',
  /* 15. */ 'anti-stress-1',
  /* 16. */ 'kurzurlaub-stretch',
  /* 17. */ 'splits-3',
  /* 18. */ 'back-strength',
  /* 19. */ 'signature-mermaid',
  /* 20. */ 'anti-stress-2',
  /* 21. */ 'level-up-basics-4',
  /* 22. */ 'deep-stretch-hips',
  /* 23. */ 'signature-splits',
  /* 24. */ 'toning-legs',
  /* 25. */ 'kurzurlaub-strength',
  /* 26. */ 'signature-birdofparadise',
  /* 27. */ 'toning-fullbody',
  /* 28. */ 'master-yin-yoga-5',
  /* 29. */ 'kurzurlaub-everything',
  /* 30. */ 'signature-headstand',
  /* 31. */ '-',
];

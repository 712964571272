import { useTranslation } from 'react-i18next';

import React from 'react';
import { Box } from 'theme-ui';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';

import { GoalsMeta } from '../../../constants/enums';
import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';

import JourneyProgressChallenge from './JourneyProgressChallange';

export type JourneyProgressProps = RouteComponentProps<{
  courseId?: string;
}>;

const JourneyProgressPage = ({
  courseId,
}: JourneyProgressProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Box sx={{ transition: 'filter ease-in 0.3s' }}>
      <Container>
        <Text
          variant="h2light"
          style={{ fontWeight: 'bold', marginBottom: '0' }}
          sx={{ fontSize: [2, 3], mt: [3, 7] }}
        >
          {t('MEIN_FORTSCHRITT')}
        </Text>
        <JourneyProgress courseId={courseId || '30_days_challenge'} />
      </Container>
    </Box>
  );
};

function JourneyProgress({ courseId }: JourneyProgressProps) {
  const isChallenge: boolean = courseId === GoalsMeta.be_young.id;
  function onOpenCourseDetails(courseId: string) {
    return navigate(`/courseDetail/${courseId}`, { replace: true });
  }
  return (
    <div style={styles.safeArea}>
      {isChallenge ? (
        <JourneyProgressChallenge
          onDismiss={() => navigate(-1)}
          onOpenCourseDetails={onOpenCourseDetails}
        />
      ) : (
        <JourneyProgressChallenge
          onDismiss={() => navigate(-1)}
          onOpenCourseDetails={onOpenCourseDetails}
        />
      )}
    </div>
  );
}

const styles = {
  safeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
};

export default JourneyProgressPage;

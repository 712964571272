/** @jsxImportSource theme-ui */
import { useTranslation } from 'react-i18next';

/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex } from 'theme-ui';

import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import i18n from '../../../i18n/i18n';

export interface CookieBannerProps {
  cookieName?: string;
  cookieConsentHandler: (event: { acceptedCategories: string[] }) => void;
}

const CookieBanner: React.FC<CookieBannerProps> = ({
  cookieName = 'cookies_accepted_categories',
  cookieConsentHandler,
}: CookieBannerProps) => {
  const { t } = useTranslation();

  const [showBanner, setShowBanner] = useState(true);
  useEffect(() => {
    const cookieConsent = document.cookie
      .split(';')
      .filter((item) => item.includes(cookieName))[0];

    const isCookieSet = cookieConsent?.length > 0;
    cookieConsentHandler({
      acceptedCategories: cookieConsent?.split('=')[1].split(',') || [],
    });
    setShowBanner(!isCookieSet);
  }, [cookieName, cookieConsentHandler]);

  const handleAcceptance = useCallback(
    (cookieValue): void => {
      document.cookie = `${cookieName}=${cookieValue};max-age=50400000;path=/`;
      cookieConsentHandler({ acceptedCategories: cookieValue.split(',') });
      setShowBanner(false);
    },
    [cookieName, cookieConsentHandler],
  );

  return showBanner ? (
    <Box variant="box.cookie">
      <Box
        sx={{
          textAlign: ['center', 'left'],
          flex: ['100%', '1 1 0'],
        }}
      >
        <Text variant="h3" as="h3">
          {t('COOKIE_BANNER.title')}
        </Text>
        <Text as="p" variant="small">
          {t('COOKIE_BANNER.body')}{' '}
          <a
            href={
              i18n.language === 'pl'
                ? '/pdfs/cookies-policy.pdf'
                : '/pdfs/cookies-policy_eng.pdf'
            }
            target="_blank"
            sx={{ variant: 'text.small', color: 'white', display: 'inline' }}
          >
            {t('MEHR_ERFAHREN')}
          </a>
        </Text>
      </Box>

      <Flex
        sx={{
          flex: '0 1 auto',
          justifyContent: 'space-around',
          width: ['100%', 'auto', 'auto', 'auto'],
          minWidth: 'auto',
          margin: '20px auto',
          maxWidth: '800px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          onClick={() => handleAcceptance('technically_required')}
          variant="primary"
          sx={{
            my: [3, 3, 0],
            width: ['90%', '90%', '30%'],
          }}
        >
          {t('COOKIE_BANNER.required_only')}
        </Button>
        <Button
          onClick={() => handleAcceptance('technically_required,analytics')}
          variant="primary"
          sx={{
            width: ['90%', '90%', '30%'],
          }}
        >
          {t('COOKIE_BANNER.analytics')}
        </Button>
        <Button
          onClick={() =>
            handleAcceptance('technically_required,analytics,marketing')
          }
          variant="primary"
          sx={{
            backgroundColor: '#f58220',
            my: [3, 3, 0],
            width: ['90%', '90%', '30%'],
          }}
        >
          {t('COOKIE_BANNER.marketing')}
        </Button>
      </Flex>
    </Box>
  ) : null;
};

export default CookieBanner;

const calculateVideoSize = (): { width: number; height: number } => {
  const padding = 0;
  const widthA = window.innerWidth - padding;
  const heightA = widthA * 0.562;
  const heightB = window.innerHeight - 4 * padding;
  const widthB = heightB * 1.78;
  let width;
  let height;

  if (widthA > widthB) {
    width = widthB;
    height = heightB;
  } else {
    width = widthA;
    height = heightA;
  }

  return { width, height };
};

export default calculateVideoSize;

import { Component } from 'react';
import Hls from 'hls.js';

interface Props {
  src: string;
  video?: HTMLVideoElement;
  type?: string;
  /* do not remove. required by video-react */
  isVideoChild?: boolean;
}

class HLSSource extends Component<Props> {
  hls: Hls;

  constructor(props: Props) {
    super(props);
    this.hls = new Hls();
  }

  componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    const { src, video } = this.props;
    // load hls video source base on hls.js
    if (Hls.isSupported()) {
      this.hls.loadSource(src);
      if (video != null) {
        this.hls.attachMedia(video);
      }
    }
  }

  componentWillUnmount() {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  }

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || 'application/x-mpegURL'}
      />
    );
  }
}

export default HLSSource;

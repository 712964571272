import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Image, Box } from 'theme-ui';
import Draggable from 'react-draggable';
import { useUser } from '@youga/youga-client-api';
import useTracking from '../../../hooks/useTracking';
import { navigate } from 'gatsby';
import {
  Step,
  StreamingSession,
  VideoPlayerStore,
} from '../../../types/interfaces';
import VideoSoundBeep from '../../../assets/video-sound-beep.mp3';
import VideoSoundGong from '../../../assets/video-sound-gong.mp3';
import iconSettings from '../../../assets/icons/iconSettings.svg';
import MirrorSettings from './MirrorSettings';
import API from '../../../services/API';
import useAuth from '../../../hooks/useAuth';
import OnboardingTrainingssession from '../OnboardingTrainingssession/OnboardingTrainingssession';
import Webcam from 'react-webcam';
import ArrowTrainingAnimation from '../../03_organisms/ArrowTrainingAnimation/ArrowTrainingAnimation';
import { v4 as uuidv4 } from 'uuid';
import SkeletonTrainingAnimation from '../../03_organisms/SkeletonTrainingAnimation/SkeletonTrainingAnimation';
import {
  getAnalytics,
  logEvent,
  isSupported,
  Analytics,
} from 'firebase/analytics';
/**
 * A Pose is active if we are {allowedOffset} seconds before or after the timestamp.
 */
const stepFromTime = (
  steps: Step[] = [],
  time: number,
  allowedOffsetBefore = 3,
  allowedOffsetAfter = 3,
): Step | undefined =>
  steps
    .filter((step) => step.id !== 'intro')
    .find(
      (step) =>
        step.time - allowedOffsetBefore < time &&
        step.time + allowedOffsetAfter > time,
    );

export enum VideoState {
  Pause,
  Play,
  Stop,
}

interface MirrorContainerProps {
  videoEnded: boolean;
  smartMirrorTranslate: boolean;
  webcamRef: Webcam;
  videoSessionId: string;
  steps: Step[] | undefined;
  videoPlayerStore?: VideoPlayerStore;
  videoState: VideoState;
  isConnected: boolean;
  placementSelector?: string | null;
  isOriginalPosition: boolean;
  streamingSession: StreamingSession;
  activeMirrorSize: string;
  showMirror: boolean;
  trainingSessionId: string;
  onStateChange: (connected: boolean) => void;
  onVideoDragged: (dragged: boolean) => void;
  onMirrorSizeChange: (size: string) => void;
  onShowMirrorChange: (size: boolean) => void;
}

interface ElementBoundary {
  width: number;
  height: number;
  left: number;
  top: number;
}

/**
 * This MirrorContainer component renders the OpenTok Element
 *
 * If there is a placementSelector defined, we monitor the boundingRect of this element and place our Mirror element in the exact same position
 */
const MirrorContainer: React.FC<MirrorContainerProps> = ({
  videoEnded,
  webcamRef,
  smartMirrorTranslate,
  videoSessionId,
  steps,
  videoPlayerStore,
  videoState,
  isConnected,
  showMirror,
  placementSelector,
  trainingSessionId,
  isOriginalPosition,
  onStateChange,
  onVideoDragged,
  activeMirrorSize,
  onMirrorSizeChange,
  onShowMirrorChange,
}: MirrorContainerProps) => {
  const { token } = useAuth();
  const { data: user } = useUser();
  const { track } = useTracking();
  const [audioEnabled, setAudioEnabled] = useState(
    typeof user?.preferences?.smartMirrorAudioEnabled === 'undefined'
      ? true
      : user?.preferences.smartMirrorAudioEnabled,
  );
  const [playerVolume, setPlayerVolume] = useState(0);
  const [currentPose, setCurrentPose] = useState<Step | null>(null);
  const [domNode, setDomNode] = useState<HTMLElement | null>(null);
  const [boundaries, setBoundaries] = useState<ElementBoundary | null>(null);
  const [draggingDisabled, setDraggingDisabled] = useState(true);
  const [showAnimation, setShowAnimation] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [isWsConnected, setIsWsConnected] = useState(false);
  const [start, setStart] = useState<boolean>(true);
  const [feedback, setFeedback] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [resolutionWidth, setResolutionWidth] = useState(640);
  const [resolutionHeight, setResolutionHeight] = useState(337);
  const [currUuid, setUuid] = useState(uuidv4());
  const [isUuidCorrect, setIsUuidCorrect] = useState<boolean>(true);
  const [isPaused, setPause] = useState(false);
  const [currentScreenshotDimensions, setCurrentScreenshotDimensions] =
    useState<any>(false);
  const ws = useRef(null);

  const websocketAddress = useMemo(() => {
    if (typeof window === 'undefined') {
      return 'wss://vp.app.stretchme.app/';
    }
    if (process.env.NODE_ENV === 'development') {
      return 'wss://vp.app-dev.stretchme.pl/';
    }
    if (window.location.host === 'app-beta.stretchme.app') {
      return 'wss://vp.app-beta.stretchme.app/';
    }
    if (window.location.host === 'app-dev.stretchme.pl') {
      return 'wss://vp.app-dev.stretchme.pl/';
    }
    return 'wss://vp.app.stretchme.app/';
  }, []);

  useEffect(() => {
    (ws as any).current = new WebSocket(websocketAddress);
    (ws as any).current.onopen = () => {
      console.log('isOpen - analytics!');
      track('smart-mirror-on', {
        user: 'asd',
      });

      setIsWsConnected(true);
    };
    (ws as any).current.onclose = () => {
      track('smart-mirror-off');
      setIsWsConnected(false);
    };
    const wsCurrent = ws.current;

    return () => {
      (wsCurrent as any).close(1000);
    };
  }, []);

  useEffect(() => {
    if (!ws.current) return;

    (ws.current as any).onmessage = (e: { data: string }) => {
      try {
        const parsedMessage = JSON.parse(e.data);
        if (currUuid === parsedMessage.uuid) {
          setIsUuidCorrect(true);
          setUuid(uuidv4());
        }
        setResolutionWidth(parsedMessage?.width || 640);
        setResolutionHeight(parsedMessage?.height || 337);
        setFeedback(parsedMessage.feedback || []);
        setCoordinates(parsedMessage.coordinates || []);
        if (parsedMessage.type === 'summary_feedback') {
          navigate(
            `/app/finish-session/${trainingSessionId}/${JSON.stringify(
              parsedMessage.data,
            )}`,
            {
              replace: true,
            },
          );
        }
      } catch {
        return;
      }
    };
  }, [isPaused, feedback, setFeedback, currUuid, setUuid, setCoordinates]);

  const [flipMode, setFlipMode] = useState(
    typeof user?.preferences?.smartMirrorFlip === 'undefined'
      ? false
      : user?.preferences.smartMirrorFlip,
  );
  const [currentPosition, setCurrentPosition] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >();

  const beepRef = useRef<HTMLAudioElement | null>(null);
  const gongRef = useRef<HTMLAudioElement | null>(null);
  const sidebarVisible = placementSelector === '#connected-mirror';
  const videoConstraints = {
    width: 640,
    height: 360,
  };
  /**
   * Preloads the Beep and Gong Sounds to avoid any delays when those should be played
   */
  useEffect(() => {
    if (videoState !== VideoState.Play) {
      return;
    }
    beepRef.current = new Audio(VideoSoundBeep);
    beepRef.current.volume = 0;
    gongRef.current = new Audio(VideoSoundGong);
    gongRef.current.volume = 0;

    // Promise.resolve makes sure that we always have a catchable promise here
    Promise.resolve(beepRef.current.play()).catch(() => {
      // do nothing, we could not play the sounds
    });

    // Promise.resolve makes sure that we always have a catchable promise here
    Promise.resolve(gongRef.current.play()).catch(() => {
      // do nothing, we could not play the sounds
    });
  }, [videoState]);

  const updateBoundaries = (): void => {
    if (!domNode) {
      setBoundaries(null);
      return;
    }

    const rect = domNode.getBoundingClientRect();

    if (
      boundaries?.width === rect.width &&
      boundaries.height === rect.height &&
      boundaries.left === rect.left &&
      boundaries.top === rect.top
    ) {
      return;
    }

    setBoundaries({
      width: rect.width,
      height: rect.height,
      left: rect.left,
      top: rect.top,
    });
  };

  /**
   * Updates the element boundaries every time the placementSelector changes
   */
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (placementSelector === '#connected-mirror') {
      onVideoDragged(false);
      setCurrentPosition({ x: 0, y: 0 });
      setShowAnimation(true);
      setDraggingDisabled(true);
    } else {
      setDraggingDisabled(false);
    }

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (placementSelector) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDomNode(document.querySelector(placementSelector as any));
        updateBoundaries();

        timer = setInterval(updateBoundaries, 1000);
      } else {
        setDomNode(null);
        updateBoundaries();

        if (timer) {
          clearInterval(timer);
        }
      }
    }

    return (): void => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [domNode, placementSelector, boundaries]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Starts an internal timer that for the Countdown Display (only if we allowed PoseComparison pictures)
   */
  useEffect(() => {
    let timer: NodeJS.Timeout;
    let countdownTimer: NodeJS.Timeout;

    const updateCountdown = (): void => {
      if (currentPose !== null) {
        return;
      }

      const currentPlayerVolume = videoPlayerStore?.getState().player.muted
        ? 0
        : videoPlayerStore?.getState().player.volume || 0;

      if (playerVolume !== currentPlayerVolume) {
        setPlayerVolume(currentPlayerVolume);
      }

      const nextStep = stepFromTime(
        steps,
        videoPlayerStore?.getState().player.currentTime || 0,
        3,
        0,
      );

      setCurrentPose(nextStep || null);
    };

    if (typeof window !== 'undefined' && videoState === VideoState.Play) {
      timer = setInterval(updateCountdown, 500);
    }

    return (): void => {
      clearInterval(timer);
      clearTimeout(countdownTimer);
    };
  }, [currentPose, playerVolume, steps, videoPlayerStore, videoState]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const imageSrc = (webcamRef as any).current.getScreenshot();

      if (
        !!videoPlayerStore?.getState()?.player?.currentTime &&
        isWsConnected &&
        isUuidCorrect
      ) {
        (ws.current as any).send(
          JSON.stringify({
            img: imageSrc,
            videoSessionId,
            timer: videoPlayerStore?.getState().player.currentTime,
            uuid: currUuid,
            userId: user?.id,
            trainingID: trainingSessionId,
            videoEnded: videoEnded,
          }),
        );
        setIsUuidCorrect(false);
      }
    }, 30);
    return () => clearInterval(interval);
  }, [
    start,
    webcamRef,
    videoPlayerStore,
    isUuidCorrect,
    setIsUuidCorrect,
    currUuid,
    setCurrentScreenshotDimensions,
    currentScreenshotDimensions,
    trainingSessionId,
  ]);

  useEffect(() => {
    const handle = {} as NodeJS.Timeout;
    onStateChange(true);

    // connectToVP();

    return () => {
      clearTimeout(handle);
    };
  }, []);

  return (
    <>
      {isConnected && (
        <OnboardingTrainingssession
          mirrorWidth={domNode?.getBoundingClientRect().width || 0}
        />
      )}
      <Draggable
        disabled={draggingDisabled || showSettings}
        position={currentPosition}
        onDrag={(e, { deltaX, deltaY }): void => {
          if (deltaX === 0 && deltaY === 0) {
            return;
          }

          if (currentPosition) {
            setCurrentPosition(undefined);
          }

          setShowAnimation(false);
          onVideoDragged(true);
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            zIndex: showMirror || sidebarVisible ? 106 : -1,
            '.OTSubscriberContainer': {
              visibility: showMirror || sidebarVisible ? 'visible' : 'hidden',
              position: 'absolute',
              width: '100% !important',
              height: '100% !important',
              transition: 'opacity 1s',
            },
          }}
          style={{
            top:
              smartMirrorTranslate && isOriginalPosition
                ? Math.max(boundaries?.top || 0, 80)
                : boundaries?.top,
            left: boundaries?.left,
            width: boundaries?.width,
            visibility: boundaries && isConnected ? 'visible' : 'hidden',
            pointerEvents: boundaries && isConnected ? 'all' : 'none',
            transition: boundaries ? 'all ease-in 0.3s' : undefined,
          }}
        >
          <SkeletonTrainingAnimation
            coordinates={coordinates}
            frameHeight={resolutionHeight}
            frameWidth={resolutionWidth}
          />
          <ArrowTrainingAnimation
            coordinates={feedback}
            height={resolutionHeight}
            width={resolutionWidth}
          />
          <Box
            className="fs-exclude"
            style={{
              height: '100%',
              cursor: draggingDisabled ? 'inherit' : 'pointer',
              position: 'relative',
              zIndex: -1,
            }}
            sx={{
              '.OT_root': {
                transform: flipMode ? 'scaleX(-1)' : undefined,
              },
            }}
          >
            <div
              style={{
                transform: 'scale(-1, 1)',

                display: 'block',
                overflow: 'auto',
              }}
            >
              <Webcam
                audio={false}
                style={{ width: '100%', height: '100%', borderRadius: '20px' }}
                height={360} //337
                width={640}
                ref={webcamRef as any}
                mirrored={false}
                forceScreenshotSourceSize={true}
                id="MirrorContainer"
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          </Box>

          <Box
            sx={{
              pointerEvents: 'none',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '5rem',
              background:
                'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)',
              width: '100%',
              borderRadius: '20px',
            }}
          />

          <Box
            sx={{
              pointerEvents: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '5rem',
              background:
                'linear-gradient(-180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
              width: '100%',
              borderRadius: '20px',
            }}
          />
          <Image
            src={iconSettings}
            sx={{
              p: 2,
              position: 'absolute',
              bottom: 0,
              right: 0,
              transition: 'opacity ease-out 0.3s',
              cursor: 'pointer',
              zIndex: 10000,
            }}
            style={{
              opacity: showSettings ? 0 : 1,
              pointerEvents: showSettings ? 'none' : 'all',
            }}
            onTouchStart={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              setShowSettings(true);
            }}
            onMouseMove={(e): void => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              setShowSettings(true);
            }}
          />
          <MirrorSettings
            audioEnabled={audioEnabled}
            onAudioEnabledChange={(state: boolean): void => {
              track('training-session-smart-mirror-settings-audio', {
                enabled: state,
                videoSessionId,
              });

              if (token == null) {
                throw new Error(`Token not defined.`);
              }

              API.postUserPreferences(token, {
                smartMirrorAudioEnabled: state,
                smartMirrorShowMirror: showMirror,
                smartMirrorSize: activeMirrorSize,
                smartMirrorFlip: flipMode,
              });

              setAudioEnabled(state);
            }}
            showMirror={showMirror}
            onShowMirrorChange={(show: boolean): void => {
              track('training-session-smart-mirror-settings-show-mirror', {
                enabled: show,
                videoSessionId,
              });

              if (token == null) {
                throw new Error(`Token not defined.`);
              }

              API.postUserPreferences(token, {
                smartMirrorAudioEnabled: audioEnabled,
                smartMirrorSize: activeMirrorSize,
                smartMirrorFlip: flipMode,
                smartMirrorShowMirror: show,
              });

              onShowMirrorChange(show);
            }}
            visible={showSettings}
            onClose={(): void => {
              setShowSettings(false);
            }}
            activeMirrorSize={activeMirrorSize}
            onMirrorSizeChange={(size: string): void => {
              track('training-session-smart-mirror-settings-size', {
                size,
                videoSessionId,
              });

              if (token == null) {
                throw new Error(`Token not defined.`);
              }

              API.postUserPreferences(token, {
                smartMirrorAudioEnabled: audioEnabled,
                smartMirrorShowMirror: showMirror,
                smartMirrorFlip: flipMode,
                smartMirrorSize: size,
              });

              onMirrorSizeChange(size);
              setTimeout(updateBoundaries, 100);
            }}
            onFlipImage={(): void => {
              track('training-session-smart-mirror-settings-flip-mode', {
                flipped: !flipMode,
                videoSessionId,
              });

              if (token == null) {
                throw new Error(`Token not defined.`);
              }

              API.postUserPreferences(token, {
                smartMirrorAudioEnabled: audioEnabled,
                smartMirrorShowMirror: showMirror,
                smartMirrorSize: activeMirrorSize,
                smartMirrorFlip: !flipMode,
              });

              setFlipMode((state) => !state);
            }}
          />

          {isOriginalPosition && boundaries && (
            <Box
              sx={{
                pointerEvents: 'none',
                position: 'absolute',
                top: '1rem',
                left: 0,
                color: 'white',
                fontFamily: 'heading',
                fontSize: 2,
                fontWeight: 'bold',
                transition: 'opacity ease-out 0.3s, transform ease-out 0.3s',
              }}
              style={{
                transform: !showSettings
                  ? `translateX(calc(${boundaries.width}px - 100% - 1.5rem))`
                  : `translateX(1.5rem)`,
              }}
            >
              SmartMirror
            </Box>
          )}
        </Box>
      </Draggable>
    </>
  );
};

export default MirrorContainer;

import * as React from 'react';
import { Image } from 'theme-ui';

import { useUser, useFavorites } from '@youga/youga-client-api';

import IconFavActiveBlack from './icons_favoritesActive.svg';
import IconFavNotActiveBlack from './icons_favorites.svg';
import IconFavNotActive from './icons_favoritesWhite.svg';
import IconFavActive from './icons_favoritesActiveWhite.svg';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    position: 'absolute',
    top: 0,
    right: 0,
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  wrapperBig: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    position: 'absolute',
    top: 60,
    left: 350,
    width: 40,
    height: 30,
    cursor: 'pointer',
  },
  iconSmall: {
    zIndex: 999,
    width: 25,
    height: 25,
    cursor: 'pointer',
  },
  icon: {
    zIndex: 999,
    width: 25,
    height: 25,
    cursor: 'pointer',
  },
};

interface FavoriteProps {
  courseId: string;
  size: 'small' | 'big';
  color?: 'white' | 'black';
}

const Favorite: React.FC<FavoriteProps> = ({
  courseId,
  size,
  color,
}: FavoriteProps) => {
  const { data: user } = useUser();
  const { updateFavorites } = useFavorites(courseId);
  const iconColor = color ?? 'white';

  let IconFavoriteActive =
    size === 'small' ? (
      <Image src={IconFavActive} style={styles.iconSmall} />
    ) : (
      <Image src={IconFavActive} style={styles.icon} />
    );

  let IconFavoriteNotActive =
    size === 'small' ? (
      <Image src={IconFavNotActive} style={styles.iconSmall} />
    ) : (
      <Image src={IconFavNotActive} style={styles.icon} />
    );

  if (iconColor === 'black') {
    IconFavoriteActive = <Image src={IconFavActiveBlack} style={styles.icon} />;

    IconFavoriteNotActive = (
      <Image src={IconFavNotActiveBlack} style={styles.icon} />
    );
  }

  return (
    <div
      onClick={updateFavorites}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        position: 'absolute',
        top: 0,
        right: 0,
        width: 40,
        height: 40,
      }}
    >
      {user?.preferences?.favoriteCourses?.indexOf(courseId) !== -1 &&
      user?.preferences?.favoriteCourses?.indexOf(courseId) !== undefined
        ? IconFavoriteActive
        : IconFavoriteNotActive}
    </div>
  );
};

export default Favorite;

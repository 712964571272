/* eslint-disable @typescript-eslint/unbound-method */
import React, { useRef, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Box } from 'theme-ui';
import { RouteComponentProps } from '@reach/router';

import env from '../../../environment';

import Layout from '../../../global/Layout';
import Seo from '../../../global/Seo';
import { StreamingSession } from '../../../types/interfaces';
import toggleFullScreen from '../../../utils/toggleFullScreen';
import PublisherCamera from '../../02_molecules/PublisherCamera/PublisherCamera';
import getObjectFitSize from '../../../utils/getObjectFitSize';

export type CameraSectionProps = RouteComponentProps<{ streamId: string }>;

const CameraSection = ({
  streamId,
}: CameraSectionProps): React.ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [session, setSession] = useState<StreamingSession>();
  const [fitSize, setFitSize] = useState<{ width: number; height: number }>();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      (document.querySelector('html') as HTMLElement).classList.add(
        'camera-overflow',
      );
    }

    return (): void => {
      (document.querySelector('html') as HTMLElement).classList.remove(
        'camera-overflow',
      );
    };
  }, []);

  useEffect(() => {
    async function getSession(): Promise<void> {
      if (streamId) {
        try {
          const response = await axios(
            `${env.GATSBY_API_URL}/session?code=${streamId}`,
          );
          setSession(response.data);
        } catch (e) {
          Sentry.setContext('request', {
            method: 'get',
            url: `${env.GATSBY_API_URL}/session?code=${streamId}`,
          });

          throw e;
        }
      }
    }
    getSession();
  }, [streamId]);

  useEffect(() => {
    const onResize = debounce((): void => {
      setFitSize(getObjectFitSize(window.innerWidth, window.innerHeight, 4, 3));
    }, 100);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      onResize();
    }

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Layout theme="dark-transparent" disableCookieBanner>
      <Seo title="Camera" titleTemplate="" />
      <Box ref={containerRef} sx={{ backgroundColor: '#000000' }}>
        <Box
          className="camera-container"
          sx={{
            backgroundColor: '#000000',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          style={{
            width: fitSize?.width || 400,
            height: fitSize?.height || 300,
          }}
          onClick={toggleFullScreen}
        >
          {session?.apiKey && (
            <PublisherCamera
              apiKey={session.apiKey}
              sessionId={session.sessionID}
              token={session.token}
            />
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default CameraSection;

import { useTranslation } from 'react-i18next';

import { Box, Image, Grid } from 'theme-ui';
import React from 'react';
import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import Header from '../../02_molecules/Header/Header';
import ConfirmationGateImage from '../../../assets/images/ConfirmationGate.png';

const ConfirmationGate: React.FC<unknown> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header showButton={null} theme="light" />

      <Box
        sx={{
          position: 'relative',
          minHeight: 'calc(100vh - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
        }}
      >
        <Container>
          <Grid columns={[1, 1, 2]} gap="2.5rem">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: ['center', null, 'left'],
              }}
            >
              <Text variant="h1" sx={{ m: 0 }}>
                {t('DEINE_EMAIL_ADRESSE_BEST_TIGEN')}
              </Text>
              <Text
                sx={{ fontSize: 0, mt: 5, lineHeight: 1.5, letterSpacing: 0 }}
              >
                {t('DU_HAST_VERSUCHT_DEINE_EMAIL_A')}
              </Text>
            </Box>

            <Box
              sx={{
                mt: [6, null, 0],
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={ConfirmationGateImage}
                sx={{ maxHeight: '100vh', maxWidth: ['50%', null, '100%'] }}
              />
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ConfirmationGate;

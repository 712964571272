import * as React from 'react';
import { navigate } from 'gatsby';
import { Box } from 'theme-ui';
import type { RouteComponentProps } from '@reach/router';

import { useConnector } from '@youga/youga-client-api';

import Text from '../../01_atoms/Text/Text';
import Spinner from '../../01_atoms/Spinner/Spinner';
import Container from '../../01_atoms/Container/Container';
import Header from '../../02_molecules/Header/Header';
import IconExclamation from '../../../assets/icons/iconExclamation.inline.svg';
import { useConnectHandler } from '../../../services/connect';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ConnectSection(_props: RouteComponentProps) {
  const { data, error } = useConnector();
  const { result } = useConnectHandler(data);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (result?.type === 'auth-success') {
      // TODO: (pwo) we should show here that the user didn't selected a video session to start a video
      navigate(`/app`);
    } else if (result?.type === 'connect-video') {
      const { streamingSession, trainingSessionId, videoId } = result.payload;
      navigate(`/connect/${result.payload.connectId}`, {
        state: { streamingSession, trainingSessionId, videoId },
      });
    }
  }, [result]);

  return (
    <>
      <Header theme="dark-transparent" />

      <Container>
        <Text variant="h2" sx={{ color: 'white', mb: 0 }}>
          Smart Mirror:
        </Text>
        <Text variant="h2" sx={{ color: 'white', fontWeight: 'normal', mt: 0 }}>
          {t('CONNECT_YPOUR_PHONE')}
        </Text>

        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '250px',
            height: '250px',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("${data?.connectDataUrl || ''}")`,
          }}
        />

        <Box
          sx={{
            position: 'fixed',
            top: 'calc(50% + 125px + 10px)',
            left: '50%',
            transform: 'translate(-50%)',
            color: 'white',
            textAlign: 'center',
          }}
        >
          {t('SCAN_QR_CODE')}
          <strong> {t('CAMERA_APP')} </strong> {t('ON_YOUR_IOS_DEVICE')}
        </Box>

        {error != null ? (
          <>
            <Box>
              <IconExclamation
                sx={{
                  display: 'block',
                  width: '3rem',
                  height: '3rem',
                  margin: '0 auto',
                  '> *': { fill: 'error' },
                }}
              />
            </Box>
            <Box>
              <Text variant="h3">{t('SORRY_2')}</Text>
              <Text>
                <span>
                  {t('SOMETHING_WENT_WRONG')}
                  <br />
                  {t('CONTACT_US')}
                  <br />
                  <a href={`mailto:${t('INFO_MAIL')}`}>{t('INFO_MAIL')}</a>
                </span>
              </Text>
            </Box>
          </>
        ) : null}

        {Array.isArray(data?.actions) ? (
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '250px',
              height: '250px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <Spinner color="#ffffff" />
          </Box>
        ) : null}
      </Container>
    </>
  );
}

export default ConnectSection;

import { useTranslation } from 'react-i18next';

import React from 'react';

export interface VideoText {
  title?: string;
  subtitle?: string;
  trainer?: string;
}

const VideoTitlesOverlay: React.FC<VideoText> = ({
  title,
  subtitle,
  trainer,
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '100px',
        left: '30px',
        zIndex: 1,
        fontFamily: 'Montserrat,sans-serif',
        letterSpacing: '0px',
        lineHeight: '35px',
        color: '#ffffff',
      }}
    >
      <p
        style={{
          margin: '0 0 0 0',
          fontWeight: 'bold',
          fontSize: '32px',
        }}
      >
        {title}:
      </p>

      <p
        style={{
          margin: '0 0 0 0',
          fontWeight: 'normal',
          fontSize: '32px',
        }}
      >
        {subtitle}
      </p>
      <p
        style={{
          margin: '0 0 0 0',
          fontWeight: 'normal',
          fontSize: '18px',
        }}
      >
        {trainer}
      </p>
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default VideoTitlesOverlay;

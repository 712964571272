import { useTranslation } from 'react-i18next';

import React from 'react';
import { Link } from 'gatsby';
import { Box, Grid, Image } from 'theme-ui';
import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import PoseComparisonSessionItem from '../../01_atoms/PoseComparisonSessionItem/PoseComparisonSessionItem';
import SeheDeinenFortschrittselbst from '../../../assets/SeheDeinenFortschrittselbst.svg';

const PoseComparisonOverviewEmpty: React.FC<unknown> = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mt: 9,
        pt: 6,
        pb: 6,
        backgroundColor: 'grey1',
        position: 'relative',
      }}
    >
      <Container>
        <Grid
          columns={[1, 3, 4]}
          sx={{ columnGap: [0, 5, null, 11], rowGap: 6 }}
        >
          <Box sx={{ width: '232px', display: 'flex', alignItems: 'center' }}>
            <PoseComparisonSessionItem
              key="example"
              poseImage="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/pose-comparison-example/reference-pics/189.jpg"
              userImage="https://yougacdkstackprod-imagebucket6194e37c-op33z8eeb47w.s3.eu-central-1.amazonaws.com/pose-comparison-example/user-pics/189.png"
              sessionName={t('POSE_COMPARISON_EXAMPLE')}
              sessionId="example"
              target="/app/pose-comparison/example"
            />
          </Box>
          <Box
            sx={{
              gridColumn: [1, '2 / 5'],
            }}
          >
            <Image src={SeheDeinenFortschrittselbst} />
            <Text
              variant="default"
              sx={{
                mt: 3,
                maxWidth: '640px',
                fontSize: 0,
                lineHeight: 1.5,
                letterSpacing: 0,
              }}
            >
              {t('MIT_HILFE_DER_POSE_COMPARISON')}
            </Text>
            <Link to="/app/pose-comparison/example">
              <Button variant="primary" sx={{ mt: 6 }}>
                {t('BEISPIEL_ANSEHEN')}
              </Button>
            </Link>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default PoseComparisonOverviewEmpty;

import React from 'react';
import TrainingArrow from './TrainingArrow';
import ProximityArrows from './ProximityArrows';
import SoundFeedback from './SoundFeedback';

type Props = {
  coordinates: any;
  width: number;
  height: number;
};

export type ArrowProps = {
  arrow1X: number;
  arrow1Y: number;
  angleArrow1: number;
  arrow2X: number;
  arrow2Y: number;
  ankleArrow2: number;
  key: string;
  type?: string;
};

type Points =
  | ['standard' | 'proximity', number[], number[], number[], number[]]
  | [];

function ArrowTrainingAnimation({ coordinates, width, height }: Props) {
  const mirrorContainerElement = document.getElementById('MirrorContainer');
  const mirrorHeight = mirrorContainerElement?.clientHeight || 0;
  const mirrorWidth = mirrorContainerElement?.clientWidth || 0;

  const isPoseValid =
    coordinates
      .map((item: number[]) => item.length)
      .reduce((acc: number, a: number) => acc + a, 0) == 0;

  const reflectXCoordinates = (val: number) => {
    return Math.abs(val - mirrorWidth);
  };

  const scaleCoordinateX = (val: number) => {
    const xFactor = mirrorWidth / width;
    return val * xFactor;
  };

  const scaleCoordinateY = (val: number) => {
    const yFactor = mirrorHeight / height;
    return val * yFactor;
  };

  const getAnkleFromUnityCircle = (unityY: number, unityX: number) => {
    return (Math.atan2(unityY, unityX * -1) * 180) / Math.PI + 90;
  };

  const mapPointToArrow = (
    originPoint: number[],
    unityCirclePoint: number[],
  ) => {
    const scaledX = scaleCoordinateX(originPoint[0]);
    const scaledY = scaleCoordinateY(originPoint[1]);
    const startY = scaledY;
    const startX = reflectXCoordinates(scaledX);
    const angleDeg = getAnkleFromUnityCircle(
      unityCirclePoint[1],
      unityCirclePoint[0],
    );
    return [startX, startY, angleDeg];
  };

  const mappedCoordinates = coordinates
    .map((points: Points, idx: number) => {
      if (points.length === 0) {
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [type, point1, unityCircle1, ...other] = points;
      const [arrow1X, arrow1Y, angleArrow1] = mapPointToArrow(
        point1,
        unityCircle1,
      );
      let arrow2X,
        arrow2Y,
        ankleArrow2 = null;
      if (type === 'proximity') {
        const [point2, unityCircle2] = other;
        [arrow2X, arrow2Y, ankleArrow2] = mapPointToArrow(point2, unityCircle2);
      }
      return {
        arrow1X,
        arrow1Y,
        angleArrow1,
        arrow2X,
        arrow2Y,
        ankleArrow2,
        type,
        key: idx,
      };
    })
    .filter((el: any) => el);

  if (!mirrorContainerElement) {
    return null;
  }

  return (
    <>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            borderStyle: 'solid',
            borderWidth: '5px',
            borderColor: isPoseValid ? '#00FF00' : '#FF2B41',
            borderRadius: '20px',
            position: 'relative',
            overflow: 'hidden',
            transition: 'border-color 1000ms linear',
          }}
        >
          {mappedCoordinates.map(
            ({
              arrow1X,
              arrow1Y,
              angleArrow1,
              arrow2X,
              arrow2Y,
              ankleArrow2,
              key,
              type,
            }: ArrowProps) => (
              <React.Fragment key={key}>
                {type === 'standard' && (
                  <TrainingArrow
                    key={key}
                    xPosition={arrow1X}
                    yPosition={arrow1Y}
                    ankle={angleArrow1}
                  />
                )}
                {type == 'proximity' && (
                  <ProximityArrows
                    key={key}
                    arrow1X={arrow1X}
                    arrow1Y={arrow1Y}
                    angleArrow1={angleArrow1}
                    arrow2X={arrow2X}
                    arrow2Y={arrow2Y}
                    ankleArrow2={ankleArrow2}
                  />
                )}
              </React.Fragment>
            ),
          )}
        </div>
      </div>
      <SoundFeedback isPoseValid={isPoseValid} />
    </>
  );
}

export default ArrowTrainingAnimation;

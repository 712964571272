/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { SWRConfig } from 'swr';
import { Amplify } from 'aws-amplify';

import {
  YougaClientApiProvider,
  createApiFetcher,
} from '@youga/youga-client-api';

import env from './environment';
import amplifyConfig from './amplify.config';

import { EnvProvider, useEnv } from './context/EnvContext';
import useAuth, { AuthProvider } from './hooks/useAuth';
import { StripeProvider } from './context/StripeContext';

// Initialize Firebase

Amplify.configure(amplifyConfig);

const ClientSideOnlyLazy = React.lazy(() => import('./client-side-only'));

function LazyLoader() {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy />
        </React.Suspense>
      )}
    </>
  );
}

/**
 * Used in `gatsby-node` and `gatsby-browser`
 * to share a global state between all pages
 *
 * @see {@link https://www.gatsbyjs.com/docs/porting-from-create-react-app-to-gatsby/#context-providers}
 */
function AppProviders({ children }: React.PropsWithChildren<unknown>) {
  return (
    <>
      <LazyLoader />
      <EnvProvider
        env={{
          apiBaseUrl: env.GATSBY_API_URL,
          revenueCatApiKey: env.GATSBY_REVENUE_CAT_API_KEY,
          gracePeriod: env.GATSBY_GRACE_PERIOD,
        }}
      >
        <StripeProvider>
          <AuthProvider clientId={amplifyConfig.Auth.userPoolWebClientId}>
            <ThirdPartyProviders>{children}</ThirdPartyProviders>
        </AuthProvider>
        </StripeProvider>
      </EnvProvider>
    </>
  );
}

function ThirdPartyProviders({ children }: React.PropsWithChildren<unknown>) {
  const auth = useAuth();
  const env = useEnv();
  return (
    <SWRConfig
      value={{
        fetcher: createApiFetcher(env.apiBaseUrl, auth.token ?? undefined),
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <YougaClientApiProvider
        baseUrl={env.apiBaseUrl}
        sentry={Sentry}
        userId={auth.user?.attributes.sub}
        token={auth.token ?? undefined}
      >
        {children}
      </YougaClientApiProvider>
    </SWRConfig>
  );
}

export default AppProviders;

import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import Text from '../Text/Text';

export interface OvalNumberProps {
  number: number;
  sx?: ThemeUIStyleObject;
}

const OvalNumber: React.FC<OvalNumberProps> = ({
  sx,
  number,
}: OvalNumberProps) => (
  <Box sx={{ textAlign: 'center', ...sx }}>
    <Text
      sx={{
        display: 'inline-block',
        width: '22px',
        height: '22px',
        textAlign: 'center',
        lineHeight: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'black',
        mt: 0,
        mx: 'auto',
        mb: 2,
        border: '1px solid black',
        borderRadius: '50%',
      }}
    >
      {number}
    </Text>
  </Box>
);

export default OvalNumber;

import { AxiosResponse } from 'axios';
import API from '../../../services/API';
import { Step, CameraAngle } from '../../../types/interfaces';

/**
 * A Pose is active if we are {allowedOffset} seconds before or after the timestamp.
 */
const stepFromTime = (
  steps: Step[] = [],
  time: number,
  allowedOffsetBefore = 3,
  allowedOffsetAfter = 3,
): Step | undefined =>
  steps
    .filter((step) => step.id !== 'intro')
    .find(
      (step) =>
        step.time - allowedOffsetBefore < time &&
        step.time + allowedOffsetAfter > time,
    );

const angleFromTime = (
  cameraAngles: CameraAngle[] = [],
  time: number,
): CameraAngle => {
  let index = (cameraAngles || []).findIndex((s) => s.time > time);
  if (index === -1) {
    index = cameraAngles.length;
  }
  return cameraAngles[index - 1];
};

const saveBurstPhotos = async (
  userId: string,
  sessionId: string,
  imageName: string,
  poseName: string,
  videoCode: string,
  videoSessionId: string,
  videoSessionName: string,
  instructorId: string,
  burstPhotos: {
    difference: number;
    picture: string | null;
  }[],
  image: string | null,
): Promise<void | AxiosResponse<unknown>> => {
  if (!userId || !image || !videoCode || !sessionId) {
    return Promise.resolve();
  }
  const data = {
    imageName,
    poseName,
    videoCode,
    userId,
    sessionId,
    base64image: image,
    burstPhotos,
    videoSessionId,
    videoSessionName,
    instructorId,
  };

  return API.postCapture(data);
};

export { stepFromTime, angleFromTime, saveBurstPhotos };

import { Select, SelectProps } from 'theme-ui';
import theme from '../../../gatsby-plugin-theme-ui';

interface CustomSelectProps {
  isHeading?: boolean;
}

const Style = {
  base: {
    fontFamily: theme.fonts.heading,
  },
};

export default function (props: SelectProps & CustomSelectProps) {
  return (
    <Select {...props} style={{ ...props.style, ...Style.base }}>
      {props.children}
    </Select>
  );
}

import React, { useRef, useEffect } from 'react';
import Negative from '../../../assets/negative-feedback.mp3';
import Positive from '../../../assets/positive-feedback.mp3';

type Props = {
  isPoseValid: boolean;
};

const SoundFeedback = ({ isPoseValid }: Props) => {
  const positiveSound = useRef<HTMLAudioElement | null>(null);
  const negativeSound = useRef<HTMLAudioElement | null>(null);
  const lastPlayedSound = useRef<'positive' | 'negative'>('positive');
  const longCashedPositions = useRef<boolean[]>([]);
  useEffect(() => {
    positiveSound.current = new Audio(Positive);
    negativeSound.current = new Audio(Negative);
  }, []);

  // we need to store values from previous rerenders to track postion changes
  longCashedPositions.current = [
    ...longCashedPositions.current,
    isPoseValid,
  ].slice(-50);

  const isCashedValuesPositive = () => {
    // it returns true if 70% of cashed values are positive
    return (
      longCashedPositions.current.reduce(
        (acc, item) => acc + (item ? 1 : 0),
        0,
      ) >
      longCashedPositions.current.length * 0.7
    );
  };

  // # positiveSound
  if (isCashedValuesPositive() && lastPlayedSound.current !== 'positive') {
    lastPlayedSound.current = 'positive';
    positiveSound.current?.play();
  }
  // negative sound
  if (!isCashedValuesPositive() && lastPlayedSound.current !== 'negative') {
    lastPlayedSound.current = 'negative';
    negativeSound.current?.play();
  }

  return <></>;
};

export default SoundFeedback;

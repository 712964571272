import * as React from 'react';
import { navigate } from 'gatsby';

import Header from '../components/02_molecules/Header/Header';
import AuthSection from '../components/03_organisms/AuthSection/AuthSection';
import useAuth from '../hooks/useAuth';
import Page from '../components/04_templates/Page';
import Footer from '../components/02_molecules/Footer/Footer';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AndroidPromoLink from '../components/01_atoms/AndroidPromoLink';

function LoginPage() {
  const { isSignedIn, isInitialized } = useAuth();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (isInitialized && isSignedIn) {
      navigate(`/app`, { replace: true });
    }
  }, [isInitialized, isSignedIn]);

  return (
    <Page title={t('LOGIN_PAGE_META_TITLE')} authRequired={false}>
      <LoginPageContent />
      <Footer />
    </Page>
  );
}

function LoginPageContent() {
  return (
    <>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content={`app-id=1638365597, app-argument=${
            typeof window === 'undefined' ? '' : window?.location?.href
          }`}
        />
      </Helmet>
      <AndroidPromoLink />
      <Header
        theme="light"
        backLinkOverride="https://www.app.stretchme.app/"
        showConnectButton={true}
      />
      <AuthSection />
    </>
  );
}

export default LoginPage;

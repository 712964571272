import { useTranslation } from 'react-i18next';

import React, { useState } from 'react';
import { Box, Grid } from 'theme-ui';
import type { RouteComponentProps } from '@reach/router';

import {
  useCoursesAll,
  useCoursesFavourites,
  useInstructors,
} from '@youga/youga-client-api';

import useTracking from '../../../hooks/useTracking';

import Container from '../../01_atoms/Container/Container';
import CourseItem from '../../01_atoms/CourseItem/CourseItem';
import Header, { useIsMenuOpen } from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Select from '../../01_atoms/Select/Select';
import i18n from '../../../i18n/i18n';

function CourseOverviewSection(_props: RouteComponentProps) {
  const { t } = useTranslation();
  const { data: instructorsData } = useInstructors();
  const { track } = useTracking();
  const [selectedCourseFilter, setSelectedCourseFilter] = useState<
    'all' | 'favorites'
  >('all');

  // 1.- Track InstructorDetailSection
  React.useEffect(() => {
    track('course-overview-section');
  }, [track]);

  const allCourses = useCoursesAll(i18n.language);
  const favCourses = useCoursesFavourites(i18n.language);

  const isMenuOpen = useIsMenuOpen();
  const courses =
    selectedCourseFilter === 'favorites' ? favCourses : allCourses;

  return (
    <>
      <Header theme="light" showButton="back" />

      <Box
        sx={{ transition: 'filter ease-in 0.3s' }}
        style={isMenuOpen ? { filter: 'blur(5px)' } : {}}
      >
        <Container>
          <Text
            variant="h2"
            sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}
            highlighted
          >
            {t('ALLE_KURSE')}
          </Text>
          {favCourses.length > 0 && (
            <Select
              arrow={
                <Box
                  as="svg"
                  sx={{
                    ml: -28,
                    alignSelf: 'center',
                    pointerEvents: 'none',
                    height: 24,
                    width: 24,
                    viewBox: '0 0 24 24',
                    fill: 'currentcolor',
                  }}
                >
                  <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                </Box>
              }
              variant="whiteBorder"
              defaultValue={t('ALLE_KURSE')}
              onChange={(e) => {
                const value =
                  e.target.value === 'favorites' ? 'favorites' : 'all';
                setSelectedCourseFilter(value);
              }}
              sx={{
                marginLeft: '40%',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '224px',
                height: '40px',
                whiteSpace: 'nowrap',
                borderRadius: '30px',
              }}
            >
              <option value="all">{t('ALLE_KURSE')}</option>

              <option value="favorites">{t('FAVORITEN')}</option>
            </Select>
          )}

          <Grid
            columns={[1, 2, 3]}
            sx={{ columnGap: '5rem', rowGap: '3.75rem', pb: 6, pt: 6 }}
          >
            {courses.map((course) => {
              return (
                <CourseItem
                  key={course.id}
                  courseId={course.id}
                  title={course.title}
                  content={course.subtitle}
                  thumbnailUrl={course.thumbnail || ''}
                  thumbnailHqUrl={course.thumbnailHq || ''}
                  level={course.level}
                  labels={course.labels}
                  preview={course.preview}
                  sessions={course.sessions.length}
                  instructor={
                    instructorsData?.instructors[course.instructor]?.name ?? ''
                  }
                  instructorId={course.instructor}
                  target={`/app/course-detail/${course.id}`}
                  sx={{ width: '100%' }}
                />
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CourseOverviewSection;

import { useTranslation } from 'react-i18next';

import React, { useCallback, useMemo } from 'react';
import { Image, Box } from 'theme-ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';
import {
  useCourses,
  useInstructors,
  useTrainingSessions,
} from '@youga/youga-client-api';
import { Course } from '@youga/youga-interfaces';

import { isCompleted } from '../../../helper/JourneyHelpers';

import IllustrationJourney from '../../../assets/illustrationJourney.png';
import IllustrationJourneyBackground from '../../../assets/illustrationJourneyBackground.svg';

import Carousel from '../../02_molecules/Carousel/Carousel';
import Container from '../../01_atoms/Container/Container';
import CourseCarouselItem from '../CourseCarouselItem/CourseCarouselItem';
import Header from '../../02_molecules/Header/Header';
import PoseComparisonCarousel from '../../02_molecules/PoseComparisonCarousel/PoseComparisonCarousel';
import Spinner from '../../01_atoms/Spinner/Spinner';
import Text from '../../01_atoms/Text/Text';
import i18n from '../../../i18n/i18n';

const MeinBereichSection: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const { data: trainingSessions } = useTrainingSessions();
  const { data: instructorsData, isValidating } = useInstructors();
  const { data: coursesData } = useCourses(i18n.language);

  // calculate Abgeschlossene Sessions for this week, this month and this year
  const trainingSessionStats = useMemo(() => {
    const now = new Date();
    const monday = new Date();
    const today = monday.getDay() || 7; // Get current day number, converting Sun. to 7
    if (today !== 1) {
      // Only manipulate the date if it isn't Mon.
      monday.setHours(-24 * (today - 1));
    } else {
      monday.setHours(0, 0);
    }
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const firstDayOfYear = new Date(now.getFullYear(), 1, 1);

    return (trainingSessions || [])
      .filter((session) => (session.completion || 0) >= 60)
      .reduce(
        (prev, session) => ({
          year:
            new Date(session.timestamp) > firstDayOfYear
              ? prev.year + 1
              : prev.year,
          month:
            new Date(session.timestamp) > firstDayOfMonth
              ? prev.month + 1
              : prev.month,
          week:
            new Date(session.timestamp) > monday ? prev.week + 1 : prev.week,
        }),
        { year: 0, month: 0, week: 0 },
      );
  }, [trainingSessions]);

  // filter for already started courses
  const courseFilter = useCallback(
    (course: Course): boolean =>
      course.sessions.filter((sessionId: string): boolean =>
        (trainingSessions || []).some(
          (trainingSession): boolean =>
            isCompleted(trainingSession.completion || 0) &&
            trainingSession.videoSessionId === sessionId,
        ),
      ).length > 0,
    [trainingSessions],
  );

  const startedCourseCount = useMemo(
    (): number =>
      Object.values(coursesData?.courses || {}).filter(courseFilter).length,
    [coursesData, courseFilter],
  );

  const startedCourses = useMemo(
    (): Course[] =>
      Object.values(coursesData?.courses || {}).filter(courseFilter),
    [coursesData, courseFilter],
  );

  const renderCourseCarouselItems = useMemo(
    () =>
      (startedCourses || []).map((course: Course, index: number) => {
        if (!course) {
          return null;
        }

        return (
          <CourseCarouselItem
            target={`/app/course-detail/${course.id}`}
            key={`coursecarousel-item-${course.id}`}
            courseId={course.id}
            title={course.title}
            content={course.subtitle}
            sessions={course.sessions.length}
            thumbnailUrl={course.thumbnail || ''}
            thumbnailHqUrl={course.thumbnailHq || ''}
            labels={course.labels}
            level={course.level}
            preview={course.preview}
            instructorId={course.instructor}
            instructor={
              (instructorsData?.instructors[course.instructor] || {}).name || ''
            }
            isLastElement={
              (coursesData?.sortedCourses || []).length - 1 === index
            }
          />
        );
      }),
    [coursesData, instructorsData, startedCourses],
  );

  if (instructorsData == null && isValidating) {
    return (
      <Container>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </Container>
    );
  }

  if (coursesData == null) {
    return (
      <Container>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </Container>
    );
  }

  return (
    <>
      <Header showButton="back" theme="light" />

      {/* Pose Comparison Box */}
      <Box sx={{ pt: [4] }}>
        <Container>
          <Text
            variant="h2"
            sx={{
              fontSize: [2, 4],
              mt: [3, 7],
              mb: [7, 12],
              display: 'block',
            }}
            highlighted
          >
            {t('MEIN_BEREICH')}
          </Text>
          <Text variant="h2light" sx={{ fontSize: [3, 4], mt: 0, mb: [3, 4] }}>
            {t('DEINE_POSE_COMPARISONS_PER_S')}
          </Text>
        </Container>
        <PoseComparisonCarousel />
      </Box>

      {/* Bereits gestartete Kurse Box. Only show when the are some */}
      {startedCourseCount > 0 && (
        <Box>
          <Container>
            <Text
              variant="h2light"
              sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}
            >
              {t('BEREITS_GESTARTETE_KURSE')}
            </Text>
            <Box
              style={{
                position: 'relative',
                // We need to extend the width a bit, to allow the shadow to be visible
                // This space will be added afterwards as a padding-left at the items
                marginLeft: '-20px',
                marginRight: '-20px',
                width: 'calc(100% + 40px)',
              }}
            >
              <Carousel
                imageHeight={180}
                hideControlsOnTouchDevices
                hideControlShadow
              >
                {renderCourseCarouselItems}
              </Carousel>
            </Box>
          </Container>
        </Box>
      )}

      {/* Deiner Journey Box */}
      <Box>
        <Container>
          <Text
            variant="h2light"
            sx={{ fontSize: [2, 4], mb: [3, 5], display: 'block' }}
          >
            {t('DEINE_JOURNEY')}
          </Text>
          <Box sx={{ display: 'grid', placeItems: 'center' }}>
            <Text>{t('ABGESCHLOSSENE_SESSIONS')}</Text>
            <Box sx={{ display: 'flex', textAlign: 'center' }}>
              <Box sx={{ margin: '2rem' }}>
                <Text sx={{ fontSize: 5, fontWeight: 700 }}>
                  {trainingSessionStats.week}
                </Text>
                <Text variant="sizeTinyBold">{t('DIESE_WOCHE')}</Text>
              </Box>
              <Box sx={{ margin: '2rem' }}>
                <Text sx={{ fontSize: 5, fontWeight: 700 }}>
                  {trainingSessionStats.month}
                </Text>
                <Text variant="sizeTinyBold">{t('DIESEN_MONAT')}</Text>
              </Box>
              <Box sx={{ margin: '2rem' }}>
                <Text sx={{ fontSize: 5, fontWeight: 700 }}>
                  {trainingSessionStats.year}
                </Text>
                <Text variant="sizeTinyBold">{t('DIESES_JAHR')}</Text>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            backgroundImage: `url(${IllustrationJourneyBackground})`,
            width: '100%',
            height: '400px',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Image
            src={IllustrationJourney}
            alt="Journey Background"
            sx={{
              height: '400px',
              display: 'inline',
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MeinBereichSection;

import env from '../environment';

let pc: RTCPeerConnection;

function createPeerConnection(onConnected: () => void) {
  const config = {
    sdpSemantics: 'unified-plan',
    iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }],
  };

  pc = new RTCPeerConnection(config);

  pc.addEventListener(
    'iceconnectionstatechange',
    function () {
      if (pc.iceConnectionState === 'connected') onConnected();
    },
    false,
  );

  // connect audio / video
  pc.addEventListener('track', function (evt) {
    if (evt.track.kind == 'video') {
      console.log('Assigning video');
      (document.getElementById('video') as HTMLVideoElement).srcObject =
        evt.streams[0];
    }
  });

  return pc;
}

const baseUrl = env.GATSBY_VIDEO_PROCESSOR_URL;

export async function pingSession(sessionId: string | undefined) {
  if (!sessionId) return;
  try {
    const response = await fetch(`${baseUrl}/pingSession`, {
      body: sessionId,
      headers: {
        'Content-Type': 'text/plain',
      },
      method: 'POST',
    });

    const result = await response.text();
    return result === 'true' ? true : false;
  } catch (error) {
    return false;
  }
}

export async function pauseTimer(sessionId: string | undefined) {
  if (!sessionId) return;
  try {
    await fetch(`${baseUrl}/pauseTimer`, {
      body: JSON.stringify({
        sessionId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  } catch (error) {
    console.log(error);
  }
}

export async function startTimer(sessionId: string | undefined) {
  if (!sessionId) return;
  try {
    await fetch(`${baseUrl}/startTimer`, {
      body: JSON.stringify({
        sessionId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  } catch (error) {
    console.log(error);
  }
}

function negotiate(userId: string | undefined, videoId: string) {
  if (!userId) return;

  return pc
    .createOffer()
    .then(function (offer) {
      return pc.setLocalDescription(offer);
    })
    .then(function () {
      // wait for ICE gathering to complete
      return new Promise<void>(function (resolve) {
        if (pc.iceGatheringState === 'complete') {
          resolve();
        } else {
          function checkState() {
            if (pc.iceGatheringState === 'complete') {
              pc.removeEventListener('icegatheringstatechange', checkState);
              resolve();
            }
          }
          pc.addEventListener('icegatheringstatechange', checkState);
        }
      });
    })
    .then(function () {
      const offer = pc.localDescription;

      return fetch(`${baseUrl}/connectViewer`, {
        body: JSON.stringify({
          sdp: offer?.sdp,
          type: offer?.type,
          videoId,
          sessionId: userId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
    })
    .then(function (response) {
      return response.json();
    })
    .then(function (answer) {
      return pc.setRemoteDescription(answer);
    })
    .catch(function (e) {
      console.log(e);
    });
}

export function start(
  userId: string | undefined,
  onConnected: () => void,
  videoId: string,
) {
  if (!userId) return;

  pc = createPeerConnection(onConnected);

  // send dummy track to initiate connection
  const black = ({ width = 640, height = 480 } = {}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const canvas: any = Object.assign(document.createElement('canvas'), {
      width,
      height,
    });
    canvas.getContext('2d')?.fillRect(0, 0, width, height);
    const stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], {
      enabled: false,
    });
  };
  pc.addTrack(black());
  negotiate(userId, videoId);
}

export function stop() {
  // close transceivers
  if (pc.getTransceivers) {
    pc.getTransceivers().forEach(function (transceiver) {
      if (transceiver.stop) {
        transceiver.stop();
      }
    });
  }

  // close local audio / video
  pc.getSenders().forEach(function (sender) {
    sender.track?.stop();
  });

  // close peer connection
  setTimeout(function () {
    pc.close();
  }, 500);
}

import * as React from 'react';

import { Button, Image } from 'theme-ui';
import { useUser } from '@youga/youga-client-api';
import Modal from 'react-modal';

import useTracking from '../../../hooks/useTracking';
import ChallengePassedIcon from '../../../assets/icons/iconCalendarCheck.svg';

import BadgeScreen from '../../03_organisms/BadgeScreen/BadgeScreen';
import JourneyProgressPage from '../../03_organisms/JourneyProgress/JourneyProgressPage';

import './ChallangeInfoBox.css';
import { useTranslation } from 'react-i18next';

const customStyles = {
  content: {
    zIndex: 999999,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    border: '0',
    boxShadow: '0 30px 30px rgba(0,0,0, 0.1)',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
if (typeof document !== `undefined`) {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
}

interface Props {
  type: 'final' | 'day' | 'btnOnly';
  finished?: boolean;
}

function ChallengeInfoBox({ type, finished }: Props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const { data: user, updatePreferences } = useUser();
  const { track } = useTracking();

  const resetChallenge = async (): Promise<void> => {
    try {
      await updatePreferences({
        ...user?.preferences,
        challenge: {
          challengeStartDate: null,
          challengeEndDate: null,
          challengeProgress: [],
        },
      });
      track('journey-challenge-reset');
    } catch (e) {
      track('journey-challenge-reset-error');
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const viewSuccessBtn = (
    <div
      style={{
        borderRadius: '100px',
        color: 'black',
        fontWeight: 'bold',
      }}
    >
      <Button
        onClick={() => openModal()}
        variant="primarySmall"
        sx={{ mt: 6 }}
        style={{
          borderRadius: 100,
          color: 'black',
          fontWeight: 'bold',
          fontSize: '14px',
          letterSpacing: '0.44px',
          textTransform: 'uppercase',
          width: '200px ',
          fontFamily: 'Montserrat',
          marginTop: type == 'btnOnly' ? '135px' : type == 'final' ? '70px' : 0,
        }}
      >
        {t('YOUR_PROGRESS')}
      </Button>
    </div>
  );

  const restartChallengeBtn = (
    <div
      style={{
        borderRadius: '100px',
        color: 'black',
        fontWeight: 'bold',
      }}
    >
      <Button
        onClick={async () => {
          await resetChallenge();
        }}
        variant="default"
        sx={{ mt: 6 }}
        style={{
          borderRadius: 100,
          color: 'black',
          fontWeight: 'bold',
          fontSize: '14px',
          letterSpacing: '0.44px',
          textTransform: 'uppercase',
          width: '250px ',
          fontFamily: 'Montserrat',
          marginTop: '5px',
          height: '48px',
          marginLeft: '150px',
        }}
      >
        {t('RESTART_CHALLENGE')}
      </Button>
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          className={'challangeModalContentWrap'}
          style={{ padding: '0 110px' }}
        >
          <div className={'challangeModalCloseBtn'} onClick={closeModal} />
          <JourneyProgressPage courseId={'30_days_challenge'} />
          <div>
            <Button variant="primary" onClick={() => setIsOpen(false)}>
              {t('BACK')}
            </Button>
          </div>
        </div>
      </Modal>

      <div
        style={{
          position: 'relative',
          textAlign: 'left',
          alignItems: 'center',
          paddingTop: '0',
          marginLeft: type === 'final' && !finished ? '45px' : '150px',
          width: type === 'final' && !finished ? '350px' : '250px',
          height: type === 'final' && !finished ? '250px' : 0,
        }}
      >
        {type === 'day' ? (
          <>
            <div style={{ marginBottom: '85px' }}>
              <Image src={ChallengePassedIcon} style={{ height: '30px' }} />
              <h4 style={{ lineHeight: '0px' }}>{t('ACHIEVED_DAILY_GOAL')}</h4>
              <p>{t('SESSION_FINISHED')}</p>
            </div>
            {viewSuccessBtn}
          </>
        ) : type === 'final' ? (
          finished ? (
            <>
              <div style={{ marginBottom: '60px' }}>
                <BadgeScreen
                  courseId={'30_days_challenge'}
                  onPress={() => {}}
                />
              </div>
              {viewSuccessBtn}
            </>
          ) : (
            <>
              <div style={{ marginBottom: '85px' }}>
                <p style={{ fontSize: '24px' }}>
                  {t('THIRTY_DAYS_CHALLANGE_UNFINISHED')}
                </p>
              </div>
              {restartChallengeBtn}
            </>
          )
        ) : (
          viewSuccessBtn
        )}
      </div>
    </div>
  );
}

export default ChallengeInfoBox;

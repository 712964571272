import * as React from 'react';
import type { RouteComponentProps } from '@reach/router';
import { z } from 'zod';
import { Box } from '@theme-ui/components';

import type { StreamingSession } from '@youga/youga-interfaces';

import { useYougaClientApi, useConnect } from '@youga/youga-client-api';

import createUuid from '../../../utils/createUuid';
import Spinner from '../../01_atoms/Spinner/Spinner';

import TrainingSessionSection from './TrainingSessionSection';
import useTracking from '../../../hooks/useTracking';

const LocationStateSchema = z.object({
  streamingSession: z
    .object({
      id: z.string(),
      apiKey: z.string(),
      codeDataUrl: z.string(),
      sessionID: z.string(),
      token: z.string(),
      streamingCode: z.string(),
    })
    .passthrough(),
  trainingSessionId: z.string(),
  videoId: z.string(),
});

export interface VideoIdProps {
  videoId: string;
  passive?: boolean;
}

export type ConnectIdProps = {
  connectId: string;
  streamingSession?: StreamingSession;
  trainingSessionId?: string;
  videoId?: string;
  passive?: boolean;
};

export type TrainingSessionSectionPageProps = RouteComponentProps<
  VideoIdProps & ConnectIdProps
>;

function TrainingSessionSectionWithConnectId({
  connectId,
  passive,
}: ConnectIdProps) {
  const [trainingSessionId, setTrainingSessionId] = React.useState<string>();
  const [videoId, setVideoId] = React.useState<string>();
  const [streamingSession, setStreamingSession] =
    React.useState<StreamingSession>();
  const { data: connectData } = useConnect(connectId);

  React.useEffect(() => {
    if (connectData == null) {
      return;
    }

    const connectVideo = connectData.actions?.find((action) => {
      return action.type === 'connect-video';
    });

    if (connectVideo?.type !== 'connect-video') {
      return;
    }

    const { streamingSession, videoId } = connectVideo;

    if (!streamingSession || !videoId) {
      // FIXME: (pwo) throw an error and show it to the user
      return;
    }

    // TODO: (pwo) clear actions array like in `useConnect()` ?

    setStreamingSession(streamingSession);
    setVideoId(videoId);
    setTrainingSessionId(connectVideo.trainingSessionId);
  }, [connectData, streamingSession, trainingSessionId, videoId]);

  if (
    trainingSessionId == null ||
    videoId == null ||
    streamingSession == null
  ) {
    return null;
  }

  return (
    <TrainingSessionSection
      passive={passive}
      videoId={videoId}
      streamingSession={streamingSession}
      trainingSessionId={trainingSessionId}
    />
  );
}

function TrainingSessionSectionWithVideoId({ videoId, passive }: VideoIdProps) {
  const { api, userId, token } = useYougaClientApi();
  const { setTransactionId, transactionId } = useTracking();
  const [trainingSessionId] = React.useState<string>(createUuid());
  const [streamingSession, setStreamingSession] =
    React.useState<StreamingSession>();

  React.useEffect(() => {
    setTransactionId(trainingSessionId);
  }, [trainingSessionId, setTransactionId, transactionId]);

  /**
   * [connectId]
   * Creates a new StreamingSession if we do not already have a ConnectId
   *
   * (This is the case when we directly hit a training-session on web)
   */
  React.useEffect(() => {
    async function createStreamingSessionWithUserId() {
      const session = await api.createStreamingSession(userId);
      setStreamingSession(session);

      if (token == null) {
        throw new Error(`Token not defined`);
      }

      await api.postTrainingSessionNotify(token, trainingSessionId, 'desktop');
    }

    createStreamingSessionWithUserId();
  }, [token, userId, api, trainingSessionId, setTransactionId, transactionId]);

  if (streamingSession == null) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <TrainingSessionSection
      videoId={videoId}
      trainingSessionId={trainingSessionId}
      streamingSession={streamingSession}
      passive={passive}
    />
  );
}

function TrainingSessionSectionPage(props: TrainingSessionSectionPageProps) {
  const connectLocationState = React.useMemo(
    () => LocationStateSchema.safeParse(props.location?.state),
    [props.location],
  );

  if (connectLocationState.success) {
    const { trainingSessionId, videoId, streamingSession } =
      connectLocationState.data;

    return (
      <TrainingSessionSection
        trainingSessionId={trainingSessionId}
        videoId={videoId}
        streamingSession={streamingSession}
      />
    );
  }

  if (props.videoId) {
    return (
      <TrainingSessionSectionWithVideoId
        videoId={props.videoId}
        passive={props.passive}
      />
    );
  }

  if (props.connectId) {
    return (
      <TrainingSessionSectionWithConnectId
        connectId={props.connectId}
        passive={props.passive}
      />
    );
  }

  throw new Error('Missing one of the params: "videoId", "connectId".');
}

export default TrainingSessionSectionPage;

import { useTranslation } from 'react-i18next';

import { Box, Image } from 'theme-ui';
import React, { useEffect, useState } from 'react';

import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Container from '../../01_atoms/Container/Container';
import IosGateIllustration from '../../../assets/iosGateIllustration.svg';
import LoadingIllustration from '../../../assets/illustrationLoading.svg';
import AndroidPromoLink from '../../../components/01_atoms/AndroidPromoLink';

const MobileGate: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    // 1vh is by default the whole viewport, however sometimes the viewport is overlapped by browser-bars.
    // for this case, we need to know the visible height when loading the page, to keep everything really full-screen
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    setShowComponent(true);
  }, []);

  if (typeof window === 'undefined' || !showComponent) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 99,
          width: '100%',
          height: '140px',
          backgroundColor: 'white',
        }}
      />
      <AndroidPromoLink />
      <Header showButton={null} theme="light" />
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 101,
        }}
      >
        <Image
          src={IosGateIllustration}
          sx={{
            height: '140px',
            width: '244px',
          }}
        />
      </Box>

      <Box
        sx={{
          position: 'relative',
          pt: '68px',
          pb: `${(173 / 376) * 100}%`,
          minHeight: 'calc((var(--vh, 1vh) * 100) - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc((var(--vh, 1vh) * 100) - 5rem)',
          },
        }}
      >
        <Container sx={{ pt: 5, textAlign: 'center' }}>
          <Text
            variant="h2"
            highlighted
            sx={{ mt: 0, mb: 3, textAlign: 'center' }}
          >
            {t('HOL_DIR_DIE_APP')}
          </Text>

          <Text sx={{ textAlign: 'center', letterSpacing: 0 }}>
            {t('UM_DIR_YOGA_IN_BESTER_QUALIT_T')}
          </Text>
        </Container>

        <Image
          src={LoadingIllustration}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }}
        />
      </Box>
    </>
  );
};

export default MobileGate;

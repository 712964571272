import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './resources/de';
import pl from './resources/pl';
import eng from './resources/eng';

export const resources = {
  de: {
    translation: de,
  },
  pl: {
    translation: pl,
  },
  eng: {
    translation: eng,
  },
};

const userLanguage = typeof window !== 'undefined' ? navigator.language : '';

export type TranslationFnType = (name: string) => string;

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  lng: userLanguage,
  resources,
  fallbackLng: 'eng',
});

export default i18n;

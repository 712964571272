import { useCourses, useUser } from '@youga/youga-client-api';

import MhmJourneyChallengeSection from '../MhmJourneyChallangeSection/MhmJourneyChallangeSection';
import MhmJourneySection from '../MhmJourneySection/MhmJourneySection';
import i18n from '../../../i18n/i18n';

function YourJourney() {
  const { data: coursesData } = useCourses(i18n.language);
  const { data: user } = useUser();

  const userGoal = user?.preferences?.goal;

  const is30DayChallenge = userGoal === 'be_young';

  if (is30DayChallenge) {
    return <MhmJourneyChallengeSection />;
  }

  const userCourse =
    userGoal != null && userGoal !== 'health_care'
      ? coursesData?.courses[userGoal]
      : null;

  if (userCourse != null) {
    return <MhmJourneySection course={userCourse} />;
  }

  return null;
}

export default YourJourney;

import { TrainingSession } from '@youga/youga-interfaces';

import { DATA } from '../data';
import moment from 'moment';

export const sessionCompleteNumber = 80;

export const isCompleted = (progress?: unknown): boolean => {
  if (typeof progress === 'number') {
    return progress >= sessionCompleteNumber;
  }
  return false;
};

export const hasDetailPagePoseTips = (
  courseName?: string,
  sessionName?: string,
): boolean => {
  if (courseName == null || sessionName == null) {
    return false;
  }

  return DATA[courseName]?.[sessionName] != null;
};

export const createFinderForTheFirstVideoOfThe30DayChallenge = (
  sessionNameOfFirstVideo: string,
) => {
  return (session: TrainingSession) => {
    if (session.videoSessionId !== sessionNameOfFirstVideo) {
      return false;
    }
    return isCompleted(session.completion);
  };
};

export const getDaysSinceChallengeStartDate = (
  userChallengeStartDate: string | null | undefined,
): number => {
  const today = moment(new Date(), 'YYYY-MM-DD');
  const challengeStartDay = moment(userChallengeStartDate, 'YYYY-MM-DD');

  if (!Number.isNaN(today.diff(challengeStartDay, 'days'))) {
    const dayDifference: number = today.diff(challengeStartDay, 'days');
    if (dayDifference >= 30) {
      return 31;
    } else {
      return dayDifference;
    }
  }

  return 0;
};

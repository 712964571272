import type { LabelName, UserPreferences } from '@youga/youga-interfaces';

type NonNullable<T> = Exclude<T, null | undefined>;

export type GoalType = UserPreferences['goal'];

export interface GoalMeta {
  id: NonNullable<GoalType>;
}

export const GoalsMeta: Record<
  NonNullable<UserPreferences['goal']>,
  GoalMeta
> = {
  imprive_condition: {
    id: 'imprive_condition',
  },

  get_rid_of_tensions: {
    id: 'get_rid_of_tensions',
  },

  twine: {
    id: 'twine',
  },

  get_rid_of_stress: {
    id: 'get_rid_of_stress',
  },
  improve_mobilitiy: {
    id: 'improve_mobilitiy',
  },
  health_care: {
    id: 'health_care',
  },

  relax: {
    id: 'relax',
  },

  gear_up: {
    id: 'gear_up',
  },

  be_young: { id: 'be_young' },
};

export function getGoalMeta(id: string): GoalMeta | null {
  switch (id) {
    case GoalsMeta['imprive_condition'].id:
      return GoalsMeta['imprive_condition'];
    case GoalsMeta.twine.id:
      return GoalsMeta.twine;
    case GoalsMeta.imprive_condition.id:
      return GoalsMeta.imprive_condition;
    case GoalsMeta.relax.id:
      return GoalsMeta.relax;
    default:
      return null;
  }
}

export type FlagType = 'POPULAR' | 'NEW';

export const flags: Record<FlagType, LabelName> = {
  POPULAR: 'POPULAR',
  NEW: 'NEW',
};

import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';

function ImprintPage() {
  const { t } = useTranslation();

  return (
    <DefaultPageLayout title="Impressum">
      <h4>{t('ANBIETER')}</h4>

      <p>
        {t('INITIUM_GMBH')}

        <br />
        {t('STRALAUER_ALLEE')}

        <br />
        {t('BERLIN')}

        <br />
        {t('DEUTSCHLAND')}

        <br />
      </p>

      <h4>{t('KONTAKT')}</h4>

      <p>{t('TELEFONNUMMER')}</p>

      <p>
        {t('E_MAIL')} <a href={`mailto:${t('INFO_MAIL')}`}>{t('INFO_MAIL')}</a>
      </p>

      <h4>{t('VERTRETEN_DURCH')}</h4>

      <p>{t('MATTIAS_ULBRICH_STEFAN_ZERWEC')}</p>

      <h4>{t('REGISTRIEREINTRAG')}</h4>

      <p>
        {t('REGISTERGERICHT_AMTSGERICHT_C')}

        <br />
        {t('HRB_NR_B')}

        <br />
        {t('UST_IDENT_NR_DE')}

        <br />
      </p>

      <h4>{t('VERBRAUCHERINFORMATION_GEM_SS')}</h4>

      <p>{t('DIE_INITIUM_GMBH_IST_NICHT_BER')}</p>
    </DefaultPageLayout>
  );
}

export default ImprintPage;

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../01_atoms/Button/Button';
import Checkbox from '../../01_atoms/Checkbox/Checkbox';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import TextInput from '../../01_atoms/TextInput/TextInput';

type BusinessSignInFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  clientCheck: (client: string) => Promise<boolean>;
  isLoading: boolean;
};

function debounce<T extends any[]>(fn: (...args: T) => void, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

export default function BusinessSignInForm({
  errorInformation,
  clientCheck,
  setEmail,
  setPassword,
  isLoading,
}: BusinessSignInFormProps) {
  const { t } = useTranslation();

  const [hasCode, setHasCode] = useState(false);

  const setClientDebounced = useRef(
    debounce(async (value: string) => {
      await clientCheck(value);
    }, 600),
  ).current;

  return (
    <React.Fragment key="signup-submit-fragment">
      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
      />
      <Checkbox
        id="newsletter"
        name="newsletter"
        checked={hasCode}
        onChange={(e): void => {
          setHasCode(!hasCode);
        }}
      >
        {t('have_voucher')}
      </Checkbox>
      {hasCode && (
        <TextInput
          id="code"
          name="code"
          onChange={(e) => setClientDebounced(e.target.value)}
          placeholder={t('voucher_placeholder')}
        />
      )}
      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder={t('PASSWORD')}
        required
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
      />
      <Button
        type="submit"
        style={{ cursor: 'pointer', display: 'block', marginTop: '3rem' }}
        disabled={isLoading}
      >
        {t('EINLOGGEN')}
      </Button>
    </React.Fragment>
  );
}

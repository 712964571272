import { useTranslation } from 'react-i18next';

import React from 'react';
import { ThemeUIStyleObject, Box, Image } from 'theme-ui';

import type { TrainingSessionImage } from '../../../types/interfaces';

import Text from '../../01_atoms/Text/Text';
import IconImageBurst from '../../../assets/icons/iconImageBurst.svg';
import IconImageBurstNext from '../../../assets/icons/iconImageBurstNext.svg';
import IconImageBurstPrev from '../../../assets/icons/iconImageBurstPrev.svg';
import PoseComparisonActionButton from '../PoseComparisonActionButton/PoseComparisonActionButton';

export interface BurstImageSwitcherProps {
  imageCount: number;
  editMode: boolean;
  burstPhotoIndex: number;
  onPrevClick: () => void;
  onNextClick: () => void;
  onDeleteClick: (
    imageName: string,
    beforeCloseCallback?: () => Promise<void>,
  ) => void;
  image: TrainingSessionImage;
  deletable: boolean;

  sx?: ThemeUIStyleObject;
}

const BurstImageSwitcher: React.FC<BurstImageSwitcherProps> = ({
  imageCount,
  editMode,
  onPrevClick,
  burstPhotoIndex,
  onNextClick,
  onDeleteClick,
  image,
  deletable,
  sx,
}: BurstImageSwitcherProps) => {
  const { t } = useTranslation();

  if (imageCount <= 1) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'absolute',
        width: '100%',
        height: '100%',
        bottom: 0,
        ...sx,
      }}
    >
      {/* Arrow on the left side */}
      <Image
        src={IconImageBurstPrev}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '1rem',
          transform: 'translateY(-50%)',
        }}
        style={{
          transition: editMode
            ? 'opacity 0.2s ease-in-out 0.4s'
            : 'opacity 0.2s ease-in-out',
          opacity: editMode ? 1 : 0,
        }}
      />

      {/* Arrow on the right side */}
      <Image
        src={IconImageBurstNext}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '1rem',
          transform: 'translateY(-50%)',
          transition: 'opacity 0.2s ease-in-out 0.4s',
        }}
        style={{
          transition: editMode
            ? 'opacity 0.2s ease-in-out 0.4s'
            : 'opacity 0.2s ease-in-out',
          opacity: editMode ? 1 : 0,
        }}
      />

      {/* Box around the left arrow to go back a picture */}
      {editMode && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '1rem',
            width: `calc(32px + 16px)`,
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={onPrevClick}
        />
      )}

      {/* Box around the right arrow to choose the next */}
      {editMode && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: '1rem',
            width: `calc(32px + 16px)`,
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={onNextClick}
        />
      )}

      {/* Box for picture count */}
      {editMode && (
        <Box
          style={{
            display: 'flex',
            bottom: '1rem',
            left: '1rem',
            position: 'absolute',
            zIndex: 1,
          }}
        >
          <Image src={IconImageBurst} style={{}} />

          <Text
            sx={{
              fontFamily: 'heading',
              fontSize: '12px',
              color: 'white',
              ml: 2,
              textShadow: '2px 2px 10px rgba(0, 0, 0, 0.75)',
            }}
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              marginLeft: '0,5rem',
            }}
          >
            {t('SERIE')}
            {burstPhotoIndex + 1}/{imageCount} {t('BILDER1')}
          </Text>
        </Box>
      )}

      {/* Delete picture button */}
      {editMode && (
        <PoseComparisonActionButton
          visibleState={editMode && deletable ? 'delete' : null}
          onDeleteClick={(e): void => {
            e.preventDefault();

            if (!deletable) {
              return;
            }

            onDeleteClick(image.imageName);
          }}
        />
      )}
    </Box>
  );
};

export default BurstImageSwitcher;

/** @jsxImportSource theme-ui */
import React from 'react';

import { Box } from 'theme-ui';
import isTouchDevice from '../../../utils/isTouchDevice';
import BtnBackward from '../../../assets/btnBackward.inline.svg';
import BtnForward from '../../../assets/btnForward.inline.svg';

interface SwipeControlProps {
  hideControlShadow: boolean;
  hideControlsOnTouchDevices: boolean;
  direction: 'left' | 'right';
  btnTopDistance: string;
  visible: boolean;
  onClick: () => void;
  className?: string;
}

const SwipeControl: React.FC<SwipeControlProps> = ({
  direction,
  btnTopDistance,
  visible,
  onClick,
  hideControlShadow,
  hideControlsOnTouchDevices,
  className,
}: SwipeControlProps) => {
  let backgroundGradient =
    direction === 'left'
      ? 'linear-gradient(-90deg, rgb(255, 255, 255, 0) 0%, rgba(255, 255, 255) 100%)'
      : 'linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)';

  if (hideControlShadow) {
    backgroundGradient = '';
  }

  const anchoredProperty = direction === 'left' ? 'left' : 'right';
  const BtnImage = direction === 'left' ? BtnBackward : BtnForward;

  if (hideControlsOnTouchDevices && isTouchDevice()) {
    return null;
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
  return (
    <div
      className={`swipe-control ${className}`}
      sx={{
        background: backgroundGradient,
        position: 'absolute',
        top: 0,
        [anchoredProperty]: 0,
        bottom: 0,
        width: '77px',
        opacity: 0,
        cursor: 'pointer',
        transition: 'opacity ease-in 0.15s',
      }}
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'all' : 'none',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          position: 'absolute',
          top: btnTopDistance,
          [anchoredProperty]: 2,
          transform: 'translateY(-50%)',
        }}
      >
        <BtnImage />
      </Box>
    </div>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
};

export default SwipeControl;

import React from 'react';
import { Box, Image } from 'theme-ui';
import Text from '../Text/Text';

export interface Marker {
  start: number;
  length: number;
  label: string;
  thumbnailUrl: string;
}

export interface MarkerOverlayProps {
  mark: Marker;
  visible: boolean;
}

const MarkerOverlay: React.FC<MarkerOverlayProps> = ({
  mark,
  visible,
}: MarkerOverlayProps) => (
  <Box
    sx={{
      position: 'absolute',
      top: '-10px',
      left: '50%',
      transform: 'translate(-50%, -100%)',
      width: '200px',
      backgroundColor: 'black',
      borderRadius: '2px',
      p: '4px',
      transition: 'opacity ease-in 0.1s',
    }}
    style={{
      opacity: visible ? 1 : 0,
      pointerEvents: visible ? 'all' : 'none',
    }}
  >
    {mark.thumbnailUrl && <Image src={mark.thumbnailUrl} />}
    <Text
      variant="default"
      clampLines={1}
      sx={{
        fontSize: 0,
        mt: 1,
        px: '2px',
        color: 'white',
      }}
    >
      {mark.label}
    </Text>
  </Box>
);

export default MarkerOverlay;

/** @jsxImportSource theme-ui */

import { Link } from 'gatsby';
import React from 'react';
import { Box, Image } from 'theme-ui';
import BtnPlay from '../../../assets/btnPlay.inline.svg';
import Text from '../Text/Text';
import ViewMoreText from '../ViewMoreText/ViewMoreText';

interface VideoItemProps {
  imageSource: string;
  duration: string;
  target?: string;
  onPressPlay?: () => void;
  title: string;
  description?: string;
  aspectRatio?: number;
}

const VideoItem: React.FC<VideoItemProps> = ({
  imageSource,
  duration,
  target,
  onPressPlay,
  title,
  description,
  aspectRatio = 180 / 320,
}) => (
  <Box>
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box sx={{ paddingTop: `${aspectRatio * 100}%` }} />

      <Image
        src={imageSource}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          objectFit: 'cover',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '3.5rem',
          background:
            'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)',
          width: '100%',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          bottom: '4px',
          left: '0px',
          transformOrigin: 'bottom left',
          lineHeight: 1,
          width: '40px',
          height: '40px',
        }}
      >
        <BtnPlay sx={{ width: '40px', height: '40px' }} />
      </Box>

      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: '14px',
          color: 'white',
          position: 'absolute',
          bottom: '10px',
          left: '2.75rem',
          lineHeight: '24px',
          fontFamily: 'heading',
        }}
      >
        {title}
      </Text>

      <Text
        sx={{
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'white',
          position: 'absolute',
          right: '8px',
          bottom: '10px',
          lineHeight: '24px',
        }}
      >
        {duration}
      </Text>

      {target && (
        <Link
          to={target}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
      )}

      {onPressPlay && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: 'pointer',
          }}
          onClick={onPressPlay}
        />
      )}
    </Box>
    {description && (
      <Box sx={{ mt: 3 }}>
        <ViewMoreText
          separator="[view-more]"
          sx={{ fontSize: '14px', color: 'baseBlack' }}
        >
          {description}
        </ViewMoreText>
      </Box>
    )}
  </Box>
);

export default VideoItem;

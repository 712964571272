import React from 'react';
import { Image } from 'theme-ui';

import IconPlay from '../../../assets/icons/iconPlay.svg';
import SuccessIcon from '../../../assets/icons/button_circle_complete.svg';

interface ThumbnailVideoItemProps {
  imageSource?: string;
  playButtonPosition: 'hidden' | 'center' | 'title';
  duration?: string;
  progress?: number;
  finished: boolean;
}

const ThumbnailVideoItem: React.FC<ThumbnailVideoItemProps> = ({
  imageSource,
  playButtonPosition,
  progress = 0,
  finished,
}: ThumbnailVideoItemProps) => {
  const videoFinished = progress > 80;
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <>
        {finished && (
          <div
            style={{
              position: 'absolute',
              width: 120,
              height: 80,
              backgroundColor: 'white',
              zIndex: 5,
              borderRadius: 10,
              opacity: 0.8,
            }}
          />
        )}

        <Image
          src={imageSource}
          style={{
            width: 120,
            height: 80,
            borderRadius: 10,
            float: 'left',
            boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)',
          }}
        />
        {playButtonPosition === 'center' && !finished && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={IconPlay}
              alt="icon"
              style={{
                color: '#000000',
                zIndex: 10,
                right: '208px',
                top: '23px',
                position: 'absolute',
              }}
            />
          </div>
        )}

        {playButtonPosition === 'center' && finished && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={SuccessIcon}
              alt="icon"
              style={{
                position: 'absolute',
                zIndex: 10,
                right: '208px',
                top: '23px',
              }}
            />
          </div>
        )}

        {!videoFinished && progress > 0 && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              height: 4,
              right: 0,
              backgroundColor: '#ffffff',
              opacity: 0.4,
              zIndex: 9,
            }}
          />
        )}
        {!videoFinished && progress > 0 && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              height: 4,
              backgroundColor: '#cfebe9',
              opacity: 1,
              zIndex: 10,
              width: `${progress}%`,
            }}
          />
        )}
      </>
    </div>
  );
};

export default ThumbnailVideoItem;

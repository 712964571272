import { isMobileOnly } from 'react-device-detect';
import { useBreakpointIndex } from '@theme-ui/match-media';

import type { PlanDetails } from '@youga/youga-interfaces';
import { useUser } from '@youga/youga-client-api';

import env from '../environment';
import useSubscriptionStatus from './useSubscriptionStatus';
import useStripe from './useStripe';

export enum GATE_INFORMATION {
  PASS = 'PASS',
  STRIPE = 'STRIPE',
  AGENT_ANDROID = 'ANDROID',
  AGENT_IOS = 'IOS',
  MOBILE = 'MOBILE',
  LOADING = 'LOADING',
  LICENSE = 'LICENSE',
  ACCOUNT_INVALID = 'ACCOUNT_INVALID',
}

type UseGateInformation = () => GATE_INFORMATION;

const isCurrentPlanValid = (plan: PlanDetails | undefined): boolean => {
  if (!plan) {
    return false;
  }

  const now = new Date().getTime();
  const isStillValid = plan.expirationDate + env.GATSBY_GRACE_PERIOD > now;
  const isInGracePeriod = isStillValid && now > plan.expirationDate;

  console.log(
    `Current validity is ${isStillValid ? 'valid' : 'invalid'}. Grace period: ${
      isInGracePeriod ? 'yes' : 'no'
    }`,
  );

  return isStillValid;
};

const isBrowser = typeof window !== 'undefined';

/**
 * @see {@link https://theme-ui.com/packages/match-media/}
 */
const serverSideRenderedDefaultIndex = 3;

const useGateInformation: UseGateInformation = () => {
  const breakpointIndex = useBreakpointIndex({
    defaultIndex: serverSideRenderedDefaultIndex,
  });
  const { data: subscriptionStatus, hasFullAppAccess } =
    useSubscriptionStatus();

    console.log({ })
  const {
    data: user,
    isValidating: isValidatingUser,
    error: userError,
  } = useUser();


  if (!hasFullAppAccess) {
    return GATE_INFORMATION.LICENSE;
  }

  if (!isBrowser) {
    return GATE_INFORMATION.LOADING;
  }

  if (isMobileOnly) {
    return GATE_INFORMATION.MOBILE;
  }

  // If we do not know about the user, just return a loading state so we can display a spinner
  if (!hasFullAppAccess && user == null && isValidatingUser) {
    return GATE_INFORMATION.LOADING;
  }

  if (subscriptionStatus == null) {
    return GATE_INFORMATION.LOADING;
  }

  if (userError?.response?.status === 404) {
    return GATE_INFORMATION.ACCOUNT_INVALID;
  }

  const hasOldPlan = user?.plan?.current != null;

  if (
    !hasFullAppAccess &&
    hasOldPlan &&
    isCurrentPlanValid(user?.plan?.current)
  ) {
    return GATE_INFORMATION.PASS;
  }

  if (!hasFullAppAccess) {
    return GATE_INFORMATION.LICENSE;
  }

  return GATE_INFORMATION.PASS;
};

export default useGateInformation;

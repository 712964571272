import { useTranslation } from 'react-i18next';

import * as React from 'react';
import styled from 'styled-components';

import { Image } from 'theme-ui';
import { Link } from 'gatsby';
import { useUser } from '@youga/youga-client-api';
import ProgressBar from '@ramonak/react-progress-bar';
import type { TrainingSession } from '@youga/youga-interfaces';

import { CourseSession } from '../../../types/interfaces';
import CalendarIcon from '../../../assets/icons/Calendar Icon@1x.svg';
import ChallengeInfoBox from '../../01_atoms/ChallangeInfoBox/ChallangeInfoBox';
import JourneyCourseItem from './JourneyCourseItem';
import Text from '../../01_atoms/Text/Text';

import './JourneyStyle.css';

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;

  &:hover h6 {
    text-decoration: underline;
  }
`;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bgImage: any;
  teaserText?: string;
  progressText?: string;
  videos?: CourseSession[];
  allSessionsFinished?: boolean;
  dailyFinishedSession?: TrainingSession;
  finishedSessions?: number;
  daysSinceChallengeStart: number;
}

function JourneyChallengeSection(props: React.PropsWithChildren<Props>) {
  const { t } = useTranslation();

  const {
    bgImage,
    teaserText,
    videos,
    finishedSessions,
    daysSinceChallengeStart,
    dailyFinishedSession,
  } = props;

  const bgImageUrl = bgImage?.default || bgImage;
  const finishedCount = finishedSessions || 0;

  const { data: user, updatePreferences } = useUser();

  const hideJourney = async (): Promise<void> => {
    try {
      await updatePreferences({
        ...user?.preferences,
        hideJourney: true,
      });
    } catch (e) {}
  };

  let key = 0;

  return (
    <div>
      <div>
        <Text
          variant="h2light"
          sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}
        >
          {t('HALLO')}

          {user?.preferences?.name}
        </Text>
      </div>
      <div
        className={'journeyImageChallengeWrap'}
        style={{
          backgroundImage: `url("${bgImageUrl}")`,
        }}
      >
        <div className={'progressBarWrap'}>
          <ProgressBar
            labelColor="black"
            baseBgColor="#ffffff"
            bgColor="#a4d9d6"
            barContainerClassName="progressBarContainer"
            className="progressBarChallenge"
            labelClassName="progressLabel"
            completed={(100 / 30) * finishedCount}
            labelAlignment="center"
            customLabel={finishedCount + '/30'}
          />
          <Image className={'progressChallengeImageIcon'} src={CalendarIcon} />
          <h3
            style={{
              marginLeft: '30px',
            }}
          >
            {t('NOCH')}{' '}
            {30 - daysSinceChallengeStart <= 0
              ? 0
              : 30 - daysSinceChallengeStart}{' '}
            {t('TAGE')}
          </h3>
        </div>
      </div>

      {teaserText ? (
        <Text
          style={{
            display: 'inline-block',
            width: '300px',
            fontSize: '32px',
            margin: 0,
            marginBottom: '60px',
            fontWeight: 'bold',
            marginLeft: '20px',
          }}
        >
          {30 - daysSinceChallengeStart <= 0 && (
            <div className={'hideJourneyBtn'} onClick={() => hideJourney()} />
          )}
          {teaserText}
        </Text>
      ) : null}

      <div className={'journeyVideoWrap journeyVideoWrapChallenge'}>
        {!!videos &&
          videos.map((item: CourseSession) => {
            key++;
            return (
              <div className={'journeyCourseItemWrapper'} key={key}>
                {item.id === '30+' ? (
                  <ChallengeInfoBox
                    type="final"
                    finished={finishedCount >= 30}
                  />
                ) : dailyFinishedSession ? (
                  <ChallengeInfoBox type="day" />
                ) : item.id !== '0' && item.videoSession != null ? (
                  <>
                    <StyledLink to={`/app/session/${item.videoSession?.id}`}>
                      <JourneyCourseItem
                        topline={`${t('TAG')} ${item.id}`}
                        course={item.videoSession}
                        finished={item.finished}
                      />
                    </StyledLink>
                  </>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default JourneyChallengeSection;

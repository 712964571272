import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from '@reach/router';
import CameraSection from '../components/03_organisms/CameraSection/CameraSection';
import '../i18n/i18n';
const CameraPage = (): React.ReactElement => (
  <Router basepath="/camera">
    <CameraSection path="/:streamId" />
  </Router>
);

export default CameraPage;

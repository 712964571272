/** @jsxImportSource theme-ui */

import React, { ChangeEvent } from 'react';
import { ThemeUIStyleObject, Label } from 'theme-ui';

interface Props {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  className?: string;
  sx?: ThemeUIStyleObject;
}

const Checkbox = ({
  id,
  name,
  checked,
  onChange,
  children,
  className,
  sx,
}: Props): React.ReactElement => (
  <Label
    sx={{
      display: 'block',
      position: 'relative',
      paddingLeft: 5,
      marginBottom: 3,
      ...sx,
    }}
    htmlFor={id}
    className={className}
  >
    <input
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      sx={{ position: 'absolute', left: 0 }}
    />
    {children}
  </Label>
);

export default Checkbox;

import React from 'react';
import { Box, Grid, ThemeUIStyleObject } from 'theme-ui';

import Text from '../../01_atoms/Text/Text';
import LevelIcon from '../../01_atoms/LevelIcon/LevelIcon';

type ValueData =
  | { title: string; type: 'level'; value: number }
  | { title: string; type: 'number'; value: number }
  | { title: string; type: 'string'; value: string };

interface LevelPropsProps {
  data: ValueData[];
  sx?: ThemeUIStyleObject;
}

const LevelProps: React.FC<LevelPropsProps> = ({
  data,
  sx,
}: LevelPropsProps) => (
  <Grid
    columns={data.length}
    gap="1rem"
    sx={{
      px: 4,
      margin: 'auto 0 auto 0',
      '.col': {
        position: 'relative',
        px: 2,
      },
      '.col:after': {
        content: "''",
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: '-0.5rem',
        borderRight: '1px solid black',
      },
      [`> .col:nth-of-type(${data.length}n):after`]: {
        display: 'none',
      },
      ...sx,
    }}
  >
    {data.map((dataset) => (
      <Text
        key={dataset.title}
        variant="h6"
        sx={{
          mt: 0,
          fontWeight: 'bold',
          textAlign: 'center',
          color: 'black',
          px: 2,
        }}
      >
        {dataset.title}
      </Text>
    ))}

    {data.map((dataset) => {
      switch (dataset.type) {
        case 'level':
          return (
            <Box
              key={`${dataset.title}-level`}
              className="col"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '2.5rem',
              }}
            >
              <Text
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  lineHeight: '1rem',
                  fontWeight: 'bold',
                  pt: '0.2rem',
                  px: 2,
                }}
              >
                {dataset.value}
                <Box sx={{ mt: 1, lineHeight: '0.1rem' }}>
                  <LevelIcon increased level={dataset.value} />
                </Box>
              </Text>
            </Box>
          );

        case 'number':
          return (
            <Box
              key={`${dataset.title}-number`}
              className="col"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '2.5rem',
              }}
            >
              <Text
                sx={{
                  lineHeight: '2rem',
                  fontSize: '2rem',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  px: 2,
                }}
              >
                {dataset.value}
              </Text>
            </Box>
          );

        case 'string':
        default:
          return (
            <Box
              key={`${dataset.title}-string`}
              className="col"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '2.5rem',
              }}
            >
              {(dataset.value as string).split(',').map((value) => (
                <Text
                  key={`${dataset.title}-string-${value}`}
                  sx={{
                    lineHeight: '1.25rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    px: 2,
                  }}
                >
                  {value.trim().charAt(0).toUpperCase() + value.trim().slice(1)}
                </Text>
              ))}
            </Box>
          );
      }
    })}
  </Grid>
);

export default LevelProps;

import React, { SyntheticEvent } from 'react';
import { ThemeUIStyleObject, Box, Button } from 'theme-ui';

import BtnDeleteCircle from '../../../assets/btnDeleteCircle.inline.svg';

export interface PoseComparisonActionButtonProps {
  visibleState: null | 'delete';
  onDeleteClick: (e: SyntheticEvent) => void;
  sx?: ThemeUIStyleObject;
}

const PoseComparisonActionButton: React.FC<PoseComparisonActionButtonProps> = ({
  visibleState,
  onDeleteClick,
  sx,
}: PoseComparisonActionButtonProps) => (
  <Button
    variant="primary"
    sx={{
      maxWidth: 'none',
      zIndex: 1,
      position: 'absolute',
      right: '1rem',
      bottom: '1rem',
      py: 0,
      px: 0,
      height: '28px',
      border: '1px solid black',
      lineHeight: 1,
      overflow: 'hidden',
      transition: 'opacity 0.2s ease-in-out',
      width: '28px !important',
      backgroundColor: 'white',
      ...sx,
    }}
    style={{
      pointerEvents: visibleState === 'delete' ? 'auto' : 'none',
      opacity: visibleState === 'delete' ? 1 : 0,
    }}
    onClick={visibleState === 'delete' ? onDeleteClick : () => {}}
  >
    <Box
      sx={{
        height: '26px',
        width: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BtnDeleteCircle
        sx={{
          '.circle': { display: 'none' },
        }}
      />
    </Box>
  </Button>
);

export default PoseComparisonActionButton;

/** @jsxImportSource theme-ui */
import React from 'react';

import Button from '../../01_atoms/Button/Button';
import Text from '../../01_atoms/Text/Text';
import OverlayModal from '../../01_atoms/OverlayModal/OverlayModal';
import { Box } from '@theme-ui/components';
import IconExclamation from '../../../assets/icons/iconExclamation.inline.svg';

export interface IInformationScreen {
  allowClose?: boolean;
  title: string;
  iconColor: 'primary' | 'success' | 'error' | 'warning';
  description: string | React.ReactNode;
  buttonLabel?: string;
  onLabelClick?: () => void;
}

interface CodeInfoModalProps {
  informationScreen: IInformationScreen | null;
  setInformationScreen: (v: IInformationScreen | null) => void;
}

export default function CodeInfoModal({
  informationScreen,
  setInformationScreen,
}: CodeInfoModalProps) {
  return (
    <OverlayModal
      allowClose={informationScreen?.allowClose || false}
      portalSelector="#overlay-modal"
      visible={!!informationScreen}
      onClose={(): void => {
        setInformationScreen(null);
      }}
    >
      {(hide): React.ReactElement | null => {
        if (!informationScreen) {
          return null;
        }

        return (
          <React.Fragment>
            <Box>
              <IconExclamation
                sx={{
                  width: '3rem',
                  height: '3rem',
                  margin: '0 auto',
                  display: 'block',
                  '> *': { fill: informationScreen.iconColor },
                }}
              />
            </Box>
            <Box>
              <Text variant="h3">{informationScreen.title}</Text>
              <Text>{informationScreen.description}</Text>
            </Box>
            <Button
              sx={{ mt: 3 }}
              variant="primary"
              onClick={(): void => {
                hide(() => {
                  setInformationScreen(null);
                  typeof informationScreen.onLabelClick !== 'undefined' &&
                    informationScreen.onLabelClick();
                });
              }}
            >
              {informationScreen.buttonLabel}
            </Button>
          </React.Fragment>
        );
      }}
    </OverlayModal>
  );
}

/** @jsxImportSource theme-ui */
import React from 'react';
import { ArrowProps } from './ArrowTrainingAnimation';
import ProximityArrow from './ProximityArrow';

const ProximityArrows = ({
  arrow1X,
  arrow1Y,
  angleArrow1,
  arrow2X,
  arrow2Y,
  ankleArrow2,
}: ArrowProps) => {
  const centerX = (arrow1X + arrow2X) / 2;
  const centerY = (arrow2Y + arrow1Y) / 2;

  return (
    <>
      <ProximityArrow
        xPosition={arrow1X}
        yPosition={arrow1Y}
        ankle={angleArrow1}
      />
      <div
        sx={{
          width: '25px',
          height: '25px',
          backgroundColor: 'white',
          border: '3px solid #fc2b41',
          position: 'absolute',
          borderRadius: '50px',
          top: `${centerY - 12.5}px`,
          left: `${centerX - 12.5}px`,
        }}
      ></div>
      <ProximityArrow
        xPosition={arrow2X}
        yPosition={arrow2Y}
        ankle={ankleArrow2}
      />
    </>
  );
};

export default ProximityArrows;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import {
  OTSession,
  OTPublisher,
  OTPublisherProps,
  OTPublisherRef,
} from 'opentok-react';

import BtnSwitchPerspective from '../../../assets/icons/btnPerspectiveSwitch.inline.svg';

require('./camera.css');

const defaultPublisherProperties: OTPublisherProps['properties'] = {
  audioSource: false,
  facingMode: 'user',
};

interface PublisherCameraProps {
  apiKey: string;
  sessionId: string;
  token: string;
  sx?: ThemeUIStyleObject;
  onImageCapture?: (imageData: string) => void;
  imageCaptureInterval?: number;
}

const PublisherCamera = ({
  apiKey,
  sessionId,
  token,
  sx,
  onImageCapture,
  imageCaptureInterval,
}: PublisherCameraProps): React.ReactElement => {
  const [videoDeviceCount, setVideoDeviceCount] = useState<number | null>(null);
  const publisherRef = useRef<OTPublisherRef>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const updateDeviceCount = (): void => {
      if (
        typeof navigator !== 'undefined' &&
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices
      ) {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) =>
            devices.filter((device) => device.kind === 'videoinput'),
          )
          .then((vd) => {
            if (videoDeviceCount !== vd.length) {
              setVideoDeviceCount(vd.length);
            }
          });
      }
    };

    if (typeof window !== 'undefined') {
      timer = setInterval(updateDeviceCount, 3000);
    }

    updateDeviceCount();

    return (): void => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [videoDeviceCount]);

  const cycleVideo = useCallback((): void => {
    if (publisherRef.current && (videoDeviceCount || 0) > 1) {
      publisherRef.current.getPublisher().cycleVideo();
    }
  }, [videoDeviceCount]);

  useEffect(() => {
    let iv: NodeJS.Timeout;

    if (imageCaptureInterval) {
      iv = setInterval((): void => {
        if (!publisherRef.current) {
          return;
        }

        if (onImageCapture) {
          const imageData = (
            publisherRef.current as any
          ).state.publisher?.getImgData();
          onImageCapture(`data:image/png;base64,${imageData}`);
        }
      }, imageCaptureInterval);
    }

    return (): void => {
      if (iv) {
        clearInterval(iv);
      }
    };
  }, [imageCaptureInterval, onImageCapture]);

  return (
    <>
      <Box
        className="camera-stream-wrapper"
        sx={{
          width: '100%',
          height: '100%',
          ...sx,
        }}
      >
        {(videoDeviceCount || 0) > 0 && (
          <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
            <OTPublisher
              ref={publisherRef}
              properties={defaultPublisherProperties}
            />
          </OTSession>
        )}
      </Box>

      {(videoDeviceCount || 0) > 1 && (
        <BtnSwitchPerspective
          sx={{
            position: 'fixed',
            zIndex: 10,
            bottom: '10px',
            right: '10px',
            p: '6px',
            backgroundColor: 'white',
            borderRadius: '50%',
            height: '42px',
            width: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            path: { fill: 'black' },
          }}
          onClick={cycleVideo}
        />
      )}
    </>
  );
};

export default PublisherCamera;

import { useTranslation } from 'react-i18next';

import React, { useEffect, useCallback, useState } from 'react';
import { Flex } from 'theme-ui';
import { RouteComponentProps } from '@reach/router';

import { useUser } from '@youga/youga-client-api';

import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import Container from '../../01_atoms/Container/Container';
import Spinner from '../../01_atoms/Spinner/Spinner';
import styled from 'styled-components';

const Space = styled.div`
  height: 50px;
`;
interface CookieManagerProps extends RouteComponentProps {
  cookieName?: string;
}

type ConsentLevel = 'technically_required' | 'analytics' | 'marketing';

const CookieManager: React.FC<CookieManagerProps> = ({
  cookieName = 'cookies_accepted_categories',
}) => {
  const { t } = useTranslation();
  const { data: user, isValidating } = useUser();
  const [consentLevel, setConsentLevel] = useState<ConsentLevel>(
    'technically_required',
  );

  useEffect(() => {
    const cookieConsentData = document.cookie
      .split(';')
      .filter((item) => item.includes(cookieName))[0]
      ?.split('=')[1];
    if (cookieConsentData?.includes('marketing')) {
      setConsentLevel('marketing');
      return;
    }
    if (cookieConsentData?.includes('analytics')) {
      setConsentLevel('analytics');
      return;
    }
  }, [setConsentLevel, cookieName]);

  const handleUpdateCookie = useCallback(
    (cookieValue: ConsentLevel): void => {
      setConsentLevel(cookieValue);
      const mappedValue = {
        technically_required: 'technically_required',
        analytics: 'technically_required,analytics',
        marketing: 'technically_required,analytics,marketing',
      }[cookieValue];
      document.cookie = `${cookieName}=${mappedValue};max-age=50400000;path=/`;
    },
    [cookieName],
  );

  if (user == null && isValidating) {
    return (
      <Container>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </Container>
    );
  }

  return (
    <>
      <Header showButton="back" theme="light" />
      <Container>
        <Text
          variant="h2"
          sx={{
            fontSize: [2, 4],
            mt: [3, 7],
            mb: [7, 12],
            display: 'block',
          }}
          highlighted
        >
          {t('COOKIE_BANNER.manage_cookie')}
        </Text>
        <Text>{t('COOKIE_BANNER.body')}</Text>
        <Flex
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Button
            variant={
              consentLevel === 'technically_required'
                ? 'primary'
                : 'whiteBorder'
            }
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              handleUpdateCookie('technically_required');
            }}
          >
            {t('COOKIE_BANNER.required_only')}
          </Button>
          <Button
            variant={consentLevel === 'analytics' ? 'primary' : 'whiteBorder'}
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              handleUpdateCookie('analytics');
            }}
          >
            {t('COOKIE_BANNER.analytics')}
          </Button>
          <Button
            variant={consentLevel === 'marketing' ? 'primary' : 'whiteBorder'}
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              handleUpdateCookie('marketing');
            }}
          >
            {t('COOKIE_BANNER.marketing')}
          </Button>
        </Flex>
        <Space />
      </Container>
    </>
  );
};

export default CookieManager;

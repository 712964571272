/** @jsxImportSource theme-ui */
import React, { useRef } from 'react';
import Arrow from '../../../assets/arrow.png';
type Props = {
  xPosition: number;
  yPosition: number;
  ankle?: number;
};

const TrainingArrow = ({ xPosition, yPosition, ankle }: Props) => {
  const markerRef = useRef<HTMLDivElement | null>(null);
  const markerWidth = markerRef?.current?.offsetWidth || 1;
  return (
    <div
      ref={markerRef}
      sx={{
        top: `${yPosition - markerWidth / 2}px`,
        left: `${xPosition - markerWidth / 2}px`,
        position: 'absolute',
        transform: `rotate(${ankle}deg)`,
      }}
    >
      <div
        sx={{
          position: 'relative',
          boxSizing: 'content-box',
        }}
      >
        <div
          sx={{
            backgroundColor: 'red',
            position: 'absolute',
            width: '2px',
            height: '2px',
            left: '4px',
            top: '0px',
            borderRadius: '100%',
            boxShadow: '0px 0px 1px 0px #a05e18',
            border: '0px solid white',
            opacity: 1,
          }}
        ></div>

        <div
          sx={{
            position: 'absolute',
            backgroundImage: `url(${Arrow})`,
            backgroundSize: 'cover',
            width: '20px',
            height: '44px',
            top: '-32px',
            left: '-6px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(TrainingArrow);

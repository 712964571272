import * as React from 'react';

import Header from '../components/02_molecules/Header/Header';
import AuthSection from '../components/03_organisms/AuthSection/AuthSection';
import Page from '../components/04_templates/Page';
import Footer from '../components/02_molecules/Footer/Footer';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

function BusinessLogin() {
  const { t } = useTranslation();

  return (
    <Page title={t('LOGIN_PAGE_META_TITLE')} authRequired={false}>
      <BusinessLoginContent />
      <Footer />
    </Page>
  );
}

function BusinessLoginContent() {
  return (
    <>
      <Header
        theme="light"
        backLinkOverride="https://www.app.stretchme.app/"
        showConnectButton={true}
      />
      <AuthSection
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        variant={'business_login'}
      />
    </>
  );
}

export default BusinessLogin;

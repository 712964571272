import { useTranslation } from 'react-i18next';

import React from 'react';
import { Box } from 'theme-ui';

export interface CollapsedLabelProps {
  isMenuExpanded: boolean;
  direction: 'horizontal' | 'vertical';
  onClick: () => void;
}

const CollapsedLabel: React.FC<CollapsedLabelProps> = ({
  isMenuExpanded,
  direction,
  onClick,
}: CollapsedLabelProps) => {
  const { t } = useTranslation();
  return (
    <Box
      id="collapsed-label"
      sx={{
        position: 'fixed',
        transition:
          'opacity ease-in 0.5s, transform ease-in 0.5s, width ease-in 0.3s, left ease-in 0.3s',
        height: '32px',
        lineHeight: '32px',
        textAlign: 'center',
        width: '150px',
        fontSize: 1,
        fontWeight: 'bold',
        fontFamily: 'heading',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
      style={{
        opacity: !isMenuExpanded && direction === 'vertical' ? '1' : '0',
        pointerEvents: !isMenuExpanded ? 'all' : 'none',
        top: `calc(8.5rem + ${(150 - 32) / 2}px)`,
        left: `calc(-${(150 - 32) / 2 + 32}px)`,
        transform: !isMenuExpanded
          ? 'rotate(-90deg)'
          : 'rotate(-90deg) translateY(100%)',
      }}
      onClick={onClick}
    >
      {t('SMARTMIRROR')}
    </Box>
  );
};

export default CollapsedLabel;

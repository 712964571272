import { useTranslation } from 'react-i18next';

import { Text } from 'theme-ui';

import DefaultPageLayout from '../components/04_templates/DefaultPageLayout';
import '../i18n/i18n';

function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <DefaultPageLayout title={t('TERMS_OF_USE_PAGE_TITLE')}>
      <p>
        {t('AUF_DER_WEBSEITE_WWW_YUUXYOGA')}

        <strong>
          <i>{t('WEBSEITE')}</i>
        </strong>
        {t('SOWIE_MIT_MIT_MIT_MIT_MIT_M')}

        <strong>
          <i>{t('APP')}</i>
        </strong>
        {t('BIETET_DIE_INITIUM_GMBH_ST')}

        <strong>
          <i>{t('WIR')}</i>
        </strong>
        {t('EINE_PLATTFORM_DIE_ES_DEM')}

        <strong>
          <i>{t('DU')}</i>
        </strong>
        {t('IM_RAHMEN_DES_NACHFOLGEND_B')}

        <strong>
          <i>{t('YUUYOGA')}</i>
        </strong>
        {t('EINE_NUTZUNG_VON_YUUYOGA_S')}
      </p>

      <h4>{t('LEISTUNGSGEGENSTAND_VON_YUU')}</h4>

      <Text sx={{ float: 'left' }}> 1.1. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        {t('YUUYOGA_BIETET_DIR_DIE_M_GLICH')}
        <b>
          <i>{t('WORKOUTS')}</i>
        </b>
        {t('VON_AUSGEW_HLTEN_LEHRERN')}
        <b>
          <i>{t('TRAINER')}</i>
        </b>
        {t('ZUZUGREIFEN_UND_W_HREND_UND')}{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 1.2. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        {t('DAS_PERSONALISIERTE_FEEDBACK')}
        <b>
          <i>{t('SMART_MIRROR')}</i>
        </b>
        {t('UND_SO_W_HREND_UND_NACH_EIN')}{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 1.3. </Text>
      <Text sx={{ ml: '2rem' }}> {t('DIE_NUTZUNG_VON_YUUYOGA_ERFORD')} </Text>
      <Text sx={{ float: 'left' }}> 1.4. </Text>
      <Text sx={{ ml: '2rem' }}> {t('ZUR_VOLLUMF_NGLICHEN_NUTZUNG_V')} </Text>

      <h4> {t('VORAUSSETZUNGEN_F_R_DIE_NUT')}</h4>

      <Text sx={{ float: 'left' }}> 2.1. </Text>
      <Text sx={{ ml: '2rem' }}> {t('DU_KANNST_YUUYOGA_NUTZEN_WENN')} </Text>
      <Text sx={{ float: 'left' }}> 2.2. </Text>
      <Text sx={{ ml: '2rem' }}> {t('SOWEIT_DU_DICH_F_R_EIN_KOSTENP')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> a) </Text>
      <Text sx={{ ml: '4rem' }}> {t('DER_FESTLEGUNG_EINES_PASSWORTS')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> b) </Text>
      <Text sx={{ ml: '4rem' }}>
        {' '}
        {t('DER_VERWENDUNG_EINES_NUTZERPRO')}
        <b>
          <i>{t('SOCIAL_LOGIN')}</i>
        </b>
        {t('SOFERN_DU_DICH_MITTELS_SOC')}
        <b>
          <i>{t('ZUGANGSDATEN')}</i>
        </b>
        “).{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 2.3. </Text>
      <Text sx={{ ml: '2rem' }}> {t('DU_HAST_DAF_R_SORGE_ZU_TRAGEN')} </Text>
      <Text sx={{ float: 'left' }}> 2.4. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_HABEN_DAS_RECHT_VON_ZEIT')} </Text>
      <Text sx={{ float: 'left' }}> 2.5. </Text>
      <Text sx={{ ml: '2rem' }}> {t('NACH_EINRICHTUNG_DES_NUTZERKON')} </Text>
      <Text sx={{ float: 'left' }}> 2.6. </Text>
      <Text sx={{ ml: '2rem' }}> {t('IM_RAHMEN_VON_YUUYOGA_WERDEN_D')} </Text>
      <Text sx={{ float: 'left' }}> 2.7. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_WEISEN_DICH_DARAUF_HIN_DA')} </Text>
      <h4>{t('VERF_GBARKEIT_FUNKTIONALIT')}</h4>
      <Text sx={{ float: 'left' }}> 3.1. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_BIETEN_DIE_M_GLICHKEIT_ZUR')} </Text>
      <Text sx={{ float: 'left' }}> 3.2. </Text>
      <Text sx={{ ml: '2em' }}>
        {' '}
        {t('WIR_SIND_BERECHTIGT_YUUYOGA_U')}
        <b>
          <i>{t('ANPASSUNGEN')}</i>
        </b>
        {t('VORAUSGESETZT_DASS_YUUYOGA')}{' '}
      </Text>
      <h4>{t('GEISTIGES_EIGENTUM_UND_ANDE')}</h4>
      <Text sx={{ float: 'left' }}> 4.1. </Text>
      <Text sx={{ ml: '2em' }}> {t('WIR_BEHALTEN_UNS_AN_YUUYOGA_UN')} </Text>
      <Text sx={{ float: 'left' }}> 4.2. </Text>
      <Text sx={{ ml: '2em' }}> {t('HINWEISE_UND_ANGABEN_ZU_URHEBE')} </Text>
      <h4>{t('VERTRAGSDAUER_UND_K_NDIGUNG')}</h4>
      <Text sx={{ float: 'left' }}> 5.1. </Text>
      <Text sx={{ ml: '2rem' }}> {t('DER_ZWISCHEN_DIR_UND_UNS_GESCH')} </Text>
      <Text sx={{ float: 'left' }}> 5.2. </Text>
      <Text sx={{ ml: '2rem' }}> {t('DU_KANNST_DEN_NUTZUNGSVERTRAG')} </Text>
      <Text sx={{ float: 'left' }}> 5.3. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_K_NNEN_DEN_NUTZUNGSVERTRAG')} </Text>
      <Text sx={{ float: 'left' }}> 5.4. </Text>
      <Text sx={{ ml: '2rem' }}> {t('DAS_RECHT_JEDER_PARTEI_ZUR_AUS')} </Text>
      <Text sx={{ float: 'left' }}> 5.5. </Text>
      <Text sx={{ ml: '2rem' }}>{t('AB_WIRKSAMWERDEN_EINER_K_NDIGU')}</Text>
      <h4>{t('SPERRUNG_DES_NUTZERKONTOS')}</h4>
      <Text sx={{ float: 'left' }}> 6.1. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_K_NNEN_DIR_DEN_ZUGRIFF_AUF')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> a) </Text>
      <Text sx={{ ml: '4rem' }}>{t('RECHTE_DRITTER_VERLETZT')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> b) </Text>
      <Text sx={{ ml: '4rem' }}> {t('EIN_SICHERHEITSRISIKO_F_R_DIE')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> c) </Text>
      <Text sx={{ ml: '4rem' }}> {t('YUUYOGA_UND_ODER_ANDERE_NUTZER')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> d) </Text>
      <Text sx={{ ml: '4rem' }}> {t('BETR_GERISCH_SEIN_K_NNTE')} </Text>
      <Text sx={{ float: 'left' }}> 6.2. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_BEHALTEN_UNS_EINE_SPERRUNG')} </Text>
      <Text sx={{ float: 'left' }}> 6.3. </Text>
      <Text sx={{ ml: '2rem' }}>
        {' '}
        {t('WIR_WERDEN_DIE_SPERRUNG_AUFHEB')}{' '}
        <b>
          <a href="mailto:infoa@yuuxyoga.de">infoa@yuuxyoga.de</a>
        </b>{' '}
        {t('KONTAKTIEREN_UM_EINE_KL_RUNG')}{' '}
      </Text>
      <Text sx={{ float: 'left' }}> 6.4. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WEITERGEHENDE_ANSPR_CHE_GEGEN')} </Text>
      <h4>{t('HAFTUNGSBESCHR_NKUNG')}</h4>
      <Text sx={{ float: 'left' }}> 7.1. </Text>
      <Text sx={{ ml: '2rem' }}> {t('WIR_HAFTEN_GLEICH_AUS_WELCHE')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> a) </Text>
      <Text sx={{ ml: '4rem' }}> {t('BEI_VORSATZ_ODER_GROBER_FAHRL')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> b) </Text>
      <Text sx={{ ml: '4rem' }}> {t('BEI_VORS_TZLICHER_ODER_FAHRL_S')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> c) </Text>
      <Text sx={{ ml: '4rem' }}> {t('BEI_FEHLEN_EINER_GARANTIERTEN')} </Text>
      <Text sx={{ float: 'left', ml: '2rem' }}> d) </Text>
      <Text sx={{ ml: '4rem' }}> {t('AUFGRUND_ZWINGENDER_HAFTUNG_WI')} </Text>
      <Text sx={{ float: 'left' }}> 7.2. </Text>
      <Text sx={{ ml: '2rem' }}> {t('BEI_FAHRL_SSIG_VERURSACHTEN_SA')} </Text>
      <Text sx={{ float: 'left' }}> 7.3. </Text>
      <Text sx={{ ml: '2rem' }}> {t('IM_BRIGEN_IST_EINE_HAFTUNG_VO')} </Text>
      <Text sx={{ float: 'left' }}> 7.4. </Text>
      <Text sx={{ ml: '2rem' }}> {t('VORSTEHENDE_HAFTUNGSREGELUNGEN')} </Text>
      <Text sx={{ float: 'left' }}> 7.5. </Text>
      <Text sx={{ ml: '2rem' }}> {t('GESETZLICHE_GEW_HRLEISTUNGSANS')} </Text>
      <h4>{t('DATENSCHUTZ')}</h4>
      <p>
        {t('DIE_VERARBEITUNG_PERSONENBEZOG')}{' '}
        <b>
          <a href="mailto:infoa@yuuxyoga.de">infoa@yuuxyoga.de</a>
        </b>
        .
      </p>
      <h4>{t('ALTERNATIVE_STREITBEILEGUNG')}</h4>

      <p>{t('WIR_SIND_NICHT_BEREIT_ODER_VER')}</p>

      <h4>{t('SCHLUSSVORSCHRIFTEN')}</h4>
      <Text sx={{ float: 'left' }}> 10.1. </Text>
      <Text sx={{ ml: '3rem' }}> {t('ANPASSUNGEN_VON_YUUYOGA_SOWIE')} </Text>
      <Text sx={{ float: 'left' }}> 10.2. </Text>
      <Text sx={{ ml: '3rem' }}> {t('DU_BIST_NICHT_BERECHTIGT_DEN')} </Text>
      <Text sx={{ float: 'left' }}> 10.3. </Text>
      <Text sx={{ ml: '3rem' }}> {t('DER_NUTZUNGSVERTRAG_UNTERLIEGT')} </Text>
    </DefaultPageLayout>
  );
}

export default NotFoundPage;

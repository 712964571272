import * as React from 'react';
import { PosesDataType, TrainingSession } from '@youga/youga-interfaces';
import useSWR, { useSWRConfig } from 'swr';

import { createApiFetcher } from '@youga/youga-client-api';

import API from '../services/API';
import type { HomeContents } from '../types/interfaces';
import { useEnv } from '../context/EnvContext';

import useAuth from './useAuth';

export type ApiType = 'posesData';

type ApiTypeResponse<T> = T extends 'home'
  ? HomeContents
  : T extends 'trainingSessions'
  ? TrainingSession[]
  : T extends 'posesData'
  ? PosesDataType[]
  : never;

export interface ApiHookResponse<T> {
  loading: boolean;
  showLoadingSpinner: boolean;
  idToken: string | null;
  errorMessage?: string;
  data: ApiTypeResponse<T> | undefined;
  updateData: (dataToMerge: T) => void;
  request: <T>(path: string, init: RequestInit) => Promise<T>;
}

const createAuthFetcher =
  <T>() =>
  async (apiType: ApiType): Promise<ApiTypeResponse<T>> => {
    switch (apiType) {
      case 'posesData': {
        const responseBody = await API.getPosesData();
        return responseBody as ApiTypeResponse<T>;
      }
      default:
        throw new Error(`Unknown ApiType provided: ${apiType}`);
    }
  };

export default function useAPI<T extends ApiType>(
  apiType: T,
  maxAge = 3600,
): ApiHookResponse<T> {
  const env = useEnv();
  const auth = useAuth();

  const isAuthenticated = auth.status === 'authenticated';

  const { mutate } = useSWRConfig();
  const { isValidating, data, error } = useSWR<ApiTypeResponse<T>>(
    isAuthenticated ? apiType : null,
    createAuthFetcher(),
    { focusThrottleInterval: maxAge, revalidateOnFocus: false },
  );

  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);
  React.useEffect(() => {
    if (!isValidating) {
      return;
    }

    const timer = setTimeout(() => {
      setShowLoadingSpinner(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isValidating]);

  return {
    loading: isValidating,
    showLoadingSpinner: isValidating
      ? data == null
        ? showLoadingSpinner
        : false
      : false,
    errorMessage: error?.message,
    /* @deprecated */
    idToken: auth.token,
    data,
    updateData: (newData: T) => {
      mutate(apiType, newData, false);
    },
    request: createApiFetcher(env.apiBaseUrl, auth.token ?? undefined),
  };
}

import { useTranslation } from 'react-i18next';

import React from 'react';

import Text from '../../01_atoms/Text/Text';
import { GoalsMeta } from '../../../constants/enums';
import { Image } from 'theme-ui';

interface Props {
  courseId: string;
  onPress: (courseId: string) => void | Promise<void>;
}

interface JourneyProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  badgeImgPath: any;
  nextCourseId: string;
}

const JOURNEYS: { [key: string]: JourneyProps } = {
  // [GoalsMeta.be_young.id]: {
  //   badgeImgPath: require('../../../assets/images/badge_success_basics.png'),
  //   nextCourseId: 'level-up-basics',
  // },
  // [GoalsMeta.gear_up.id]: {
  //   badgeImgPath: require('../../../assets/images/badge_success_headstand.png'),
  //   nextCourseId: 'splits',
  // },
  // [GoalsMeta.get_rid_of_tensions.id]: {
  //   badgeImgPath: require('../../../assets/images/badge_success_30_days.png'),
  //   nextCourseId: 'splits',
  // },
};

function BadgeScreen({ courseId }: Props) {
  const { t } = useTranslation();

  const journey = JOURNEYS[courseId];

  if (journey == null) {
    return null;
  }

  const imagePath = journey.badgeImgPath?.default
    ? journey.badgeImgPath?.default
    : journey.badgeImgPath;

  return (
    <div style={styles.container}>
      <div style={styles.scrollContainer}>
        <div style={styles.badgeContainer}>
          <Image
            src={imagePath}
            style={{ width: 113, float: 'left', marginBottom: '40px' }}
          />

          <Text
            size="legalBold"
            variant="black"
            uppercase
            style={styles.badgeHeadline}
          >
            {t('JOURNEY_ERFOLGREICH_ABGESCHLOS')}
          </Text>
          <div
            style={{
              position: 'absolute',
              flex: 1,
              top: 52 + 137,
            }}
          />
          <Image
            src={require('../../../assets/images/check_black.png')}
            style={{
              flex: 1,
              height: 9,
              width: 10,
              position: 'absolute',
              top: 52 + 142,
            }}
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
    marginTop: 28,
  },
  badgeContainer: {
    width: 350,
    margin: '-30px 0 0 -40px',
  },
  badge: {
    position: 'absolute',
    top: 40,
    flex: 1,
    height: 137,
    width: 117,
  },
  badgeHeadline: {
    marginTop: '40px',
    float: 'left',
    width: 'calc(350px - 133px)',
    fontSize: '20px',
    marginLeft: '20px',
    textAlign: 'left',
  },
  badgeProgress: {
    position: 'absolute',
    flex: 1,
    top: 52 + 137,
  },
  check: {
    flex: 1,
    height: 9,
    width: 10,
    position: 'absolute',
    top: 52 + 142,
  },
  coursePreview: {
    marginTop: 28,
    marginBottom: 16,
    padding: 28,
  },
  previewHeadline: {
    paddingBottom: 16,
  },
};

export default BadgeScreen;

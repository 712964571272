import * as React from 'react';

import type { VideoSession } from '@youga/youga-interfaces';

import ThumbnailVideoItem from './ThumbnailVideoItem';
import Text from '../../01_atoms/Text/Text';
import { useTranslation } from 'react-i18next';
interface Props {
  topline: string;
  subline?: string;
  introUrl?: string;
  introThumb?: string;
  course?: VideoSession;
  finished: boolean;
}

function JourneyCourseItem({ topline, introThumb, course, finished }: Props) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        flex: 1,
        flexDirection: 'row',
        marginTop: 8,
        maxWidth: 300,
        color: finished ? '#a4d9d6' : 'black',
      }}
    >
      <ThumbnailVideoItem
        finished={finished}
        imageSource={course?.headerImage || introThumb}
        duration={course?.duration || '0'}
        playButtonPosition="center"
      />
      <div
        style={{
          marginLeft: '130px',
          flex: 1,
          padding: 5,
          fontSize: '12px',
        }}
      >
        <div>
          <Text
            size="h6light"
            style={{ marginLeft: 0 }}
            variant={finished ? 'main' : 'baseBlack'}
          >
            {topline}
          </Text>
          <h6 style={{ padding: 0, margin: 0, fontSize: '14px' }}>
            {course?.title || t('COURSE_WAITING')}
          </h6>
        </div>
      </div>
    </div>
  );
}
export default JourneyCourseItem;

import { Router } from '@reach/router';
import { Box } from 'theme-ui';

import FooterSection from '../components/03_organisms/FooterSection/FooterSection';
import PairWithConnectSection from '../components/03_organisms/PairWithConnectSection/PairWithConnectSection';
import Page from '../components/04_templates/Page';
import '../i18n/i18n';
/**
 * This page is rendered when the user scans the QR Code on the Connect Page
 * with his smartphone camera and not with the web-app as it is planned.
 */

function PairWithConnectPage() {
  return (
    <Page title="StretchMe - Connect" authRequired={false}>
      <Box sx={{ minHeight: 'calc(100vh - 155px)' }}>
        <Router basepath="/pair-with-connect">
          <PairWithConnectSection path="/*" />
        </Router>
      </Box>
      <FooterSection />
    </Page>
  );
}

export default PairWithConnectPage;

/**
 * @see {@link https://gist.github.com/tschuegge/903b4688a70c2ea34a6270fcc7baac48}
 */
function createUUID(): string {
  // http://www.ietf.org/rfc/rfc4122.txt
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0; // Nachkommastellen abschneiden
    const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemäss RFC 4122
    return value.toString(16); // Hexadezimales Zeichen zurückgeben
  });
}

export default createUUID;

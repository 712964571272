/** @jsxImportSource theme-ui */

import { useTranslation } from 'react-i18next';
import '../i18n/i18n';
import { Box, Image, Grid } from 'theme-ui';
import React, { useRef, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';
import Text from '../components/01_atoms/Text/Text';
import Container from '../components/01_atoms/Container/Container';
import SmartMirrorInfoBox from '../components/01_atoms/SmartMirrorInfoBox/SmartMirrorInfoBox';
import PoseComparisonInfoBox from '../components/01_atoms/PoseComparisonInfoBox/PoseComparisonInfoBox';
import VideoOverlay from '../components/01_atoms/VideoOverlay/VideoOverlay';
import Header from '../components/02_molecules/Header/Header';
import useTracking from '../hooks/useTracking';
import ExplainerVideoPreview from '../assets/explainerVideoPreview.png';
import BtnPlay from '../assets/btnPlay.inline.svg';
import Accordion from '../components/01_atoms/Accordion/Accordion';
import Footer from '../components/02_molecules/Footer/Footer';

const QuestionsAndAnswers: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const [videoVisible, setVideoVisible] = useState(false);
  const { track } = useTracking();
  const trackExplainerVideo = useRef(false);

  // 1.- Track HomeSection
  useEffect(() => {
    track('how-it-works-section');
  }, [track]);

  // 2. Track Explainer Video Opened
  useEffect(() => {
    if (videoVisible && !trackExplainerVideo.current) {
      trackExplainerVideo.current = true;
      track('how-it-works-explainer-video-opened');
    }
  }, [track, videoVisible]);

  return (
    <React.Fragment>
      <VideoOverlay
        closeOnFinish
        videoUrl="https://stretchme-app-stack-imagebucket6194e37c-1tk6xzff9kcqw.s3.eu-central-1.amazonaws.com/stretchme+POPRAWKI+PL.mp4"
        visible={videoVisible}
        videoMaxWidth={1920}
        onPlay={(): void => {
          track('how-it-works-explainer-video-play');
        }}
        onFinish={(): void => {
          track('how-it-works-explainer-video-finished');
        }}
        onClose={(): void => {
          setVideoVisible(false);
        }}
      />

      <Header showButton="back" />
      <Container sx={{ transition: 'filter ease-in 0.3s' }}>
        <Text variant="h2" sx={{ fontSize: [2, 4], mb: [5, 7] }} highlighted>
          {t('VISUELLES_FEEDBACK_SO_FUNKTI1')}
        </Text>
      </Container>

      <Box sx={{ position: 'relative', pb: 9 }}>
        <Box
          sx={{
            position: 'absolute',
            top: ['100px', '200px', '300px', '400px'],
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: '-1',
            backgroundColor: 'grey1',
          }}
        />

        <Container>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                cursor: 'pointer',
                paddingTop: '55.78%',
                overflow: 'hidden',
              }}
              onClick={(): void => {
                setVideoVisible(true);
              }}
            >
              <Image
                src={ExplainerVideoPreview}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  boxShadow: '0px 10px 16px 0px rgba(0, 0, 0, 0.1)',
                }}
              />
              <Box
                sx={{
                  display: ['none', 'block'],
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '254px',
                  borderRadius: '8px 8px 0 0',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BtnPlay
                  sx={{ width: [null, '5rem'], height: [null, '5rem'] }}
                  className="assets-btn-play"
                />
              </Box>
            </Box>

            <Text
              variant="h1"
              sx={{
                fontSize: [1, 4, 5],
                color: 'white',
                position: 'absolute',
                top: [0, null, 8, 14],
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                maxWidth: ['230px', '480px', '620px'],
                m: 0,
                mt: [4, null, 0],
                mb: [16, null, 0],
                textAlign: 'center',
                textTransform: 'none',
                '@media (max-width: 374px)': {
                  mt: 3,
                  fontSize: 0,
                  maxWidth: '210px',
                },
              }}
            >
              {t('ALLE_VORTEILE_VON_YUU_YOGA_IN')}
            </Text>
          </Box>

          <Grid columns={[1, null, 2]} gap="2.5rem" sx={{ mt: 8 }}>
            <PoseComparisonInfoBox />
            <SmartMirrorInfoBox />
          </Grid>
        </Container>
      </Box>

      <Box id="faq" sx={{ mt: '20px' }} />
      {/* extremely high margin-bottom because the footer has a negative margin */}
      <Container sx={{ mb: ['15rem', 25], mt: 13 }}>
        <Box>
          <Text variant="h2">{t('NOCH_FRAGEN_WIR_HELFEN_DIR_GE')}</Text>
        </Box>

        <Accordion
          elements={[
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_1'),

              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_1')}{' '}
                  </p>
                </React.Fragment>
              ),
            },

            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_2'),

              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_2')}{' '}
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_3'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_3_1')}{' '}
                  </p>
                  <ul>
                    <li>{t('QUESTIONS_AND_ANSWERS.ANSWER_3_2')}</li>
                    <li sx={{ pt: 2 }}>
                      {t('QUESTIONS_AND_ANSWERS.ANSWER_3_3')}
                    </li>
                    <li sx={{ pt: 2 }}>
                      {t('QUESTIONS_AND_ANSWERS.ANSWER_3_4')}
                    </li>
                  </ul>
                  {t('QUESTIONS_AND_ANSWERS.ANSWER_3_5')}
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_4'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_4')}{' '}
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_5'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_5')}{' '}
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_6'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_6_1')}{' '}
                  </p>
                  <ul>
                    <li>{t('QUESTIONS_AND_ANSWERS.ANSWER_6_2')}</li>
                    <li sx={{ pt: 2 }}>
                      {t('QUESTIONS_AND_ANSWERS.ANSWER_6_3')}
                    </li>
                    <li sx={{ pt: 2 }}>
                      {t('QUESTIONS_AND_ANSWERS.ANSWER_6_4')}
                    </li>
                  </ul>
                  <p sx={{ m: 0 }}>{t('QUESTIONS_AND_ANSWERS.ANSWER_6_5')} </p>
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_7'),
              content: (
                <React.Fragment>
                  <p
                    sx={{ m: 0, pt: 5, pb: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: t('QUESTIONS_AND_ANSWERS.ANSWER_7'),
                    }}
                  />
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_8'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_8')}{' '}
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_9'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_9')}{' '}
                  </p>
                </React.Fragment>
              ),
            },

            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_10'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_10_1')}{' '}
                  </p>

                  <p sx={{ m: 0, pt: 3 }}>
                    <b>{t('QUESTIONS_AND_ANSWERS.ANSWER_10_2')}</b>

                    <br />
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_10_3')}
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>{t('QUESTIONS_AND_ANSWERS.ANSWER_10_4')}</b>

                    <br />
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_10_5')}
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>{t('QUESTIONS_AND_ANSWERS.ANSWER_10_6')}</b>

                    <br />
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_10_7')}
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>{t('QUESTIONS_AND_ANSWERS.ANSWER_10_8')}</b>

                    <br />
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_10_9')}
                  </p>
                  <p sx={{ m: 0, pt: 3 }}>
                    <b>{t('QUESTIONS_AND_ANSWERS.ANSWER_10_10')}</b>

                    <br />
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_10_11')}
                  </p>
                </React.Fragment>
              ),
            },
            {
              title: t('QUESTIONS_AND_ANSWERS.QUESTION_11'),
              content: (
                <React.Fragment>
                  <p sx={{ m: 0, pt: 5, pb: 0 }}>
                    {t('QUESTIONS_AND_ANSWERS.ANSWER_11')}{' '}
                  </p>
                </React.Fragment>
              ),
            },
          ]}
        />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default QuestionsAndAnswers;

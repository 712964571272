/** @jsxImportSource theme-ui */

import React from 'react';

import SplashInstructor from '../../01_atoms/SplashInstructor/SplashInstructor';

interface SplashInstructorCarouselItemProps {
  thumbnail: string;
  target: string;
  name: string;
  collabLogo: string;
}

const SplashInstructorCarouselItem: React.FC<SplashInstructorCarouselItemProps> =
  ({
    thumbnail,
    target,
    name,
    collabLogo,
  }: SplashInstructorCarouselItemProps) => (
    <div sx={{ width: '100%', minWidth: '100%' }}>
      <SplashInstructor
        collabLogo={collabLogo}
        thumbnail={thumbnail}
        target={target}
        name={name}
      />
    </div>
  );

export default SplashInstructorCarouselItem;

import React from 'react';

import Button from '../../01_atoms/Button/Button';
import Text from '../../01_atoms/Text/Text';
import OverlayModal from '../../01_atoms/OverlayModal/OverlayModal';
import { Box } from '@theme-ui/components';
import { AuthVariant } from './AuthBox';
import { navigate } from 'gatsby';

export interface IInformationScreen {
  allowClose?: boolean;
  title: string;
  icon: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  iconColor: 'primary' | 'success' | 'error' | 'warning';
  description: string | React.ReactNode;
  buttonLabel?: string;
  buttonTargetVariant?: AuthVariant;
}

interface InformationModalProps {
  informationScreen: IInformationScreen | null;
  setInformationScreen: (v: IInformationScreen | null) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
}

export default function InformationModal({
  informationScreen,
  setInformationScreen,
  setCurrentVariant,
}: InformationModalProps) {
  return (
    <OverlayModal
      allowClose={informationScreen?.allowClose || false}
      portalSelector="#overlay-modal"
      visible={!!informationScreen}
      onClose={(): void => {
        setInformationScreen(null);
      }}
    >
      {(hide): React.ReactElement | null => {
        if (!informationScreen) {
          return null;
        }

        return (
          <React.Fragment>
            <Box>
              <informationScreen.icon
                sx={{
                  width: '3rem',
                  height: '3rem',
                  margin: '0 auto',
                  display: 'block',
                  '> *': { fill: informationScreen.iconColor },
                }}
              />
            </Box>
            <Box>
              <Text variant="h3">{informationScreen.title}</Text>
              <Text>{informationScreen.description}</Text>
            </Box>
            {informationScreen.buttonLabel && (
              <Button
                sx={{ mt: 3 }}
                variant="primary"
                onClick={(): void => {
                  if (informationScreen.buttonTargetVariant === 'signin') {
                    navigate('/login');
                    return;
                  }
                  if (informationScreen.buttonTargetVariant) {
                    setCurrentVariant(informationScreen.buttonTargetVariant);
                  }
                  hide(() => setInformationScreen(null));
                }}
              >
                {informationScreen.buttonLabel}
              </Button>
            )}
          </React.Fragment>
        );
      }}
    </OverlayModal>
  );
}

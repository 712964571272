import * as React from 'react';
import { Hub, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { z } from 'zod';
import axios from 'axios';

import jwtParser from '../utils/jwtParser';

/**
 * Apple: AccessToken is valid for 24h
 *
 */

interface HubCapsule {
  channel: string;
  payload: HubPayload;
  source: string;
  patternInfo?: string[];
}

interface HubPayload {
  event: string;
  data?: never;
  message?: string;
}

const UserAttributes = z.object({
  sub: z.string(),
  email: z.string().email(),
  email_verified: z.boolean(),
  'custom:newsletter': z
    .preprocess((val) => Boolean(val), z.boolean())
    .optional(),
  'custom:referral': z
    .preprocess((val) => Boolean(val), z.boolean())
    .optional(),
  'custom:terms_agreed': z
    .preprocess((val) => Boolean(val), z.boolean())
    .optional(),
});

const User = z.object({
  username: z.string(),
  attributes: UserAttributes,
});

export type AuthenticatedUser = z.infer<typeof User>;

type AuthState =
  | { status: 'init'; user: null; token: null }
  | { status: 'loading'; user: null; token: null }
  | { status: 'error'; error: string; user: null; token: null }
  | { status: 'unauthenticated'; user: null; token: null }
  | { status: 'authenticated'; user: AuthenticatedUser; token: string };

export enum AuthActionType {
  Success,
  Failure,
  UnAuth,
  CONFIRM,
}

export interface SetUnAuth {
  type: AuthActionType.UnAuth;
}

export interface SetAuthSuccess {
  type: AuthActionType.Success;
  payload: { user: AuthenticatedUser; token: string };
}

export interface CONFIRM {
  type: AuthActionType.CONFIRM;
  payload: { user: AuthenticatedUser; token: string };
}

export interface SetAuthError {
  type: AuthActionType.Failure;
  payload: string;
}

export type AuthActions = SetAuthSuccess | SetAuthError | SetUnAuth | CONFIRM;

const authReducer: React.Reducer<AuthState, AuthActions> = (state, action) => {
  switch (action.type) {
    case AuthActionType.Success:
      return {
        status: 'authenticated',
        user: action.payload.user,
        token: action.payload.token,
      };
    case AuthActionType.Failure:
      return {
        status: 'error',
        error: action.payload,
        user: null,
        token: null,
      };
    case AuthActionType.UnAuth:
      return { status: 'unauthenticated', user: null, token: null };
    case AuthActionType.CONFIRM:
      return {
        status: 'authenticated',
        user: action.payload.user,
        token: action.payload.token,
      };
    default:
      return state;
  }
};

const initialState: AuthState = { status: 'init', user: null, token: null };

const AuthTokens = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  idToken: z.string(),
});

type IAuthTokens = z.infer<typeof AuthTokens>;

interface IAuthContext {
  state: AuthState;
  handleSignOut: () => Promise<void>;
  handleSignInWithEmail: (email: string, password: string) => Promise<void>;
  isInitialized: boolean;
  isSignedIn: boolean;
  signInWithToken: (token: IAuthTokens) => Promise<void>;
  refreshAuthContext: (code: string) => Promise<void>;
  fetchAndSetCurrentUser: () => Promise<string>;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

interface Props {
  clientId: string;
  onAuthRequired?: () => void;
}

function AuthProvider({
  clientId,
  onAuthRequired,
  children,
}: React.PropsWithChildren<Props>) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  async function fetchAndSetCurrentUser() {
    // Requests `AWSCognitoIdentityProviderService.GetUser`
    console.log('asdasd');
    const currentAuthenticatedUser: CognitoUser =
      await Auth.currentAuthenticatedUser();
    console.log('currentAuthenticatedUser', { currentAuthenticatedUser });

    const userSession = currentAuthenticatedUser.getSignInUserSession();
    console.log('userSession', { userSession });

    if (userSession == null) {
      throw new Error(`Can't sign-in user. Missing: "userSession"`);
    }

    const username = currentAuthenticatedUser.getUsername();
    console.log('asusernamedasd', { username });

    if (username == null) {
      throw new Error(`Can't sign-in user. Missing: "username"`);
    }

    // See: https://docs.amplify.aws/lib/restapi/authz/q/platform/js/#note-related-to-use-access-token-or-id-token
    const idToken = userSession.getIdToken();

    const newUser = User.parse({ username, attributes: idToken.payload });
    const token = idToken.getJwtToken();

    if (token == null) {
      throw new Error(`Can't sign-in user. Missing: "token"`);
    }

    dispatch({
      type: AuthActionType.Success,
      payload: {
        user: newUser,
        token,
      },
    });
    return token;
  }

  React.useEffect(() => {
    let isMounted = true;
    axios
      .get(
        'https://jxhe8gdr5k.execute-api.eu-central-1.amazonaws.com/default/EC2_video-stream-processor_Start',
      )
      .then(() => console.log('processing server initialated'))
      .catch(() => console.log('server endpoint problem'));

    const fetchUserData = async () => {
      try {
        if (isMounted) {
          await fetchAndSetCurrentUser();
        }
      } catch (error) {
        console.log(`fetchUserData error`, error);
        if (isMounted) {
          dispatch({
            type: AuthActionType.Failure,
            payload:
              error instanceof Error ? error.message : 'Failed to get user',
          });
        }
      }
    };

    /**
     * @see {@link https://haverchuck.github.io/docs/js/hub#listening-authentication-events}
     */
    const onAuthEvent = ({ payload }: HubCapsule) => {
      console.log(`onAuthEvent()`, payload);
      switch (payload.event) {
        case 'signIn':
          console.log('user signed in');
          break;
        case 'signUp':
          console.log('user signed up');
          break;
        case 'signOut':
          console.log('user signed out');
          if (isMounted) {
            dispatch({ type: AuthActionType.UnAuth });
          }
          break;
        case 'signIn_failure':
          console.log('user sign in failed');
          break;
        case 'confirm_email':
          fetchAndSetCurrentUser();
          console.log('user sign in failed');
          break;
        case 'tokenRefresh':
          console.log('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          console.log('token refresh failed');
          break;
        case 'configured':
          console.log('the Auth module is configured');
        default:
          return;
      }
    };

    fetchUserData();

    Hub.listen('auth', onAuthEvent);
    return () => {
      Hub.remove('auth', onAuthEvent);
      isMounted = false;
    };
  }, []);

  async function handleSignInWithEmail(email: string, password: string) {
    await Auth.signIn(
      email.trim().toLowerCase().replace('@', '_at_'),
      password,
    );
    console.log;
    await fetchAndSetCurrentUser();
  }

  // async function signInWithCode(email: string, password: string, code: string) {
  //   try {
  //       // You can add the code as a custom attribute or modify the request in any way you see fit
  //       const user = await Auth.signUp({
  //           username: email.trim().toLowerCase().replace('@', '_at_'),
  //           password,
  //           attributes: {
  //               'custom:signupCode': code // assuming you are storing the code in a custom attribute
  //           }
  //       });

  //       // After successful signUp with the code, you might also want to signIn the user
  //       await handleSignInWithEmail(email, password);

  //       return user;
  //   } catch (error) {
  //       console.error("Error signing up with code:", error);
  //       throw error;
  //   }

  async function handleSignOut() {
    await Auth.signOut();
    dispatch({ type: AuthActionType.UnAuth });
  }

  async function refreshAuthContext(code: string) {
    await Auth.verifyCurrentUserAttributeSubmit('email', code);
    const currentAuthenticatedUser: CognitoUser =
      await Auth.currentAuthenticatedUser();
    const userSession = currentAuthenticatedUser.getSignInUserSession();
    if (userSession == null) {
      throw new Error(`Can't sign-in user. Missing: "userSession"`);
    }

    const username = currentAuthenticatedUser.getUsername();

    if (username == null) {
      throw new Error(`Can't sign-in user. Missing: "username"`);
    }

    // See: https://docs.amplify.aws/lib/restapi/authz/q/platform/js/#note-related-to-use-access-token-or-id-token
    const idToken = userSession.getIdToken();

    const newUser = User.parse({ username, attributes: idToken.payload });
    const token = idToken.getJwtToken();

    if (token == null) {
      throw new Error(`Can't sign-in user. Missing: "token"`);
    }

    await fetchAndSetCurrentUser();

    dispatch({
      type: AuthActionType.CONFIRM,
      payload: {
        user: newUser,
        token,
      },
    }); //fixando sprawdzić czy dispatchuje useAuth
  }

  /**
   * @see {@link https://docs.cypress.io/guides/testing-strategies/amazon-cognito-authentication#Custom-Command-for-Amazon-Cognito-Authentication}
   */
  const storeTokens = React.useCallback(
    (username: string, tokens: IAuthTokens) => {
      const prefix = `CognitoIdentityServiceProvider.${clientId}`;
      const prefixWithUser = `${prefix}.${username}`;
      localStorage.setItem(`${prefix}.LastAuthUser`, username);
      localStorage.setItem(`${prefixWithUser}.accessToken`, tokens.accessToken);
      localStorage.setItem(
        `${prefixWithUser}.refreshToken`,
        tokens.refreshToken,
      );
      if (tokens.idToken) {
        localStorage.setItem(`${prefixWithUser}.idToken`, tokens.idToken);
      } else {
        localStorage.removeItem(`${prefixWithUser}.idToken`);
      }
    },
    [clientId],
  );

  const deleteTokens = React.useCallback(
    (username: string) => {
      const prefix = `CognitoIdentityServiceProvider.${clientId}`;
      const prefixWithUser = `${prefix}.${username}`;
      localStorage.removeItem(`${prefix}.LastAuthUser`);
      localStorage.removeItem(`${prefixWithUser}.idToken`);
      localStorage.removeItem(`${prefixWithUser}.accessToken`);
      localStorage.removeItem(`${prefixWithUser}.refreshToken`);
    },
    [clientId],
  );

  const signInWithToken = React.useCallback(
    async (rawTokens: IAuthTokens) => {
      const tokens = AuthTokens.parse(rawTokens);
      const idTokenData = jwtParser(tokens.idToken);
      const username = idTokenData['cognito:username'];
      try {
        storeTokens(username, tokens);
        await fetchAndSetCurrentUser();
      } catch (error) {
        deleteTokens(username);
        throw error;
      }
    },
    [storeTokens, deleteTokens],
  );

  const isInitialized = state.status !== 'init';
  const isSignedIn = state.status === 'authenticated';

  if (onAuthRequired != null && isInitialized && !isSignedIn) {
    onAuthRequired();
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        handleSignOut,
        handleSignInWithEmail,
        refreshAuthContext,
        isInitialized,
        isSignedIn,
        signInWithToken,
        fetchAndSetCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const authContextValue = React.useContext(AuthContext);

  if (authContextValue == null) {
    throw new Error('useAuth() must be used within a AuthProvider');
  }

  const { state, ...other } = authContextValue;

  return {
    ...state,
    ...other,
  };
}

export { AuthProvider };
export default useAuth;

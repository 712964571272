import React from 'react';

type Props = {
  coordinates: Array<Array<number[][] | boolean>>;
  frameWidth: number;
  frameHeight: number;
};

interface MappedCoordinates {
  x1?: number;
  y2?: number;
  y1?: number;
  x2?: number;
  color?: string;
}

const SkeletonTrainingAnimation = ({
  coordinates,
  frameWidth,
  frameHeight,
}: Props) => {
  const mirrorContainerElement = document.getElementById('MirrorContainer');
  const mirrorWidth = mirrorContainerElement?.clientWidth || 0;
  const mirrorHeight = mirrorContainerElement?.clientHeight || 0;
  const xFactor = mirrorWidth / frameWidth;
  const yFactor = mirrorHeight / frameHeight;

  const mappedCoordinates: MappedCoordinates[] = coordinates.map(
    ([points, valid]) => {
      if (Array.isArray(points)) {
        const [[x1, y1], [x2, y2]] = points;
        return {
          x1: Math.abs(x1 * xFactor - mirrorWidth),
          y1: y1 * yFactor,
          x2: Math.abs(x2 * xFactor - mirrorWidth),
          y2: y2 * yFactor,
          color: valid ? 'green' : 'red',
        };
      }
      return {};
    },
  );
  return (
    <svg
      width={mirrorWidth}
      height={mirrorHeight}
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute' }}
    >
      <defs>
        <linearGradient id="redGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#fc2b42" />
          <stop offset="70%" stop-color="#f28d7f" />
          <stop offset="100%" stop-color="transparent" />
        </linearGradient>
        <linearGradient
          id="revertRedGradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stop-color="transparent" />
          <stop offset="30%" stop-color="#fc2b42" />
          <stop offset="100%" stop-color="#fc2b42" />
        </linearGradient>
      </defs>
      {mappedCoordinates.map(({ x1, y1, x2, y2, color }) => (
        <React.Fragment>
          <line
            x1={x1}
            x2={x2}
            y1={y1}
            y2={y2}
            stroke={
              y1 && y2 && y1 > y2
                ? 'url(#revertRedGradient)'
                : 'url(#redGradient)'
            }
            stroke-width="4"
            stroke-linecap="round"
          />
        </React.Fragment>
      ))}
    </svg>
  );
};

export default SkeletonTrainingAnimation;

/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { Image } from 'theme-ui';
import BtnClose from '../../../assets/btnClose.svg';
import ScretchmeIcon from '../../../assets/images/stretchme_icon.png';
import { useTranslation } from 'react-i18next';

const AndroidPromoLink = (): JSX.Element => {
  const { t } = useTranslation();
  const [showComponent, setShowComponent] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userAgent = window.navigator.userAgent;
      const isAndroid = /Android/i.test(userAgent);
      setShowComponent(isAndroid);
    }
  }, []);

  if (typeof window == 'undefined') {
    return <></>;
  }

  if (!showComponent) {
    return <></>;
  }

  return (
    <div
      sx={{
        position: 'fixed',
        zIndex: 200,
        backgroundColor: 'white',
        top: 0,
        height: 80,
        borderBottom: '0.5px solid #efefef',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <div
          onClick={() => {
            setShowComponent(false);
          }}
          sx={{
            width: '15px',
            height: '15px',
            marginLeft: 2,
            marginRight: 1,
          }}
        >
          <Image src={BtnClose} />
        </div>
        <div
          sx={{
            width: '45px',
            height: '45px',
            marginLeft: 2,
            borderRadius: 10,
            overflow: 'hidden',
            marginRight: 1,
          }}
        >
          <Image src={ScretchmeIcon} />
        </div>
        <div
          sx={{
            height: '45px',
            marginLeft: 2,
            borderRadius: 10,
            overflow: 'hidden',
            maxWidth: '90px',
            marginRight: 1,
            fontSize: 14,
          }}
        >
          <p sx={{ margin: 0, marginTop: '2px' }}>Stretchme.app</p>
          <p sx={{ margin: 0, fontSize: 10, color: '#6f6f6f' }}>
            {t('android_promo_banner.desc')}
          </p>
        </div>
      </div>
      <div sx={{ alignItems: 'center', display: 'flex' }}>
        <a
          href="https://play.google.com/store/apps/details?id=com.yuuyoga"
          sx={{
            background: '#007afe',
            borderRadius: 50,
            color: 'white !important',
            textDecoration: 'none',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            padding: 1,
            paddingLeft: 2,
            paddingRight: 2,
            marginRight: 2,
            cursor: 'pointer',
            fontSize: 10,
          }}
        >
          {t('android_promo_banner.install')}
        </a>
      </div>
    </div>
  );
};

export default AndroidPromoLink;

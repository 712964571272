import { Course, HomeContents } from '@youga/youga-interfaces';
import { animated, useTransition } from 'react-spring';

import CourseCarouselItem from '../CourseCarouselItem/CourseCarouselItem';

export default function AnimatedFavoriteCourses({
  instructors,
  favoriteCourses,
}: {
  instructors?: HomeContents['instructors'];
  favoriteCourses: Course[];
}) {
  let count = 0;
  const transition = useTransition(favoriteCourses, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    duration: 100,
  });

  return transition((style, item) => {
    const course = item;
    count++;
    return (
      <animated.div style={style}>
        <CourseCarouselItem
          target={`/app/course-detail/${course.id}`}
          key={`coursecarousel-item-${course.id}`}
          title={course.title}
          content={course.subtitle}
          sessions={course.sessions.length}
          thumbnailUrl={course.thumbnail || ''}
          thumbnailHqUrl={course.thumbnailHq || ''}
          labels={course.labels}
          variant={'favorites'}
          level={course.level}
          preview={course.preview}
          instructorId={course.instructor}
          courseId={course.id}
          instructor={
            ((instructors && instructors[course.instructor]) || {}).name || ''
          }
          isLastElement={
            ((favoriteCourses && favoriteCourses) || []).length === count
          }
        />
      </animated.div>
    );
  });
}

import React, { useMemo } from 'react';
import { Box } from 'theme-ui';

import type { Course } from '@youga/youga-interfaces';
import { useCourses, useInstructors } from '@youga/youga-client-api';

import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import CourseCarouselItem from '../../03_organisms/CourseCarouselItem/CourseCarouselItem';

import Carousel from '../Carousel/Carousel';
import { t } from 'i18next';
import i18n from '../../../i18n/i18n';

const PoseComparisonOverviewCourses: React.FC<unknown> = () => {
  const { data: instructorsData } = useInstructors();
  const { data: coursesData } = useCourses(i18n.language);

  const courses = useMemo(() => {
    const sortedCourses = coursesData?.sortedCourses ?? [];

    return sortedCourses
      .map((courseId) => coursesData?.courses[courseId])
      .filter((course) => !!course) as Course[];
  }, [coursesData]);

  return (
    <Box
      sx={{
        mt: 9,
      }}
    >
      <Container>
        <Text
          variant="h2light"
          sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}
        >
          {t('START_OUR_COURSESS')}
        </Text>
        <Box
          style={{
            position: 'relative',
            // We need to extend the width a bit, to allow the shadow to be visible
            // This space will be added afterwards as a padding-left at the items
            marginLeft: '-20px',
            marginRight: '-20px',
            width: 'calc(100% + 40px)',
          }}
        >
          <Carousel
            imageHeight={180}
            hideControlsOnTouchDevices
            hideControlShadow
          >
            {courses
              .filter((c) => !c.preview)
              .map((course: Course, index: number) => (
                <CourseCarouselItem
                  target={`/app/course-detail/${course.id}`}
                  key={`coursecarousel-item-${course.id}`}
                  courseId={course.title}
                  title={course.titleEng}
                  content={course.subtitleEng}
                  sessions={course.sessions.length}
                  thumbnailUrl={course.thumbnail}
                  thumbnailHqUrl={course.thumbnailHq}
                  level={course.level}
                  instructorId={course.instructor}
                  instructor={
                    (instructorsData?.instructors[course.instructor] || {})
                      .name || ''
                  }
                  isLastElement={
                    (coursesData?.sortedCourses || []).length - 1 === index
                  }
                />
              ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default PoseComparisonOverviewCourses;

/** @jsxImportSource theme-ui */

import React, { useState, useRef, useEffect } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import debounce from 'lodash/debounce';
import SwipeControl from '../../01_atoms/SwipeControl/SwipeControl';

export interface CarouselProps {
  children: React.ReactNode;
  imageHeight?: number;
  hideControlsOnTouchDevices?: boolean;
  hideControlShadow?: boolean;
  resetScrollOnChildrenChange?: boolean;
  fullHeight?: boolean;
  alignItems?: string;
  swipeLeftStyle?: ThemeUIStyleObject;
  swipeRightStyle?: ThemeUIStyleObject;
}

const Carousel: React.FC<CarouselProps> = ({
  imageHeight,
  children,
  hideControlsOnTouchDevices = false,
  hideControlShadow = false,
  resetScrollOnChildrenChange = true,
  fullHeight = false,
  alignItems,
  swipeLeftStyle,
  swipeRightStyle,
}: CarouselProps) => {
  const childrenSelector = ':scope > *:not(.swipe-control)';
  const elRef = useRef<HTMLDivElement>();
  const [swipeRightVisible, setSwipeRightVisible] = useState(false);
  const [swipeLeftVisible, setSwipeLeftVisible] = useState(false);

  const scrollListener = (): void => {
    if (!elRef.current) {
      return;
    }

    const { scrollLeft, offsetWidth, scrollWidth } =
      elRef.current as unknown as HTMLDivElement;

    setSwipeLeftVisible(scrollLeft > 0);
    setSwipeRightVisible(scrollLeft + offsetWidth < scrollWidth);
  };

  useEffect(() => {
    scrollListener();
  }, [elRef]);

  useEffect(() => {
    scrollListener();

    if (resetScrollOnChildrenChange && elRef.current) {
      (elRef.current as unknown as HTMLDivElement).scrollLeft = 0;
    }
  }, [children, resetScrollOnChildrenChange]);

  return (
    <Box
      ref={elRef as React.RefObject<HTMLDivElement>}
      sx={{
        display: 'flex',
        alignItems,
        width: '100%',
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        height: fullHeight ? '100%' : undefined,
      }}
      onScroll={debounce(scrollListener, 50)}
    >
      {children}

      <SwipeControl
        sx={swipeLeftStyle}
        hideControlsOnTouchDevices={hideControlsOnTouchDevices}
        hideControlShadow={hideControlShadow}
        direction="left"
        btnTopDistance={
          // We need to add 4px because of the whitespace in the svg
          imageHeight ? `calc((${imageHeight}px / 2) + 4px)` : '50%'
        }
        visible={swipeLeftVisible}
        onClick={(): void => {
          if (elRef.current == null) {
            return;
          }
          const { scrollLeft } = elRef.current;
          const childrenElement =
            elRef.current.querySelector<HTMLDivElement>(childrenSelector);
          const childrenWidth = childrenElement?.offsetWidth;
          if (childrenWidth) {
            elRef.current.scrollTo({
              left: Math.max(scrollLeft - childrenWidth, 0),
              behavior: 'smooth',
            });
          }
        }}
      />

      <SwipeControl
        sx={swipeRightStyle}
        hideControlsOnTouchDevices={hideControlsOnTouchDevices}
        hideControlShadow={hideControlShadow}
        direction="right"
        btnTopDistance={
          // We need to add 4px because of the whitespace in the svg
          imageHeight ? `calc((${imageHeight}px / 2) + 4px)` : '50%'
        }
        visible={swipeRightVisible}
        onClick={(): void => {
          if (elRef.current == null) {
            return;
          }

          const { scrollLeft, scrollWidth, offsetWidth } = elRef.current;
          const childrenElement =
            elRef.current.querySelector<HTMLDivElement>(childrenSelector);
          const childrenWidth = childrenElement?.offsetWidth;
          if (childrenWidth) {
            elRef.current.scrollTo({
              left: Math.min(
                scrollLeft + childrenWidth,
                scrollWidth - offsetWidth,
              ),
              behavior: 'smooth',
            });
          }
        }}
      />
    </Box>
  );
};

export default Carousel;

import React, { useRef, useEffect, useState } from 'react';
import { Image, Label, Radio, Box } from 'theme-ui';
import debounce from 'lodash/debounce';
import OverlayModal from '../../01_atoms/OverlayModal/OverlayModal';
import Button from '../../01_atoms/Button/Button';
import Text from '../../01_atoms/Text/Text';
import IllustrationPoseDelete from '../../../assets/images/IllustrationPoseDelete.svg';
import { useTranslation } from 'react-i18next';
interface DeletePoseComparisonOverlayProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => Promise<void>;
}

const DeletePoseComparisonOverlay: React.FC<DeletePoseComparisonOverlayProps> =
  ({ visible, onClose, onConfirm }: DeletePoseComparisonOverlayProps) => {
    const [containerHeights, setContainerHeights] = useState<{
      [key: string]: number;
    }>({});
    const [checkedAnswer, setCheckedAnswer] = useState<string | null>(null);
    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const { t } = useTranslation();

    const timingContainerRef = useRef<HTMLDivElement | null>(null);
    const qualityContainerRef = useRef<HTMLDivElement | null>(null);
    const comfortContainerRef = useRef<HTMLDivElement | null>(null);
    const privacyContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const updateHeights = (): void => {
        setContainerHeights({
          timing:
            timingContainerRef.current?.getBoundingClientRect()?.height || 0,
          quality:
            qualityContainerRef.current?.getBoundingClientRect()?.height || 0,
          comfort:
            comfortContainerRef.current?.getBoundingClientRect()?.height || 0,
          privacy:
            privacyContainerRef.current?.getBoundingClientRect()?.height || 0,
        });
      };

      const debounced = debounce(updateHeights, 300);

      window.addEventListener('resize', debounced);
      updateHeights();

      return (): void => {
        window.removeEventListener('resize', debounced);
      };
    }, []);

    const questions = [
      {
        id: 'timing',
        ref: timingContainerRef,
        headline: t('QUESTION_1_HEADLINE'),
        answer: t('QUESTION_1_ANSWEAR'),
      },
      {
        id: 'quality',
        ref: qualityContainerRef,
        headline: t('QUESTION_2_HEADLINE'),
        answer: t('QUESTION_2_ANSWEAR'),
      },
      {
        id: 'comfort',
        ref: comfortContainerRef,
        headline: t('QUESTION_3_HEADLINE'),
        answer: t('QUESTION_3_ANSWEAR'),
      },
      {
        id: 'privacy',
        ref: privacyContainerRef,
        headline: t('QUESTION_4_HEADLINE'),
        answer: t('QUESTION_4_ANSWEAR'),
      },
    ];

    const onCloseWrapped = (): void => {
      onClose();
      setCheckedAnswer(null);
    };

    return (
      <OverlayModal
        maxWidth={640}
        allowClose
        visible={visible}
        onClose={onCloseWrapped}
      >
        {(hide, rendered): React.ReactElement => (
          <>
            <Text
              variant="h2"
              sx={{
                fontSize: [3, 4],
                mt: 0,
                mb: [4, 6],
                zIndex: 0,
                '@media (max-width: 320px)': {
                  fontSize: 2,
                  mt: 4,
                },
              }}
            >
              {t('WHY_DO_YOU_WANT_DELETE')}
            </Text>

            <Box
              sx={{
                textAlign: 'center',
                mb: [4, 6],
                '@media (max-width: 320px)': {
                  display: 'none',
                },
              }}
            >
              <Image
                src={IllustrationPoseDelete}
                sx={{
                  margin: '0 auto',
                  width: '100%',
                  maxWidth: ['200px', '300px'],
                  transform: !rendered ? 'scale(0.7)' : 'scale(1)',
                  transition: 'transform ease-in 0.3s 0.1s',
                  '@media (max-height: 576px) and (orientation: landscape)': {
                    display: 'none',
                  },
                  '@media (max-width: 320px)': {
                    maxWidth: '150px',
                  },
                }}
              />
            </Box>

            {questions.map((question) => (
              <Box sx={{ mb: 4 }} key={question.id}>
                <Box sx={{ textAlign: 'left' }}>
                  <Label
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      pl: '40px',
                    }}
                  >
                    <Radio
                      name="delete-reason"
                      value={question.id}
                      checked={checkedAnswer === question.id}
                      onChange={(): void => {
                        setCheckedAnswer(question.id);
                      }}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                      }}
                    />
                    <Text
                      variant="h5"
                      sx={{
                        '@media (max-width: 320px)': {
                          fontSize: 0,
                        },
                      }}
                    >
                      {question.headline}
                    </Text>
                  </Label>
                </Box>

                <Box
                  sx={{
                    pt: 1,
                    overflow: 'hidden',
                    transition: 'max-height 0.1s ease-out',
                  }}
                  style={{
                    maxHeight:
                      checkedAnswer === question.id
                        ? containerHeights[question.id]
                        : 0,
                  }}
                >
                  <Box
                    sx={{ textAlign: 'left', pl: '40px' }}
                    ref={question.ref}
                  >
                    {question.answer}
                  </Box>
                </Box>
              </Box>
            ))}

            <Box
              sx={{
                mt: [6, 8],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: ['column-reverse', 'row'],
              }}
            >
              <Button
                variant="white"
                onClick={(): void => {
                  hide(onCloseWrapped);
                }}
              >
                {t('CANCEL')}
              </Button>
              <Button
                variant="primary"
                disabled={confirmDisabled || !checkedAnswer}
                onClick={async (): Promise<void> => {
                  setConfirmDisabled(true);
                  await onConfirm(checkedAnswer || '');
                  hide(onCloseWrapped);
                  setConfirmDisabled(false);
                }}
              >
                {t('DELETE_POSE')}
              </Button>
            </Box>
          </>
        )}
      </OverlayModal>
    );
  };

export default DeletePoseComparisonOverlay;

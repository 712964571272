import { PosesDataType } from '@youga/youga-interfaces';

const findPoseByName = (
  posesData: PosesDataType[] | null,
  poseName: string,
): PosesDataType | undefined =>
  (posesData || []).find(
    (p) =>
      p.id === poseName ||
      p.de === poseName ||
      p.en === poseName ||
      p.hi === poseName,
  );

export default findPoseByName;

import { Box } from 'theme-ui';
import '../i18n/i18n';
import FooterSection from '../components/03_organisms/FooterSection/FooterSection';
import ConfirmationGate from '../components/03_organisms/ConfirmationGate/ConfirmationGate';
import Page from '../components/04_templates/Page';
import { useTranslation } from 'react-i18next';

/**
 * This page is rendered when the user tries to open the confirm-email universal link on a desktop device
 */

function ConfirmEmailPage() {
  const { t } = useTranslation();
  return (
    <Page title={t('APP_PAGE_TITLE')} authRequired={false}>
      <Box sx={{ minHeight: 'calc(100vh - 155px)' }}>
        <ConfirmationGate />
      </Box>
      <FooterSection />
    </Page>
  );
}

export default ConfirmEmailPage;

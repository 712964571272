import { useTranslation } from 'react-i18next';

import React from 'react';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import TextInput from '../../01_atoms/TextInput/TextInput';

type ResetPasswordProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  setEmail: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
};

export default function ResetPassword({
  errorInformation,
  setEmail,
  setCurrentVariant,
}: ResetPasswordProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment key="reset-submit-fragment">
      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
        style={{ marginTop: '3.5rem', marginBottom: '4.5rem' }}
      />

      <div style={{}}>
        <Button
          type="submit"
          style={{
            cursor: 'pointer',
            display: 'block',
            marginTop: '3rem',
          }}
        >
          {t('PASSWORT_ZUR_CKSETZEN')}
        </Button>

        <Button
          type="button"
          variant="text"
          style={{
            marginTop: '2rem',
            justifyContent: 'flex-start',
            color: 'black',
          }}
          onClick={(): void => setCurrentVariant('signin')}
        >
          {t('ZUR_CK_ZUM_LOGIN')}
        </Button>
      </div>
    </React.Fragment>
  );
}

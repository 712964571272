import React from 'react';
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlayToggle,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
} from 'video-react';
import HLSSource from './HLSSource';
import ControlSpreader from './ControlSpreader';
import FullscreenToggle from './FullscreenToggle';
import ProgressControl from './ProgressControl';
import VideoTitlesOverlay from './VideoTitlesOverlay';
import { Marker } from './MarkerOverlay';

export interface VideoPlayerProps {
  forceDisplayControlBar?: boolean;
  src: string;
  marker?: Marker[];
  poster?: string;
  title?: string;
  subtitle?: string;
  videoSession: string;
  trainer?: string;
  onEnded?: () => void;
}

const VideoPlayer = React.forwardRef(
  (
    {
      forceDisplayControlBar = false,
      src,
      marker,
      poster,
      videoSession,
      title,
      subtitle,
      onEnded,
      trainer,
    }: VideoPlayerProps,
    ref,
  ) => (
    <Player fluid ref={ref} poster={poster} onEnded={onEnded}>
      <HLSSource isVideoChild src={src} />
      <BigPlayButton position="center" />

      <ControlBar
        autoHide={!forceDisplayControlBar}
        autoHideTime={3000}
        disableDefaultControls
      >
        <VideoTitlesOverlay
          title={title}
          subtitle={subtitle}
          trainer={trainer}
        />
        <PlayToggle key="play-toggle" order={1} />
        <VolumeMenuButton key="volume-menu-button" order={4} />
        <CurrentTimeDisplay key="current-time-display" order={5.1} />
        <TimeDivider key="time-divider" order={5.2} />
        <DurationDisplay key="duration-display" order={5.3} />
        <ControlSpreader key="control-spreader" order={6} />
        <FullscreenToggle key="fullscreen-toggle" order={8} />

        {/* this will be absolute positioned */}
        <ProgressControl
          key="progress-control"
          order={10}
          marker={marker}
          videoSession={videoSession}
        />
      </ControlBar>
    </Player>
  ),
);

export default VideoPlayer;

import React, { useMemo } from 'react';
import { Box, Text } from 'theme-ui';
import Tick from './Tick';
import { useTranslation } from 'react-i18next';

type Props = {};

const TrainingTipsSection = (props: Props) => {
  const { t } = useTranslation();

  const [tip1, tip2, tip3] = useMemo(() => {
    return [
      t(
        `finish_session_thanks.first_category_tips._${
          Math.floor(Math.random() * 5) + 1
        }` as any,
      ),
      t(
        `finish_session_thanks.second_category_tips._${
          Math.floor(Math.random() * 5) + 1
        }` as any,
      ),
      t(
        `finish_session_thanks.third_category_tips._${
          Math.floor(Math.random() * 5) + 1
        }` as any,
      ),
    ];
  }, [t]);

  return (
    <Box sx={{ marginTop: '35px' }}>
      <Text
        sx={{
          color: '#F48222',
          fontSize: '18px',
          fontWeight: '600',
          textAlign: 'center',
        }}
      >
        {t('finish_session_thanks.tips_for_you')}
      </Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          flexWrap: 'wrap',
          '@media (min-height: 950px)': { justifyContent: 'center' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '300px',
            marginTop: '10px',
            marginRight: '5px',
          }}
        >
          <Tick />
          <Box
            sx={{
              marginLeft: '40px',
            }}
          >
            <Text sx={{ fontSize: '14px' }}>{tip1}</Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '300px',
            marginTop: '10px',
            marginRight: '5px',
          }}
        >
          <Tick />
          <Box
            sx={{
              marginLeft: '40px',
            }}
          >
            <Text sx={{ fontSize: '14px' }}>{tip2}</Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '300px',
            marginTop: '10px',
            marginRight: '5px',
          }}
        >
          <Tick />
          <Box
            sx={{
              marginLeft: '40px',
            }}
          >
            <Text sx={{ fontSize: '14px' }}>{tip3}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TrainingTipsSection;

import { useTranslation } from 'react-i18next';

import * as React from 'react';
import { Link } from 'gatsby';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from '@reach/router';
import Button from '../../01_atoms/Button/Button';
import Container from '../../01_atoms/Container/Container';
import Header from '../../02_molecules/Header/Header';
import useAuth from '../../../hooks/useAuth';

export type ConnectSectionProps = RouteComponentProps;

// eslint-disable-next-line no-empty-pattern
const PairWithConnectSection =
  ({}: ConnectSectionProps): React.ReactElement => {
    const { t } = useTranslation();

    const { isSignedIn } = useAuth();

    return (
      <>
        <Header showButton={null} />
        <Container sx={{ textAlign: 'center' }}>
          <h1>{t('NUR_NOCH_WENIGE_SCHRITTE_ENTFE')}</h1>

          {isSignedIn ? (
            <>
              <p>{t('BITTE_W_HLE_NUN_AUF_DIESEM_GER')}</p>

              <Link to="/app">
                <Button variant="primary">{t('ZUR_AUSWAHL')}</Button>
              </Link>
            </>
          ) : (
            <>
              <p>{t('BITTE_MELDE_DICH_NUN_AUF_DIESE')}</p>

              <Link to="/app">
                <Button variant="primary">{t('ZUM_LOGIN')}</Button>
              </Link>
            </>
          )}
        </Container>
      </>
    );
  };

export default PairWithConnectSection;

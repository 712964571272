import React, { Ref } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

interface Props {
  className?: string;
  children: React.ReactNode;
  sx?: ThemeUIStyleObject;
  style?: { [key: string]: string };
}

const Container = React.forwardRef(
  (
    { children, className = '', sx = {}, style = {} }: Props,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        maxWidth: '1200px',
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
        margin: 'auto',
        ...sx,
      }}
      className={className}
      style={style}
    >
      {children}
    </Box>
  ),
);

export default Container;

import React from 'react';
import { Box } from 'theme-ui';
import CourseItem from '../../01_atoms/CourseItem/CourseItem';
import { LabelName } from '@youga/youga-interfaces';
import FadeIn from 'react-fade-in';

interface CourseCarouselItemProps {
  title: string;
  content: string;
  thumbnailUrl: string;
  thumbnailHqUrl: string;
  level: number;
  sessions: number;
  instructor: string;
  instructorId: string;
  courseId: string;
  hideInstructor?: boolean;
  preview?: boolean;
  target: string;
  variant?: 'all' | 'favorites';
  increaseSize?: boolean;
  reduceMargin?: boolean;
  isLastElement: boolean;
  labels?: LabelName[];
}
const CourseCarouselItem: React.FC<CourseCarouselItemProps> = ({
  title,
  content,
  thumbnailUrl,
  thumbnailHqUrl,
  level,
  sessions,
  instructor,
  instructorId,
  courseId,
  labels,
  hideInstructor = false,
  preview = false,
  target,
  variant,
  increaseSize = false,
  reduceMargin = false,
  isLastElement,
}: CourseCarouselItemProps) => {
  const width = isLastElement
    ? '360px'
    : ['360px', reduceMargin ? '360px' : '420px'];

  return (
    <FadeIn>
      <Box
        sx={{
          scrollSnapStop: 'always',
          scrollSnapAlign: 'start',
          pl: '20px',
          pr: isLastElement ? '20px' : [0, reduceMargin ? '20px' : '80px'],
          ml: 0,
          mr: increaseSize ? ['100px', '82px'] : 0,
          mt: 2,
          mb: 5, // render shadow. OverflowX: scroll results in OverflowY: auto and not visible
          minWidth: width,
          width,
        }}
        // 1160
      >
        <CourseItem
          title={title}
          content={content}
          thumbnailUrl={thumbnailUrl}
          thumbnailHqUrl={thumbnailHqUrl}
          level={level}
          sessions={sessions}
          labels={labels}
          noLabel={variant === 'favorites'}
          instructor={instructor}
          instructorId={instructorId}
          courseId={courseId}
          hideInstructor={hideInstructor}
          preview={preview}
          target={target}
          increaseSize={increaseSize}
        />
      </Box>
    </FadeIn>
  );
};

export default CourseCarouselItem;

import { useEffect, useState } from 'react';

function useStripe() {
  const [isStripePayment, setIsStripePayment] = useState<boolean>(true);

  const handleStripePaymentChange = (value: boolean): void => {
    console.log('handleStripePaymentChange', value);
    setIsStripePayment(value);
  };
  console.log('isStripePayment', isStripePayment);

  return { isStripePayment, handleStripePaymentChange };

};
export default useStripe;
/** @jsxImportSource theme-ui */
import React, { CSSProperties } from 'react';
import { Input, ThemeUIStyleObject } from 'theme-ui';

interface Props {
  key?: string;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  type?: string;
  id: string;
  name: string;
  placeholder: string;
  required?: boolean;
  defaultValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  overlayLink?: React.ReactElement | null;
  error?: React.ReactNode;
  sx?: ThemeUIStyleObject;
  framed?: boolean; // New prop for framed style
}

const TextInput = ({
  style = {},
  key,
  disabled = false,
  className,
  id,
  name,
  onChange,
  placeholder,
  type = 'text',
  overlayLink,
  error,
  defaultValue,
  required = false,
  framed = false, // Default value for the new prop
}: Props): React.ReactElement => (
  <div style={{ position: 'relative' }} key={key || undefined}>
    <Input
      key={`${key || ''}-input`}
      className={className}
      disabled={disabled}
      type={type}
      style={style}
      id={id}
      name={name}
      required={required}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
      sx={{
        ...(error ? { color: 'error' } : {}),
        ...(framed
          ? {
              border: '1px solid', // Thinner border
              borderColor: '#666', // Lighter shade of black (dark gray)
              borderRadius: '8px', // Rounded corners
              padding: '12px 16px', // More padding
              fontSize: '16px', // Larger font size
            }
          : {}),
      }}
    />
    {overlayLink && (
      <span sx={{ position: 'absolute', right: 2, top: 2 }}>{overlayLink}</span>
    )}
    {error ? (
      <span
        sx={{
          display: 'block',
          marginTop: '-1.5rem',
          marginBottom: 5,
          paddingLeft: 1,
          color: 'error',
          fontSize: 0,
        }}
      >
        {error}
      </span>
    ) : null}
  </div>
);

export default TextInput;

import * as React from 'react';

interface Props {
  title: string;
  onDismiss: () => void;
}

function JourneyProgressUi(props: React.PropsWithChildren<Props>) {
  const { title, children } = props;
  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: 'white',
        position: 'relative',
      }}
    >
      <div
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <div>
          <h2 style={{ fontWeight: 'normal', marginTop: 0 }}>{title}</h2>
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            marginBottom: -24,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default JourneyProgressUi;

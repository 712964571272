import React from 'react';

import { Box, Flex, Image } from 'theme-ui';
import { useUser } from '@youga/youga-client-api';

import Button from '../../01_atoms/Button/Button';
import FadeIn from '../../01_atoms/FadeIn/FadeIn';
import Text from '../../01_atoms/Text/Text';

import OnboardingPlayBarImage from '../../../assets/onboardingPlayBar.png';
import OnboardingArrowPlayBarImage from '../../../assets/onboardingArrowPlayBar.png';
import OnboardingArrowSmartMirrorImage from '../../../assets/onboardingArrowSmartMirror.png';
import { useTranslation } from 'react-i18next';

const style = {
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background:
      'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 0%)',
  } as React.CSSProperties,
  sessionStartContainer: {
    position: 'absolute',
    top: '35%',
    left: '2.5%',
  } as React.CSSProperties,
  title: { color: 'white', margin: 0, fontFamily: 'Montserrat' },
  subtitle: {
    color: 'white',
    fontWeight: 'thin',
    margin: 0,
    fontFamily: 'Montserrat',
  },
  body: { color: 'white', marginTop: 16, fontFamily: 'Metropolis' },
  smartMirrorContainer: {
    position: 'absolute',
    top: '15%',
    right: 600,
    width: 480,
  } as React.CSSProperties,
  smartMirrorArrow: {
    position: 'absolute',
    top: 12,
    right: -40,
  } as React.CSSProperties,
  playBarContainer: {
    position: 'absolute',
    bottom: 32,
    left: 0,
    right: 0,
    width: '100%',
  } as React.CSSProperties,
  playBarTextContainer: {
    marginBottom: 64,
    width: 480,
    position: 'absolute',
    bottom: 64,
    left: '45%',
  } as React.CSSProperties,
  playBarArrow: {
    position: 'absolute',
    left: '39.75%',
    bottom: 112,
  } as React.CSSProperties,
  playBarImage: {
    position: 'absolute',
    left: '2%',
    right: '2%',
    bottom: 32,
    width: '96%',
  } as React.CSSProperties,
};

function SessionStartContainer({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Box style={style.sessionStartContainer}>
      <Text variant="h2" sx={style.title}>
        {t('YOUR_FIRST_SESSION')}
        <Text variant="h2" sx={style.subtitle}>
          {t('WITH_FEEDBACK')}
        </Text>
      </Text>
      <Button disabled={disabled} style={{ marginTop: 24 }} onClick={onClick}>
        {t('START_SESSION')}
      </Button>
    </Box>
  );
}

function ExplainSmartMirrorContainer({ mirrorWidth }: { mirrorWidth: number }) {
  const containerStyle = Object.assign({}, style.smartMirrorContainer, {
    right: (mirrorWidth || 0) + 66,
  });
  const { t } = useTranslation();

  return (
    <Box style={containerStyle}>
      <Text variant="h3" sx={style.title}>
        SmartMirror
        <Text variant="h3" sx={style.subtitle}>
          {t('YOUR_SMARTPHONE_BACAME')}
        </Text>
        <Text variant="h3" sx={style.subtitle}>
          {t('SMART_MIRROR2')}
        </Text>
      </Text>

      <Image
        src={OnboardingArrowSmartMirrorImage}
        style={style.smartMirrorArrow}
      />

      <Text varian="p" style={style.body}>
        {t('HOW_TO_POSITION_SMART_MIRROR')}
      </Text>
      <Text varian="p" style={style.body}>
        {t('PROPER_POSITION_HELP_YOU_IMPROVE')}
      </Text>
    </Box>
  );
}

function ExplainPlaybarContainer() {
  const { t } = useTranslation();
  return (
    <Box style={style.playBarContainer}>
      <Flex>
        <Box style={style.playBarTextContainer}>
          <Text variant="h3" sx={style.title}>
            {t('POSE_COMPARSION')}
            <Text variant="h3" sx={style.subtitle}>
              {t('IMPROVE_YOUR_PROGRRESS')}
            </Text>
          </Text>
          <Text varian="p" style={style.body}>
            {t('WE_ARE_TAKING_PHOTOS')}
          </Text>
          <Text varian="p" style={style.body}>
            {t('COUTDOWN_BEFORE_EACH_POSE')}
          </Text>
        </Box>

        <Image src={OnboardingArrowPlayBarImage} style={style.playBarArrow} />

        <Image src={OnboardingPlayBarImage} style={style.playBarImage} />
      </Flex>
    </Box>
  );
}

export default function OnboardingTrainingssession({
  mirrorWidth,
}: {
  mirrorWidth: number;
}) {
  const { data, updatePreferences } = useUser();
  const [displayOverlay, setDisplayOverlay] = React.useState(false);
  const [disabled, setDisable] = React.useState(false);

  const smCounter = data?.stats?.smartMirror;
  const smOnboardedCompleted = data?.preferences?.smartMirrorOnboarded;

  const onClickResolveOnboarding = async () => {
    setDisable(true);
    await updatePreferences({
      ...data?.preferences,
      smartMirrorOnboarded: true,
    });

    setDisplayOverlay(false);
    setDisable(true);
  };

  React.useEffect(() => {
    const isFirstSession = smCounter === 0 && smOnboardedCompleted !== true;

    if (isFirstSession && !displayOverlay) {
      setDisplayOverlay(true);
    }
  }, [displayOverlay, smCounter, smOnboardedCompleted]);

  if (displayOverlay) {
    return (
      <div style={style.root}>
        <Box>
          <FadeIn delay={750} duration={750}>
            <SessionStartContainer
              disabled={disabled}
              onClick={onClickResolveOnboarding}
            />
          </FadeIn>

          <FadeIn delay={1250} duration={750}>
            <ExplainSmartMirrorContainer mirrorWidth={mirrorWidth} />
          </FadeIn>

          <FadeIn delay={2000} duration={750}>
            <ExplainPlaybarContainer />
          </FadeIn>
        </Box>
      </div>
    );
  }
  return null;
}

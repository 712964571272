/** @jsxImportSource theme-ui */

import React from 'react';
import { Link } from 'gatsby';
import { LabelName } from '@youga/youga-interfaces';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import Text from '../Text/Text';
import LevelIcon from '../LevelIcon/LevelIcon';
import Labels from '../Labels/index';
import Favorite from '../../03_organisms/Favorite/index';
import { useTranslation } from 'react-i18next';
interface CourseItemProps {
  className?: string;
  title: string;
  content: string;
  thumbnailUrl: string;
  thumbnailHqUrl: string;
  level: number;
  sessions: number;
  instructor: string;
  instructorId: string;
  courseId: string;
  hideInstructor?: boolean;
  increaseSize?: boolean;
  labels?: LabelName[];
  noLabel?: boolean;
  target: string;
  preview?: boolean;
  sx?: ThemeUIStyleObject;
}

const CourseItem: React.FC<CourseItemProps> = ({
  className = '',
  title,
  content,
  thumbnailUrl,
  thumbnailHqUrl,
  level,
  sessions,
  labels,
  noLabel,
  instructor,
  instructorId,
  courseId,
  hideInstructor = false,
  increaseSize = false,
  preview = false,
  target,
  sx,
}: CourseItemProps) => {
  const sessionTitle = (
    <>
      <Text variant="h5" clampLines={1}>
        {title}
      </Text>
      <Text variant="h6light" sx={{ mt: 1, fontFamily: 'body' }} clampLines={1}>
        {content}
      </Text>
    </>
  );
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'relative',
        width: '20rem',
        borderRadius: '8px',
        boxShadow: '0px 10px 16px 0px rgba(0, 0, 0, 0.1);',
        backgroundColor: 'white',
        transition: 'transform ease-in 0.15s, box-shadow ease-in 0.15s',
        overflow: 'hidden',
        transform: increaseSize ? 'scale(1.32)' : undefined,
        ml: increaseSize ? '50px' : undefined,
        a: {
          textDecoration: 'none',
        },
        ...(!preview
          ? {
              ':hover': {
                transform: increaseSize ? 'scale(1.28)' : 'scale(0.975)',
                boxShadow: '0px 10px 20px 5px rgba(0, 0, 0, 0.2)',
              },
            }
          : {}),
        ...sx,
      }}
      className={className}
    >
      <Box
        sx={{
          position: 'relative',
          paddingTop: `${(180 / 320) * 100}%`,
          overflow: 'hidden',
        }}
      >
        {!noLabel && labels && <Labels labels={labels} />}
        <Favorite courseId={courseId} size={'small'} />
        <picture
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            objectFit: 'cover',
            objectPosition: 'center center',
            filter: preview ? 'blur(8px)' : undefined,
          }}
        >
          <source srcSet={`${thumbnailUrl} 1x, ${thumbnailHqUrl} 2x`} />
          <img src={thumbnailUrl} alt={title} sx={{ width: '100%' }} />
        </picture>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            background:
              'linear-gradient(-180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
            height: '2rem',
            width: '100%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background:
              'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)',
            height: '2rem',
            width: '100%',
          }}
        />
        {sessions > 0 && (
          <Text
            sx={{
              fontFamily: 'heading',
              fontWeight: 'normal',
              fontSize: '12px',
              color: 'white',
              position: 'absolute',
              bottom: '4px',
              right: '8px',
            }}
          >
            {sessions} {t('SESSIONS')}
          </Text>
        )}
        {preview && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              sx={{
                fontFamily: 'heading',
                fontWeight: 'bold',
                fontSize: '20px',
                color: 'white',
                textAlign: 'center',
                textTransform: 'uppercase',
                maxWidth: '290px',
                textShadow: '0px 10px 16px rgba(0, 0, 0, 0.1)',
              }}
            >
              {t('AVAILABLE_SOON')}
            </Text>
          </Box>
        )}

        {!preview && (
          <Link
            to={target}
            sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          />
        )}
      </Box>
      <Box
        sx={{
          py: [3, 2],
          px: 3,
          position: 'relative',
        }}
      >
        {preview ? sessionTitle : <Link to={target}>{sessionTitle}</Link>}
        {!hideInstructor && (
          <Link to={`/app/instructor-detail/${instructorId}`}>
            <Text
              variant="h6light"
              sx={{ color: 'grey2', mt: 1, fontFamily: 'body' }}
            >
              {instructor || ' '}
              {!instructor && <span>&nbsp;</span>}
            </Text>
          </Link>
        )}
        <Flex sx={{ mt: 3, alignItems: 'center' }}>
          <Text
            sx={{
              fontFamily: 'body',
              color: 'black',
              fontSize: '12px',
              mt: 2,
              mr: 2,
            }}
          >
            {t('LEVEL')} {level}
          </Text>
          <LevelIcon level={level} />
        </Flex>
      </Box>
    </Box>
  );
};

export default CourseItem;

/** @jsxImportSource theme-ui */

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { Text as ThemeText, ThemeUIStyleObject } from 'theme-ui';

export interface TextProps {
  className?: string;
  children?: React.ReactNode;
  highlighted?: false | true | 'white';
  variant?: string;
  backgroundImageUrl?: string;
  clampLines?: number;
  sx?: ThemeUIStyleObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

interface FontFaceSet {
  ready: Promise<void>;
}

const Text: React.FC<TextProps> = ({
  className,
  children,
  highlighted = false,
  clampLines,
  variant = 'default',
  sx,
  ...props
}: TextProps) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true; // track whether component is mounted

    // the document.fonts feature is unfortunately not always supported
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const documentFonts = (document as any).fonts as FontFaceSet | undefined;

    if (!clampLines || fontsLoaded || !documentFonts) {
      return;
    }

    documentFonts.ready.then((): void => {
      // Only set state if the component is still there
      if (isMounted) {
        setFontsLoaded(true);
      }
    });

    // eslint-disable-next-line consistent-return
    return (): void => {
      isMounted = false; // cleanup
    };
  });

  return (
    <ThemeText
      className={className}
      variant={variant}
      sx={{
        ...sx,
        ...(highlighted
          ? { display: 'inline-block', position: 'relative' }
          : {}),
      }}
      {...props}
    >
      {highlighted && (
        <div
          sx={{
            position: 'relative',
            '::after': {
              content: "''",
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '35%',
              backgroundColor: highlighted === true ? 'primary' : highlighted,
              zIndex: -1,
              opacity: 0.9,
              marginLeft: '-6px',
              marginRight: '-6px',
            },
          }}
        >
          {children}
        </div>
      )}

      {clampLines && <Dotdotdot clamp={clampLines}>{children}</Dotdotdot>}
      {!clampLines && !highlighted && children}
    </ThemeText>
  );
};

export default Text;

/** @jsxImportSource theme-ui */

import React from 'react';

interface LevelIconProps {
  level: number;
  increased?: boolean;
}

const LevelIcon: React.FC<LevelIconProps> = ({
  level,
  increased = false,
}: LevelIconProps) => (
  <div>
    {[1, 2, 3, 4].map((currentLevel) => (
      <div
        key={currentLevel}
        sx={{
          display: 'inline-block',
          marginLeft: currentLevel === 1 ? 0 : '2px',
          width: increased ? '0.75rem' : '0.5rem',
          height: increased ? '3px' : '2px',
          backgroundColor: currentLevel <= level ? 'baseBlack' : 'grey2',
          borderRadius: '2px',
        }}
      />
    ))}
  </div>
);

export default LevelIcon;

/** @jsxImportSource theme-ui */

import * as React from 'react';
import { Link, navigate } from 'gatsby';
import { Image, Box, Flex } from 'theme-ui';
import { useLocation } from '@reach/router';
import { z } from 'zod';

import useAuth from '../../../hooks/useAuth';

import Container from '../../01_atoms/Container/Container';
import SmartMirrorLinkButton from '../../01_atoms/SmartMirrorLinkButton/SmartMirrorLinkButton';

// Load images
import yuuLogo from '../../../assets/yuuLogoLarge_2.svg';
import yuuLogoWhite from '../../../assets/yuuLogoWhite.svg';
import backBtnWhite from '../../../assets/icons/btnBackWhite.svg';
import backBtn from '../../../assets/icons/btnBack.svg';
import btnMenu from '../../../assets/btnMenu.svg';
import btnMenuClose from '../../../assets/btnMenuClose.svg';
import Menu from './Menu';

interface HeaderProps {
  backLinkOverride?: string;
  theme?: 'dark-solid' | 'dark-transparent' | 'light';
  showConnectButton?: boolean;
  showButton?: 'menu' | 'back' | null;
  fullWidth?: boolean;
  fadeOut?: boolean;
}

const LocationStateSchema = z.object({
  isMenuOpen: z.boolean().optional(),
});

export function useIsMenuOpen() {
  const location = useLocation();
  const locationState = LocationStateSchema.safeParse(location.state);

  const isMenuOpen = locationState.success
    ? locationState.data.isMenuOpen === true
    : false;

  return isMenuOpen;
}

const Header = (props: HeaderProps): React.ReactElement => {
  const theme = props.theme ?? 'light';
  const {
    showConnectButton = false,
    backLinkOverride,
    fullWidth = false,
    fadeOut = false,
    showButton,
  } = props;

  const { isSignedIn } = useAuth();

  let headerClass = 'app-header';

  if (theme === 'dark-transparent') {
    headerClass = 'app-header app-header-dark-transparent';
  } else if (theme === 'dark-solid') {
    headerClass = 'app-header app-header-dark-solid';
  }

  const isMenuOpen = useIsMenuOpen();

  return (
    <>
      <div
        id="header"
        className={headerClass}
        sx={{
          opacity: fadeOut ? 0 : undefined,
          position: 'sticky',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            pointerEvents: 'none',
            transition: 'opacity ease-in 0.3s',
            background:
              'linear-gradient(-180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%)',
          }}
          style={{ opacity: theme === 'dark-transparent' ? 1 : 0 }}
        />
        <Container sx={{ maxWidth: fullWidth ? 'none' : undefined }}>
          <Flex
            sx={{
              position: 'relative',
              px: '34px',
              margin: 'auto',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {showButton === 'back' && (
              <a
                href={backLinkOverride}
                onClick={(event) => {
                  if (backLinkOverride) {
                    return;
                  }
                  event.preventDefault();
                  window.history.back();
                }}
              >
                <React.Fragment>
                  {/** LIGHT BUTTON */}
                  <Image
                    src={backBtn}
                    alt="Zurück Pfeil"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transition: 'opacity 0.3s ease-in',
                      transform: 'translateY(-50%)',

                      display: 'block',
                      mb: '-2px',
                      cursor: 'pointer',
                    }}
                    style={{ opacity: theme === 'light' ? 1 : 0 }}
                  />

                  {/** DARK BUTTON */}
                  <Image
                    src={backBtnWhite}
                    alt="Zurück Pfeil"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transition: 'opacity 0.3s ease-in',
                      transform: 'translateY(-50%)',

                      display: 'block',
                      mb: '-2px',
                      cursor: 'pointer',
                    }}
                    style={{
                      opacity:
                        theme &&
                        ['dark-solid', 'dark-transparent'].includes(theme)
                          ? 1
                          : 0,
                    }}
                  />
                </React.Fragment>
              </a>
            )}

            {showButton === 'menu' && (
              <React.Fragment>
                <Image
                  onClick={(): void => {
                    navigate(window.location.pathname, {
                      state: { isMenuOpen: true },
                    });
                  }}
                  src={btnMenu}
                  alt="Menü öffnen"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    transition: 'opacity ease-in 0.3s',
                  }}
                  style={{
                    opacity: isMenuOpen ? 0 : 1,
                    pointerEvents: isMenuOpen ? 'none' : 'all',
                  }}
                />

                <Image
                  onClick={(): void => {
                    navigate(window.location.pathname, {
                      state: { isMenuOpen: false },
                    });
                  }}
                  src={btnMenuClose}
                  alt="Menü schließen"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    transition: 'opacity ease-in 0.3s',
                  }}
                  style={{
                    opacity: isMenuOpen ? 1 : 0,
                    pointerEvents: isMenuOpen ? 'all' : 'none',
                  }}
                />
              </React.Fragment>
            )}

            <Box className="app-header-image-logo">
              <Link
                to={isSignedIn ? '/app' : '/'}
                sx={{ textDecoration: 'none' }}
              >
                {/* LIGHT LOGO */}
                <Image
                  src={yuuLogo}
                  alt="yuu Logo"
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '5px',
                    height: '92px',
                    transform: 'translate(-50%, -50%)',
                    transition: 'opacity ease-in 0.3s',
                    cursor: 'pointer',
                  }}
                  style={{ opacity: theme === 'light' ? 1 : 0 }}
                />
                {/* DARK LOGO */}
                <Image
                  src={yuuLogoWhite}
                  alt="yuu Logo"
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    height: '92px',
                    top: '25px',
                    transform: 'translate(-50%, -50%)',
                    transition: 'opacity ease-in 0.3s',
                    cursor: 'pointer',
                  }}
                  style={{
                    opacity:
                      theme &&
                      ['dark-solid', 'dark-transparent'].includes(theme)
                        ? 1
                        : 0,
                  }}
                />
              </Link>
            </Box>

            {/* {showConnectButton && <SmartMirrorLinkButton />} */}
          </Flex>
        </Container>
      </div>

      <Menu isOpen={isMenuOpen} />
    </>
  );
};

export default Header;

// Fisher-Yates shuffle: Walk the array in the reverse order and swap each element with a random one before it
// Returns more reliable random results than a regular "shuffle"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function shuffle<T>(elem: T[]): T[] {
  const clone = [...elem];

  for (let i = clone.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line no-param-reassign
    [clone[i], clone[j]] = [clone[j], clone[i]];
  }

  return clone;
}

const supportsFullScreen = (): boolean => {
  const doc = window.document;
  const docEl = doc.documentElement;

  return (
    typeof (
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen
    ) !== 'undefined'
  );
};

const isFullScreen = (): boolean => {
  if (!supportsFullScreen()) {
    return false;
  }

  const doc = window.document;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    return false;
  }

  return true;
};

const toggleFullScreen = (): boolean => {
  if (!supportsFullScreen()) {
    return false;
  }

  const doc = window.document;
  const docEl = doc.documentElement;

  const requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;

  const cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (!isFullScreen()) {
    requestFullScreen.call(docEl);
    return true;
  }

  cancelFullScreen.call(doc);
  return false;
};

export { isFullScreen, supportsFullScreen };
export default toggleFullScreen;

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react";
import AppProviders from "./src/AppProviders";

import "./src/global/global.css";

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
export const registerServiceWorker = () => true;

/**
 * @see {@link https://www.gatsbyjs.com/docs/porting-from-create-react-app-to-gatsby/#context-providers}
 */
export const wrapRootElement = ({ element }) => {
  return <AppProviders>{element}</AppProviders>;
};
